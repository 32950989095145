/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { SubSink } from 'subsink';
import { ElevatorInfoDialog } from './elevator-info.dialog';
import { NavigationService } from '../../_services/navigation.service';

@Component({
  selector: 'elevator-info-entry-dialog',
  template: '',
})
export class ElevatorInfoEntryDialog implements OnInit, OnDestroy {
  private subsink = new SubSink();

  constructor(
    private navigationService: NavigationService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.route.paramMap.subscribe(async (paramMap) => {
        const id = paramMap.get('equipmentId');
        if (!id) return this.navigationService.back();
        this.openDialog(id);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  async openDialog(id: string): Promise<void> {
    const dialogRef = this.dialog.open(ElevatorInfoDialog, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      panelClass: ['dialog-no-padding', 'dialog-no-border-radius'],
      exitAnimationDuration: '300ms',
      data: { id },
    });
    await lastValueFrom(dialogRef.afterClosed());
    this.navigationService.back();
  }
}
