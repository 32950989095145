import { Pipe, PipeTransform } from '@angular/core';
import { get, isEqual } from 'lodash';

@Pipe({ name: 'equals' })
export class EqualsPipe implements PipeTransform {
  transform(src: any, obj: string, field?: string): boolean {
    return isEqual(
      field ? get(src, field) : src,
      field ? get(obj, field) : obj
    );
  }
}
