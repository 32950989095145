/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { read, utils } from 'xlsx';
import { S3File } from '../../_classes/s3-file.class';
import { fadeIn, fadeOut } from '../../_constants/animations';
import { FileService } from '../../_services/file.service';

@Component({
  selector: 'app-xlsx-viewer',
  templateUrl: './xlsx-viewer.component.html',
  styleUrls: ['./xlsx-viewer.component.scss'],
  animations: [fadeOut, fadeIn],
})
export class XlsxViewerComponent implements OnInit {
  loading = true;

  @Input()
  file: S3File;

  // rows: any[];
  html: string;

  constructor(
    private translate: TranslateService,
    private fileService: FileService,
  ) {}

  ngOnInit() {
    this.load();
  }

  async load() {
    const data = await this.fileService.getFileContent(this.file);
    const worksheetData = await data.arrayBuffer();
    const wb = read(worksheetData);
    this.html = utils.sheet_to_html(wb.Sheets[wb.SheetNames[0]]);
    this.loading = false;
  }
}
