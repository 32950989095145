<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <button id="close" mat-icon-button mat-dialog-close color="white">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div id="content" mat-dialog-content>
  <div fxLayout="row wrap">
    <ng-container *ngIf="!loading; else loadingTmpl">
      <mat-card class="small-padding" *ngFor="let equipment of equipments"
        [style.border-color]="EquipmentStatusColor[equipment.status]">
        <mat-card-header>
          <mat-card-title>
            <div fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="grow">
              <span color="primary">{{equipment.kind.name}}</span>
              <span color="primary" bold>{{equipment.reference}}</span>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px">
            <app-equipment-status [status]="equipment.status"></app-equipment-status>
            <div *ngIf="equipment.metadata?.lastMouvement" fxLayout="row" fxLayoutAlign="space-between center"
              fxLayoutGap="16px">
              <span i18n> Dernier mouvement: </span>
              <span color="primary">{{equipment.metadata.lastMouvement.end | date:'short'}}</span>
            </div>
            <div *ngFor="let doorState of equipment.metadata?.lastDoorStates | values" fxLayout="row"
              fxLayoutAlign="space-between center" fxLayoutGap="16px">
              <span i18n> {{EquipmentDoorNameName[doorState.doorName]}}</span>
              <span color="primary">{{EquipmentDoorStateName[doorState.state]}}</span>
            </div>
            <ng-container *ngTemplateOutlet="failureInfo; context: { equipment: equipment}">
            </ng-container>
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
              <span i18n> État de l'équipement: </span>
              <app-star-rate [value]="equipment.condition" [style.height.px]="20"></app-star-rate>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center center">
          <button mat-button color="secondary" (click)="data.navigateTo(equipment)" [style.width.%]="100">
            <span bold i18n>VOIR L'ÉQUIPEMENT</span>
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </div>
</div>

<ng-template #failureInfo let-equipment="equipment">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span light i18n>Panne{ equipment.failureCount, plural, =0{} one{} other{s} }</span>
    <app-count-up class="info" color="danger" [value]="equipment.failureCount"></app-count-up>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span light i18n>Taux de disponibilité</span>
    <app-count-up class="info" [style.color]="equipment.disponibilityRate | disponibilityRateColor"
      [value]="equipment.disponibilityRate * 100" suffix=" %" decimalPlaces="2"></app-count-up>
  </div>
</ng-template>

<ng-template #loadingTmpl>
  <app-loader id="loader" @fadeOut [diameter]="60"></app-loader>
</ng-template>
