/** @format */

import { each, find, map, reduce, values } from 'lodash-es';
import { Module } from './module';

export enum RealEstateStructureFamily {
  BUILDING = 'building',
  BOILER_ROOM = 'boiler-room',
  OTHER = 'other',
}

export const RealEstateStructureFamilyName: { [family: string]: string } = {
  [RealEstateStructureFamily.BUILDING]: $localize`Bâtiment`,
  [RealEstateStructureFamily.BOILER_ROOM]: $localize`Chaufferie`,
  [RealEstateStructureFamily.OTHER]: $localize`Autre`,
};

export const RealEstateStructureFamilyColor: { [family: string]: string } = {
  [RealEstateStructureFamily.BUILDING]: 'var(--app-color-primary)',
  [RealEstateStructureFamily.BOILER_ROOM]: 'var(--app-color-secondary)',
  [RealEstateStructureFamily.OTHER]: 'var(--app-color-medium)',
};

export interface IRealEstateStructureFamilyDatagridOptions {
  value: any;
  name: string;
  color: string;
}

export const RealEstateStructureFamilyDatagridOptions: IRealEstateStructureFamilyDatagridOptions[] = map(
  values(RealEstateStructureFamily),
  (value) => ({
    value,
    name: RealEstateStructureFamilyName[value],
    color: RealEstateStructureFamilyColor[value],
  })
);

export const RealEstateStructureFamilyByModule: { [module: string]: RealEstateStructureFamily[] } = {
  [Module.ELEVATOR]: [RealEstateStructureFamily.BUILDING, RealEstateStructureFamily.OTHER],
  [Module.PARKING_DOOR]: [RealEstateStructureFamily.BUILDING, RealEstateStructureFamily.OTHER],
  [Module.ENERGY]: [
    RealEstateStructureFamily.BUILDING,
    RealEstateStructureFamily.BOILER_ROOM,
    RealEstateStructureFamily.OTHER,
  ],
  [Module.RELAY_ANTENNA]: [RealEstateStructureFamily.BUILDING, RealEstateStructureFamily.OTHER],
  [Module.FIRE_SAFETY]: [RealEstateStructureFamily.BUILDING, RealEstateStructureFamily.OTHER],
};

export function getModuleDatagridOptions(modules: Module[]): IRealEstateStructureFamilyDatagridOptions[] {
  return reduce(
    modules,
    (pv, cv) => {
      each(RealEstateStructureFamilyByModule[cv], (value) => {
        if (!find(pv, { value })) {
          const option = find(RealEstateStructureFamilyDatagridOptions, { value });
          if (option) pv.push(option);
        }
      });
      return pv;
    },
    [] as IRealEstateStructureFamilyDatagridOptions[]
  );
}
