import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'starFill',
})
export class StarFillPipe implements PipeTransform {
  transform(index: number, value: number, step?: number | null): number {
    let fill: number;
    if (step) {
      const stepValue = 1 / step;
      fill = Math.max(Math.min((Math.round((value - index) * stepValue) / stepValue) * 100, 100), 0);
    } else fill = Math.max(Math.min((value - index) * 100, 100), 0);
    return fill;
  }
}
