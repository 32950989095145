import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { get } from 'lodash';

@Pipe({ name: 'formArray' })
export class FormArrayPipe implements PipeTransform {
  transform(formGroup: FormGroup, field: string): FormArray | null {
    const formArray = formGroup.get(field);
    if (formArray instanceof FormArray) {
      return formArray as FormArray;
    }
    return null;
  }
}
