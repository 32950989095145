import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { ElevatorLastMouvement } from '../../_classes/equipment/equipment.elevator.class';

@Pipe({
  name: 'elevatorLastMouvement',
})
export class ElevatorLastMouvementPipe implements PipeTransform {
  transform(lastMouvement: ElevatorLastMouvement, kind?: 'time' | 'distance', format?: 'since'): string {
    switch (kind) {
      default:
        switch (format) {
          default:
            const duration = lastMouvement?.end
              ? moment.duration(lastMouvement?.end.diff(moment()))
              : lastMouvement?.start
              ? moment.duration(lastMouvement?.start.diff(moment()))
              : null;
            return duration ? duration.humanize(true) : '';
        }
    }
  }
}
