import { Directive, ElementRef, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDatagridCell]'
})
export class DatagridCellDirective {

  @Input('appDatagridCell')
  get name(): string { return this.contentName; }
  set name(name: string) { this.contentName = name; }
  private contentName!: string;

  constructor(public template: TemplateRef<any>) { }

}
