/** @format */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { NgxObserversModule } from 'ngx-observers';
import { AppFlexLayoutModule } from '../../_directives/flex-layout/flex-layout.module';
import { AppTooltipModule } from '../../_directives/tooltip/tooltip.module';
import { ColorPipeModule } from '../../_pipes/color-pipe/color-pipe.module';
import { ExtentedPipesModule } from '../../_pipes/extented-pipes/extended-pipes.module';
import { AppTranslateModule } from '../../app-translate.module';
import { AppCountUpModule } from '../count-up/count-up.module';
import { DatagridModule } from '../datagrid/datagrid.module';
import { EquipmentStatusDialogModule } from '../equipment-status-dialog/equipment-status-dialog.module';
import { AppLoaderModule } from '../loader/loader.module';
import { LocationAddressCellModule } from '../location-address-cell/location-address-cell.module';
import { OrganizationalUnitCellModule } from '../organizational-unit-cell/organizational-unit-cell.module';
import { RealEstateStructureCellModule } from '../real-estate-structure-cell/real-estate-structure-cell.module';
import { StarRateModule } from '../star-rate/star-rate.module';
import { ConnectivityMapComponent } from './connectivity-map/connectivity-map.component';
import { EquipmentStatusMapComponent } from './equipment-status-map/equipment-status-map.component';
import { IndicatorMapComponent } from './indicator-map/indicator-map.component';
import { MapComponent } from './map.component/map.component';
import { RealEstateStructureStatusMapComponent } from './real-estate-structure-status-map/real-estate-structure-status-map.component';
import { SensorIndicatorMapComponent } from './sensor-indicator-map/sensor-indicator-map.component';

@NgModule({
  declarations: [
    MapComponent,
    EquipmentStatusMapComponent,
    RealEstateStructureStatusMapComponent,
    SensorIndicatorMapComponent,
    ConnectivityMapComponent,
    IndicatorMapComponent,
  ],
  imports: [
    CommonModule,
    AppTranslateModule,
    AppFlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    AppLoaderModule,
    IntersectionObserverModule,
    MatSidenavModule,
    AppCountUpModule,
    ExtentedPipesModule,
    DatagridModule,
    LocationAddressCellModule,
    OrganizationalUnitCellModule,
    RealEstateStructureCellModule,
    NgxObserversModule,
    MatCardModule,
    StarRateModule,
    ColorPipeModule,
    EquipmentStatusDialogModule,
    AppTooltipModule,
  ],
  exports: [
    MapComponent,
    EquipmentStatusMapComponent,
    RealEstateStructureStatusMapComponent,
    SensorIndicatorMapComponent,
    ConnectivityMapComponent,
    IndicatorMapComponent,
  ],
})
export class AppMapModule {}
