/** @format */

import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { isNil } from 'lodash-es';
import { fade } from '../../_constants/animations';
import { SessionData } from '../../app.session.data';

@Component({
  selector: 'app-count-up',
  templateUrl: './count-up.component.html',
  styleUrls: ['./count-up.component.scss'],
  animations: [fade],
})
export class CountUpComponent implements OnInit {
  ready = false;
  loading = true;

  @Input()
  color: string;

  @Input()
  duration: number;

  @Input('value')
  set setValue(value: number | null | undefined) {
    this.value = value;
    this.loading = !this.ready || isNil(this.value);
  }
  value: number | null | undefined;

  @Input()
  prefix: string;

  @Input()
  suffix: string;

  @Input('decimalPlaces')
  set setDecimalPlaces(decimalPlaces: string | number) {
    const value = typeof decimalPlaces === 'string' ? parseInt(decimalPlaces) : decimalPlaces;
    this.decimalPlaces = isNaN(value) ? 0 : value;
  }
  decimalPlaces: number;

  options: any = { duration: 1, separator: ' ', decimal: $localize`,`, decimalPlaces: 0 };

  constructor() {}

  ngOnInit() {
    this.options.decimalPlaces = !isNil(this.decimalPlaces) ? this.decimalPlaces : 0;
    this.options.decimal = getLocaleNumberSymbol(SessionData.$locale.value!, NumberSymbol.Decimal);
    this.options.prefix = this.prefix ? this.prefix : '';
    this.options.suffix = this.suffix ? this.suffix : '';
    if (this.duration) this.options.duration = this.duration;
    this.ready = true;
    if (!isNil(this.value)) this.loading = false;
  }
}
