/** @format */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-disponibility-rate-label',
  templateUrl: './disponibility-rate-label.component.html',
  styleUrls: ['./disponibility-rate-label.component.scss'],
})
export class DisponibilityRateLabelComponent {

  @Input('value')
  set setValue(value: number | null | undefined) {
    this.value = value === undefined ? 1 : value;
  }
  value: number | null;
}
