import { Pipe, PipeTransform } from '@angular/core';
import { get, map } from 'lodash';

@Pipe({ name: 'asyncFunction' })
export class AsyncFunctionPipe implements PipeTransform {
  transform(
    data: any | any[],
    run: (entry: any) => Promise<any>
  ): Promise<any> {
    return Array.isArray(data)
      ? Promise.all(map(data, (d) => run(d)))
      : run(data);
  }
}
