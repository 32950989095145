/** @format */

import { PropertyMap } from 'model-mapper';
import { Model3DKind } from '../_constants/model-3d-type';
import { s3PathPropertyMap } from '../_helpers/class.helper';
import { S3File } from './s3-file.class';

export class Model3D {
  @PropertyMap()
  _id: string;

  @PropertyMap(s3PathPropertyMap)
  s3Path: string;

  @PropertyMap()
  name: string;

  @PropertyMap()
  kind: Model3DKind;

  @PropertyMap({ type: S3File })
  file: S3File;
}
