/** @format */

import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, inject } from '@angular/core';
// @ts-ignore
import mapboxgl from '!mapbox-gl';
import { Storage } from 'aws-amplify';
import { cloneDeep, each, filter, find, findIndex, get, head, map, merge, remove, slice } from 'lodash-es';
import { GeoJSONSource, IControl, Map, Marker, NavigationControl, Popup } from 'mapbox-gl';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { NGXLogger } from 'ngx-logger';
import { take } from 'rxjs/operators';
import { v4 } from 'uuid';
import { environment } from '../../../../environments/environment';
import { MapModel } from '../../../_classes/map-model.class';
import { fade } from '../../../_constants/animations';
import { RealEstateStructureService } from '../../../_services/real-estate-structure.service';
// @ts-ignore
import { Threebox } from 'threebox-plugin';
import { Model3DKind } from '../../../_constants/model-3d-type';

export interface ISource {
  type: 'geojson';
  data: {
    type: 'FeatureCollection';
    features: {
      type: 'Feature';
      properties: any;
      geometry: [number, number];
    }[];
  };
  cluster?: boolean;
  clusterRadius?: number;
  clusterProperties?: {
    [name: string]: {
      color?: string;
      filter: any;
    };
  };
}

export interface ITextMarker {
  position: [number, number];
  text: string;
  id?: number;
  type: 'text';
  click?: (event: any) => void;
  color?: string;
  radius?: number;
  textColor?: string;
  textSize?: number;
  properties?: any;
}

export interface IIconMarker {
  position: [number, number];
  icon: string;
  id?: number;
  type: 'icon';
  click?: (event: any) => void;
  color?: string;
  iconSize?: number;
  properties?: any;
}

export interface IDefaultMarker {
  position: [number, number];
  id?: number;
  type: 'default';
  click?: (event: any) => void;
  color?: string;
  marker?: any;
  drag?: (coordinates: [number, number]) => any;
}

export type IMarker = ITextMarker | IIconMarker | IDefaultMarker;

export interface IPadding {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export type Padding = number | IPadding;

export interface IFitBoundsOptions {
  padding?: Padding;
  maxZoom?: number;
}

export interface IOptions {
  enableNavControl?: boolean;
  disableKeyboard?: boolean;
  disableZoom?: boolean;
  disableDrag?: boolean;
  disableLayers?: boolean;
  zoomWithCtrl?: boolean;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  center?: [number, number];
  maxBounds?: [[number, number], [number, number]];
  cluster?: boolean;
  clusterMaxZoom?: number;
  clusterRadius?: number;
  building?: boolean;
  clusterProperties?: { [key: string]: { color?: string; merge: any } };
  popup?: (markers: IMarker[]) => string | void;
  click?: (markers: IMarker[]) => void;
  tb?: {
    enableSelectingObjects?: boolean;
    enableDraggingObjects?: boolean;
    enableRotatingObjects?: boolean;
  };
  zoomControl?: {
    zoom: boolean;
  };
}

class ZoomControl implements IControl {
  private map: Map;
  private container: HTMLElement;

  constructor(private options?: { zoom?: boolean | null }) {
    // super();
  }

  onClick(event: MouseEvent) {
    // console.log('click', event);
  }

  onAdd(map: Map): HTMLElement {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this.container.addEventListener('contextmenu', (e) => e.preventDefault());
    this.container.addEventListener('click', (e) => this.onClick(e));

    this.container.innerHTML =
      '<div class="tools-box">' +
      '<button>' +
      '<span class="mapboxgl-ctrl-icon my-image-button" aria-hidden="true" title="Description"></span>' +
      '</button>' +
      '</div>';

    return this.container;
  }

  onRemove(map: Map): void {}
  // getDefaultPosition?: (() => string) | undefined {  };
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  animations: [fade],
})
export class MapComponent implements OnInit {
  static MARKER_SVG = `
  <svg xmlns="http://www.w3.org/2000/svg" height="50px" viewBox="0 0 24 24" width="50px" fill="currentColor" filter="drop-shadow(0px 1px 2px rgb(0 0 0 / 0.8))">
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M12 4C9.24 4 7 6.24 7 9c0 2.85 2.92 7.21 5 9.88 2.11-2.69 5-7 5-9.88 0-2.76-2.24-5-5-5zm0 7.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" opacity=".3" stroke="currentColor" fill="currentColor"/>
    <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" stroke="currentColor" fill="currentColor"/>
    <circle cx="12" cy="9" r="2.5" stroke="currentColor" fill="currentColor"/>
  </svg>
`;

  static DEFAULT_OPTIONS: IOptions = {
    enableNavControl: true,
    disableKeyboard: false,
    disableZoom: false,
    disableDrag: false,
    disableLayers: false,
    zoomWithCtrl: true,
    zoom: 10,
    maxZoom: 18,
    center: [2.3514619999900788, 48.856697000002384],
    cluster: false,
    clusterRadius: 50,
    building: true,
    tb: {
      enableSelectingObjects: false,
      enableDraggingObjects: false,
      enableRotatingObjects: false,
    },
  };

  MapLayers = [
    { id: 'default', imgUrl: 'assets/icons/map.png' },
    { id: 'satellite', imgUrl: 'assets/icons/satellite.png' },
  ];

  public isReady = false;
  public isLoading = true;

  @Input('options')
  public set setOption(options: IOptions) {
    this.options = merge(cloneDeep(MapComponent.DEFAULT_OPTIONS), options);
  }
  options: IOptions = MapComponent.DEFAULT_OPTIONS;

  @Output()
  public ready: EventEmitter<MapComponent> = new EventEmitter();

  @Output('moved')
  public movedEmitter: EventEmitter<any> = new EventEmitter();

  @Output('clicked')
  public clickedEmitter: EventEmitter<any> = new EventEmitter();

  @Output('layer')
  public layerEmitter: EventEmitter<string> = new EventEmitter();

  public id: string;

  private map: Map;
  private tb: Threebox;
  private navigationControl: NavigationControl | null;
  private zoomControl: ZoomControl | null;
  private markers: { [id: string]: Marker } = {};
  private markersOnScreen: { [id: string]: Marker } = {};
  private markerPopups: Popup;

  private dataMarkers: IMarker[] = [];
  private perimeters: { id: string; data: any[]; color: string; click?: (event: any) => any }[] = [];

  private logger: NGXLogger = inject(NGXLogger);

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent): void {
    if (this.map && this.options.zoomWithCtrl && ($event.ctrlKey || $event.metaKey)) {
      this.map.scrollZoom.enable();
    }
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp($event: KeyboardEvent): void {
    if (this.map && this.options.zoomWithCtrl && !$event.ctrlKey && !$event.metaKey) {
      this.map.scrollZoom.disable();
    }
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private realEstateStructureService: RealEstateStructureService,
  ) {}

  ngOnInit(): void {
    this.id = v4();
    if (!mapboxgl.supported()) {
      this.notSupported = true;
      this.isLoading = false;
      this.isReady = true;
    }
  }

  switchLayer(layer: 'default' | 'satellite') {
    this.map.once('styledata', async (event) => {
      if (this.options.building) this.loadBuildings();
      this.setSource();
      this.updateSource();
      const perimeters = cloneDeep(this.perimeters);
      this.perimeters = [];
      each(perimeters, (perimeter) => this.addPerimeter(perimeter.data, perimeter.color, perimeter.click));
      this.layerEmitter.emit(layer);
    });
    switch (layer) {
      case 'default':
        this.map.setStyle('mapbox://styles/mapbox/streets-v11', { diff: false });
        break;
      case 'satellite':
        this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11', { diff: false });
        break;
    }
  }

  public resize(): void {
    if (this.map) {
      this.map.resize();
    }
  }

  private resizeTm: any;
  resized(entries: ResizeObserverEntry[]) {
    if (!this.map) return;
    if (this.resizeTm) clearTimeout(this.resizeTm);
    this.resizeTm = setTimeout(() => {
      this.resizeTm = null;
      this.resize();
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  public getZoom(): number {
    return this.map.getZoom();
  }

  public setZoom(zoom: number): void {
    this.map.setZoom(zoom);
  }

  public getBounds(): mapboxgl.LngLatBounds {
    return this.map?.getBounds();
  }

  public async fitBounds(coordinates: [number, number][], options: IFitBoundsOptions = {}): Promise<void> {
    if (coordinates.length) {
      return new Promise((resolve) => {
        const bounds = coordinates.reduce(
          (bds, coord) => (coord?.length === 2 ? bds.extend(coord) : bds),
          new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
        );
        this.map?.fitBounds(bounds, {
          padding: options.padding || 0,
          maxZoom: options.maxZoom || this.options.maxZoom,
        });
        this.map?.once('moveend', resolve);
      });
    }
  }

  public async flyTo(center: [number, number], options?: IFitBoundsOptions): Promise<void> {
    return this.fitBounds([center], options);
  }

  public removePerimeter(id: string): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.removePerimeter(id));
    }
    if (!id) return;
    remove(this.perimeters, (p) => p.id === id);
    try {
      this.map.removeLayer(`border_${id}`);
      this.map.setPaintProperty(`fill_${id}`, 'fill-opacity', 0);
      setTimeout(() => {
        this.map.removeLayer(`fill_${id}`);
        this.map.removeSource(`source_${id}`);
      }, 300);
    } catch (err) {
      this.logger.error(err);
    }
  }

  public removeAllPerimeters(): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.removeAllPerimeters());
    }
    each(this.perimeters, (perimeter) => {
      try {
        this.map.removeLayer(`border_${perimeter.id}`);
        this.map.setPaintProperty(`fill_${perimeter.id}`, 'fill-opacity', 0);
        setTimeout(() => {
          this.map.removeLayer(`fill_${perimeter.id}`);
          this.map.removeSource(`source_${perimeter.id}`);
        }, 300);
      } catch (err) {
        this.logger.error(err);
      }
    });
    this.perimeters = [];
  }

  public addPerimeter(data: any[], color: string, click?: (event: any) => any): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.addPerimeter(data, color));
    }
    const id = v4();
    const source = `source_${id}`;
    const fillLayer = `fill_${id}`;
    const borderLayer = `border_${id}`;
    this.perimeters.push({ id, data, color, click });
    this.map.addSource(source, {
      type: 'geojson',
      generateId: true,
      data: {
        type: 'FeatureCollection',
        features: data,
      },
    });
    this.map.addLayer({
      id: fillLayer,
      type: 'fill',
      source,
      layout: {},
      paint: {
        'fill-color': color,
        'fill-opacity': 0,
        'fill-opacity-transition': { duration: 300 },
      },
    });
    setTimeout(() => this.map.setPaintProperty(fillLayer, 'fill-opacity', 0.3), 0);
    this.map.addLayer({
      id: borderLayer,
      type: 'line',
      source,
      layout: {},
      paint: {
        'line-color': color,
        'line-width': ['case', ['==', ['feature-state', 'hover'], true], 2, 0],
      },
    });
    let hoveredId: any = null;
    this.map.on('mousemove', fillLayer, (e) => {
      if (get(e, 'features.length', 0)) {
        if (hoveredId !== null) {
          if (this.map.getSource(source)) this.map.setFeatureState({ source, id: hoveredId }, { hover: false });
        }
        hoveredId = get(head(get(e, 'features')), 'id') as string;
        if (this.map.getSource(source)) this.map.setFeatureState({ source, id: hoveredId }, { hover: true });
        if (click) this.setCursor('pointer');
        else this.setCursor('default');
      }
    });
    this.map.on('mouseleave', fillLayer, (e) => {
      if (hoveredId !== null) {
        if (this.map.getSource(source)) this.map.setFeatureState({ source, id: hoveredId }, { hover: false });
        hoveredId = null;
        this.setCursor('default');
      }
    });
    if (click) this.map.on('click', fillLayer, (e) => click(e));
  }

  public async addMapModel(data: MapModel): Promise<any> {
    if (!this.map) {
      return new Promise((resolve) => this.ready.pipe(take(1)).subscribe(() => this.addMapModel(data).then(resolve)));
    }
    const obj = await Storage.get(data.model.file.key, { level: 'public' });
    this.map.addLayer({
      id: `map-model-${v4()}`,
      type: 'custom',
      renderingMode: '3d',
      onAdd: (map, mbxContext) => {
        var options = {
          obj,
          type: data.model.kind,
          scale: 1,
          units: 'meters',
          rotation: data.rotation,
          anchor: 'center',
        };

        this.tb.loadObj(options, (model: any) => {
          let obj = model.setCoords(data.coordinates);
          obj.addEventListener('ObjectDragged', (event: any) => {
            data.coordinates = slice(get(event, 'detail.draggedObject.coordinates'), 0, 2) as [number, number];
            data.rotation!.y = get(event, 'detail.draggedObject.rotation.z') * (180 / Math.PI);
          });
          this.tb.add(obj);
        });
      },
      render: (gl, matrix) => this.tb.update(),
    });
  }

  public async addModel(
    data: {
      url: string;
      kind: Model3DKind;
      coordinates: [number, number, number];
      rotation?: number;
      scale?: number;
      // followView?: boolean;
    },
    dragged?: (coordinates: [number, number, number], rotation: number) => void,
  ) {
    let obj: any;
    const rotation = { x: 90, y: 0, z: data.rotation };
    this.map.addLayer({
      id: v4(),
      type: 'custom',
      renderingMode: '3d',
      onAdd: (map, mbxContext) => {
        var options = {
          obj: data.url,
          type: data.kind,
          scale: data.scale || 1,
          units: 'meters',
          rotation,
          anchor: 'center',
        };

        this.tb.loadObj(options, (model: any) => {
          obj = model.setCoords(data.coordinates);
          obj.addEventListener('ObjectDragged', (event: any) => {
            if (dragged) {
              dragged(
                get(event, 'detail.draggedObject.coordinates') as [number, number, number],
                get(event, 'detail.draggedObject.rotation.z') * (180 / Math.PI),
              );
            }
          });
          this.tb.add(obj);
        });
      },

      render: (gl, matrix) => {
        this.tb.update();
      },
    });

    // this.map.on('rotate', (e) => {
    //   const bearing = this.map.getBearing();
    //   rotation.y = -bearing * (Math.PI / 180);
    //   if (building) building.rotation.set(rotation.x, rotation.y, rotation.z);
    // });

    // this.map.on('pitch', (e) => {
    //   const pitch = this.map.getPitch();
    //   rotation.x = pitch * (Math.PI / 180);
    //   if (building) building.rotation.set(rotation.x, rotation.y, rotation.z);
    // });
  }

  public addMarker(marker: IMarker): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.addMarker(marker));
    }
    this.dataMarkers.push(merge({ id: this.dataMarkers.length, type: 'text' }, cloneDeep(marker)));
    this.updateSource();
  }

  public setMarkers(markers: IMarker[]): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.setMarkers(markers));
    }
    each(filter(this.dataMarkers, { type: 'default' }) as IDefaultMarker[], (d: IDefaultMarker) => d.marker.remove());
    this.dataMarkers = map(cloneDeep(markers), (m, i) => merge({ id: i, type: 'text' }, m));
    this.updateSource();
  }

  public removeMarkers(): any {
    if (!this.isReady) {
      return this.ready.pipe(take(1)).subscribe(() => this.removeMarkers());
    }
    each(filter(this.dataMarkers, { type: 'default' }) as IDefaultMarker[], (d: IDefaultMarker) => {
      if (d.marker) {
        d.marker.getElement().className = d.marker.getElement().className.replace(' display', '');
        setTimeout(() => d.marker.remove(), 300);
      }
    });
    this.dataMarkers = [];
    this.markers = {};
    this.updateSource();
  }

  inview($event: IntersectionObserverEvent): void {
    if ($event.intersect && !this.map) {
      this.initMap();
    }
  }

  notSupported = false;
  private initMap(): void {
    if (this.notSupported) return;
    mapboxgl.accessToken = environment.mapboxAccessToken;
    this.map = new mapboxgl.Map({
      container: `map-${this.id}`,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.options.center,
      zoom: this.options.zoom,
      minZoom: this.options.minZoom,
      maxZoom: this.options.maxZoom,
      maxBounds: this.options.maxBounds,
      attributionControl: false,
    });
    this.initThreebox();
    this.map.on('moveend', (event) => this.movedEmitter.emit(event));

    this.enabledControls();

    this.map.on('load', async () => {
      this.map.resize();
      if (this.options.building) this.loadBuildings();
      this.setSource();
      this.updateSource();
      this.map.on('render', () => {
        if (this.map.getSource('markers') && this.map.isSourceLoaded('markers')) {
          this.updateMarkers();
        }
      });
      this.isLoading = false;
      this.isReady = true;
      this.ready.emit(this);
    });

    this.map.on('click', (event) => this.clickedEmitter.emit(event));
  }

  private initThreebox() {
    this.tb = (window as any).tb = new Threebox(this.map, this.map.getCanvas().getContext('webgl'), {
      defaultLights: true,
      enableSelectingFeatures: false, //change this to false to disable fill-extrusion features selection
      enableSelectingObjects: this.options.tb?.enableSelectingObjects, //change this to false to disable 3D objects selection
      enableDraggingObjects: this.options.tb?.enableDraggingObjects, //change this to false to disable 3D objects drag & move once selected
      enableRotatingObjects: this.options.tb?.enableRotatingObjects, //change this to false to disable 3D objects rotation once selected
      enableTooltips: false, // change this to false to disable default tooltips on fill-extrusion and 3D models
    });
  }

  private async loadBuildings() {
    const mapModels = await this.realEstateStructureService.getMapModels();

    const layers = this.map.getStyle().layers;
    const labelLayerId = get(
      find(layers, (layer) => layer.type === 'symbol' && get(layer, 'layout.text-field')),
      'id',
    );

    const features: any[] = [];
    for (let data of mapModels) {
      if (data.kind === 'feature' && data.features?.length) {
        features.push(...data.features);
      } else if (data.kind === 'model') {
        await this.addMapModel(data);
      }
    }

    this.map.addSource('building-3d', {
      type: 'geojson',
      generateId: true,
      data: { type: 'FeatureCollection', features },
    });

    this.map.addLayer(
      {
        id: '3d-buildings',
        source: 'building-3d',
        type: 'fill-extrusion',
        paint: {
          // 'fill-extrusion-color': ['get', 'color'],
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 14, 0, 14.05, ['get', 'height']],
          'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 14, 0, 14.05, ['get', 'min_height']],
          'fill-extrusion-opacity': 0.6,
        },
      },
      labelLayerId,
    );
  }

  public removeBuilding() {
    if (this.map.getLayer('3d-buildings')) this.map.removeLayer('3d-buildings');
  }

  public displayBuilding(selectedFeatures?: any[], selected?: (features: any) => void) {
    if (!this.map.getLayer('3d-buildings')) {
      const layers = this.map.getStyle().layers;
      const labelLayerId = get(
        find(layers, (layer) => layer.type === 'symbol' && get(layer, 'layout.text-field')),
        'id',
      );
      this.map.addLayer(
        {
          id: '3d-buildings',
          source: 'composite',
          'source-layer': 'building',
          filter: ['==', 'extrude', 'true'],
          type: 'fill-extrusion',
          paint: {
            'fill-extrusion-color': ['case', ['boolean', ['feature-state', 'selected'], false], 'red', '#aaa'],
            'fill-extrusion-height': ['get', 'height'],
            'fill-extrusion-base': ['get', 'min_height'],
            'fill-extrusion-opacity': 0.8,
          },
        },
        labelLayerId,
      );
    }

    const findParent = (features: any[]) => {
      const clicked = features[0];
      if (clicked?.properties?.type === 'building:part') {
        const all_features = this.map.queryRenderedFeatures({
          layers: ['3d-buildings'],
        } as any);
        let parent;
        all_features.every((feature: any) => {
          if (feature.id === clicked.properties.parent) {
            parent = feature;
            return false;
          } else {
            return true;
          }
        });
        return parent ? parent : clicked;
      } else {
        return clicked;
      }
    };

    let fselected: any[] = Array.isArray(selectedFeatures) ? cloneDeep(selectedFeatures) : [];
    each(fselected, (f) =>
      this.map.setFeatureState({ source: 'composite', sourceLayer: 'building', id: f.id }, { selected: true }),
    );

    this.map.on('click', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['3d-buildings'],
      });
      if (features) {
        const parent = findParent(features);
        if (parent?.id) {
          const sindex = findIndex(fselected, { id: parent.id });
          if (sindex !== -1) {
            this.map.setFeatureState(
              { source: 'composite', sourceLayer: 'building', id: parent.id },
              { selected: false },
            );
            fselected.splice(sindex, 1);
            if (selected) selected(fselected);
          } else if (parent.geometry) {
            this.map.setFeatureState(
              { source: 'composite', sourceLayer: 'building', id: parent.id },
              { selected: true },
            );
            const data = {
              id: parent.id,
              properties: parent.properties,
              type: parent.type,
              geometry: parent.geometry,
            };
            if (fselected) fselected.push(data);
            if (selected) selected(fselected);
          }
        }
      }
    });
  }

  private enabledControls(): void {
    if (this.options.enableNavControl) {
      this.navigationControl = new NavigationControl();
      this.map.addControl(this.navigationControl);
    } else if (this.navigationControl) {
      this.map.removeControl(this.navigationControl);
      this.navigationControl = null;
    }
    if (this.options.disableKeyboard) {
      this.map.keyboard.disable();
    } else {
      this.map.keyboard.enable();
    }
    if (this.options.disableDrag) {
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
    } else {
      this.map.dragPan.enable();
      this.map.dragRotate.enable();
    }
    if (this.options.disableZoom) {
      this.map.touchZoomRotate.disableRotation();
      this.map.boxZoom.disable();
      this.map.doubleClickZoom.disable();
    } else {
      this.map.touchZoomRotate.enableRotation();
      this.map.boxZoom.enable();
      this.map.doubleClickZoom.enable();
    }
    if (this.options.disableZoom || this.options.zoomWithCtrl) {
      this.map.scrollZoom.disable();
    } else {
      this.map.scrollZoom.enable();
    }
    if (this.options.zoomControl) {
      this.zoomControl = new ZoomControl(this.options.zoomControl);
      this.map.addControl(this.zoomControl);
    } else if (this.zoomControl) {
      this.map.removeControl(this.zoomControl);
      this.zoomControl = null;
    }
  }

  private setSource(): void {
    if (this.map.getSource('markers')) {
      this.map.removeLayer('marker_inner-circle');
      this.map.removeLayer('marker_circle');
      this.map.removeLayer('marker_label');
      this.map.removeSource('markers');
    }

    const clusterProperties = this.options.clusterProperties
      ? Object.keys(this.options.clusterProperties).reduce(
          (pv, cv) => ((pv[cv] = this.options.clusterProperties![cv].merge), pv),
          {} as any,
        )
      : {};

    this.map.addSource('markers', {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: [] },
      cluster: this.options.cluster,
      clusterMaxZoom: this.options.clusterMaxZoom || this.options.maxZoom,
      clusterRadius: this.options.clusterRadius,
      clusterProperties: merge(clusterProperties, {
        ids: ['concat', ['concat', ['get', 'id'], '#']],
      }),
    });

    this.addLayers();
  }

  private addLayers(): void {
    this.map.addLayer({
      id: 'marker_circle',
      type: 'circle',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['==', '__marker_type', 'text']],
      paint: {
        'circle-color': ['get', '__marker_color'],
        'circle-radius': ['get', '__marker_radius'],
      },
    });

    this.map.addLayer({
      id: 'marker_inner-circle',
      type: 'circle',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['==', '__marker_type', 'text']],
      paint: {
        'circle-color': 'white',
        'circle-radius': ['*', ['get', '__marker_radius'], 0.6],
      },
    });

    this.map.addLayer({
      id: 'marker_label',
      type: 'symbol',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['==', '__marker_type', 'text']],
      layout: {
        'text-field': ['get', '__marker_text'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': ['get', '__marker_text-size'],
      },
      paint: {
        'text-color': ['get', '__marker_text-color'],
      },
    });

    this.map.addLayer({
      id: 'marker_icon',
      type: 'symbol',
      source: 'markers',
      filter: ['all', ['!=', 'cluster', true], ['==', '__marker_type', 'icon']],
      layout: {
        'icon-image': ['get', '__marker_icon'],
        'icon-size': ['get', '__marker_icon_size'],
      },
    });

    if (this.options.popup) {
      this.map.on('mouseenter', 'marker_circle', () => this.setCursor('pointer'));
      this.map.on('mouseleave', 'marker_circle', () => (this.map.getCanvas().style.cursor = ''));
      this.map.on('click', 'marker_circle', (e: any) => {
        const coords = e.features[0].geometry.coordinates.slice();
        const ids = e.features.map((f: any) => f.properties.id);
        const html = this.options.popup!(this.dataMarkers.filter((d) => ids.includes(d.id)));
        if (this.markerPopups) this.markerPopups.remove();
        if (html) this.markerPopups = new Popup().setLngLat(coords).setHTML(html).addTo(this.map);
      });
    }
    if (this.options.click) {
      this.map.on('mouseenter', 'marker_circle', () => this.setCursor('pointer'));
      this.map.on('mouseleave', 'marker_circle', () => this.setCursor('default'));
      this.map.on('mouseenter', 'marker_icon', () => this.setCursor('pointer'));
      this.map.on('mouseleave', 'marker_icon', () => this.setCursor('default'));
      const click = (e: any) => {
        const coords = e.features[0].geometry.coordinates.slice();
        const ids = e.features.map((f: any) => f.properties.id);
        this.options.click!(this.dataMarkers.filter((d) => ids.includes(d.id)));
      };
      this.map.on('click', 'marker_circle', click);
      this.map.on('click', 'marker_icon', click);
    }
  }

  private async updateSource() {
    const source: GeoJSONSource = this.map.getSource('markers') as GeoJSONSource;
    if (!source) return;
    const data: any = {
      type: 'FeatureCollection',
      features: [],
    };
    const icons = new Set<string>();
    each(this.dataMarkers, (d) => {
      if (d.type === 'text') {
        data.features.push(this.buildTextMarker(d));
      } else if (d.type === 'icon') {
        icons.add(d.icon);
        data.features.push(this.buildIconMarker(d));
      } else if (d.type == 'default' && !d.marker) {
        const el = this.buildMarkerElement(d);
        d.marker = new mapboxgl.Marker(el, { draggable: typeof d.drag === 'function' })
          .setLngLat(d.position)
          .addTo(this.map);
        if (typeof d.drag === 'function') d.marker.on('dragend', () => d.drag!(d.marker.getLngLat().toArray()));
      }
    });
    const promises: Promise<void>[] = [];
    icons.forEach((icon) => {
      promises.push(
        new Promise((resolve, reject) => {
          this.map.loadImage(icon, (err, image) => {
            if (err) this.logger.error(err);
            if (!image) this.logger.error(`[${icon}] image not loaded`);
            else if (!this.map.hasImage(icon)) this.map.addImage(icon, image);
            resolve();
          });
        }),
      );
    });
    await Promise.all(promises);
    source.setData(data);
  }

  private buildTextMarker(d: ITextMarker) {
    return {
      type: 'Feature',
      properties: merge(cloneDeep(d.properties) || {}, {
        id: d.id,
        __marker_type: d.type,
        __marker_text: d.text,
        __marker_color: d.color || '#3f5264',
        __marker_radius: d.radius || 16,
        '__marker_text-color': d.textColor || '#000000',
        '__marker_text-size': d.textSize || 14,
      }),
      geometry: {
        type: 'Point',
        coordinates: d.position,
      },
    };
  }

  private buildIconMarker(d: IIconMarker) {
    return {
      type: 'Feature',
      properties: merge(cloneDeep(d.properties) || {}, {
        id: d.id,
        __marker_type: d.type,
        __marker_icon: d.icon,
        __marker_icon_size: d.iconSize,
        __marker_color: d.color || '#3f5264',
      }),
      geometry: {
        type: 'Point',
        coordinates: d.position,
      },
    };
  }

  private buildMarkerElement(d: IMarker): HTMLElement {
    const el = document.createElement('div');
    el.id = `marker_${d.id!}`;
    el.className = 'map-marker';
    el.innerHTML = MapComponent.MARKER_SVG;
    el.style.color = d.color || '#3f5264';
    el.style.width = el.style.height = el.style.fontSize = '50px';
    if (typeof d.click === 'function') {
      el.addEventListener('mouseover', () => (el.style.cursor = 'pointer'));
      el.addEventListener('mouseleave', () => (el.style.cursor = 'default'));
      el.addEventListener('click', (event) => d.click!(event));
    }
    setTimeout(() => (el.className += ' display'), 0);
    return el;
  }

  private updateMarkers(): void {
    if (!this.options.cluster) return;
    const newMarkers: any = {};
    const features = this.map.querySourceFeatures('markers');
    features.forEach((feature: any) => {
      const properties = feature.properties;
      if (!properties.cluster) return;
      const coords = feature.geometry.coordinates;
      const id = properties.cluster_id;
      let marker = this.markers[id];
      if (!marker) {
        const element = this.createDonutChart(properties);
        element.addEventListener('click', (e: Event) => this.markerClickHandler(e, feature));
        marker = this.markers[id] = new mapboxgl.Marker({ element }).setLngLat(coords);
      }
      newMarkers[id] = marker;
      if (!this.markersOnScreen[id]) {
        marker.addTo(this.map);
      }
    });
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove();
      }
    }
    this.markersOnScreen = newMarkers;
  }

  private createDonutChart(properties: { [status: string]: number }): ChildNode {
    const counts = Object.keys(this.options.clusterProperties as any).map((k) => ({
      color: this.options.clusterProperties![k].color || '#3f5264',
      value: properties[k],
    }));
    const offsets: number[] = [];
    let total = 0;
    counts.forEach((c) => (offsets.push(total), (total += c.value)));
    const fontSize = total >= 1000 ? 16 : 14;
    const r = total >= 1000 ? 34 : total >= 100 ? 25 : total >= 10 ? 20 : 16;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;
    const html = `<div style="cursor: pointer;">
      <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">
        ${counts
          .map((c, i) =>
            this.donutSegment(
              total ? offsets[i] / total : 0,
              total ? (offsets[i] + c.value) / total : 0,
              r,
              r0,
              c.color,
            ),
          )
          .join('\n')}
        <circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
        <text dominant-baseline="central" transform="translate(${r}, ${r})"> ${total.toLocaleString()}</text>
      </svg>
    </div>`;
    const el = document.createElement('div');
    el.innerHTML = html;
    return el.firstChild!;
  }

  private donutSegment(start: number, end: number, r: number, r0: number, color: string): string {
    if (end - start === 1) {
      end -= 0.00001;
    }
    const a0 = 2 * Math.PI * (start - 0.25);
    const a1 = 2 * Math.PI * (end - 0.25);
    const x0 = Math.cos(a0);
    const y0 = Math.sin(a0);
    const x1 = Math.cos(a1);
    const y1 = Math.sin(a1);
    const largeArc = end - start > 0.5 ? 1 : 0;
    return `<path
      d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${
        r + r * y1
      } L ${r + r0 * x1} ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0}"
      fill="${color}"
    />`;
  }

  private markerClickHandler(e: Event, feature: any): void {
    const properties = feature.properties;
    const coords = feature.geometry.coordinates;
    const id = properties.cluster_id;
    if (this.options.popup && this.map.getZoom() === this.map.getMaxZoom()) {
      const ids = properties.ids.split('#');
      const html = this.options.popup(this.dataMarkers.filter((d) => ids.includes(d.id?.toString())));
      if (this.markerPopups) this.markerPopups.remove();
      if (html) this.markerPopups = new Popup().setLngLat(coords).setHTML(html).addTo(this.map);
      e.stopPropagation();
    } else if (this.options.click && this.map.getZoom() === this.map.getMaxZoom()) {
      const ids = properties.ids.split('#');
      this.options.click(this.dataMarkers.filter((d) => ids.includes(d.id?.toString())));
      e.stopPropagation();
    } else {
      (this.map.getSource('markers') as GeoJSONSource).getClusterExpansionZoom(id, (err, zoom) => {
        if (err) throw err;
        this.map.easeTo({ center: coords, zoom });
      });
    }
  }

  private setCursor(cursor: string, el?: HTMLElement) {
    if (el) el.style.cursor = cursor;
    this.map.getCanvas().style.cursor = cursor;
    (this.map.getCanvas().nextSibling as any)!.style.cursor = cursor;
  }
}
