/** @format */

import { Injectable, inject } from '@angular/core';
import { Storage } from 'aws-amplify';
import { get, has, keys, map as lmap, reduce, set, values } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { v4 } from 'uuid';
import { EquipmentCategory } from '../_classes/equipment-category.class';
import { S3File } from '../_classes/s3-file.class';
import { TreeNode } from '../_classes/tree-node.class';
import { EquipmentFamily } from '../_constants/equipment-family';
import { EquipmentStatus } from '../_constants/equipment-status';
import { CrudServiceBuild } from './crud.service';
import { FileService } from './file.service';

export interface IEquipmentCategoryFilter {
  search?: string;
  families?: EquipmentFamily[];
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentCategoryService extends CrudServiceBuild<EquipmentCategory, IEquipmentCategoryFilter>(
  EquipmentCategory,
  '/equipment-category',
) {
  fileService: FileService = inject(FileService);

  async countByFamily(): Promise<{ family: EquipmentFamily; count: number }[]> {
    const path = `${this.path}/count-by-family`;
    return this.httpService.get(path);
  }

  async getIcons(id: string): Promise<{ [status: string]: string }> {
    const category = await this.get(id);
    if (!category) return {};
    const icons: string[] = await Promise.all<any>(
      lmap(keys(category.icons), async (status: EquipmentStatus) => {
        if (!category.icons[status]) return null;
        return Storage.get(get(category, `icons.${status}.key`, ''), { level: 'public' });
      }),
    );
    return reduce(
      keys(category.icons),
      (pv, cv, index) => ((pv[cv] = icons[index]), pv),
      {} as { [status: string]: string },
    );
  }

  async getTrees(): Promise<TreeNode[]> {
    const path = `${this.path}/get-trees`;
    return this.httpService.post(path).then((data) => lmap(data, (d) => new ModelMapper(TreeNode).map(d)));
  }

  override async processData(data: any, action: 'create' | 'update'): Promise<any> {
    let s3Path = get(data, 's3Path');
    if (!s3Path && action === 'create') set(data, 's3Path', (s3Path = v4()));
    if (!s3Path) throw new Error(`missing s3Path`);
    const icons = {};
    for (let status of values(EquipmentStatus)) {
      if (has(data, `icons.${status}`)) {
        const s3File = get(data, `icons.${status}`) as S3File;
        if (s3File?.file) {
          set(
            icons,
            status,
            await this.fileService.pushFile(`${get(data, 's3Path')}/status-icon-${status}`, s3File.file),
          );
        } else set(icons, status, null);
      }
    }
    if (keys(icons).length) set(data, 'icons', icons);
    return super.processData(data, action);
  }
}
