/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Module } from './_constants/module';
import { AdminGuard } from './_guards/admin.guard';
import { ModuleGuard } from './_guards/module.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: Module.REAL_ESTATE_ASSETS,
    data: { module: Module.REAL_ESTATE_ASSETS },
    canActivate: [ModuleGuard],
    loadChildren: () =>
      import('./real-estate-assets/real-estate-assets-page.module').then(m => m.RealEstateAssetsPageModule),
  },
  {
    path: Module.AIR_QUALITY,
    data: { module: Module.AIR_QUALITY },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./air-quality/air-quality-page.module').then(m => m.AirQualityModule),
  },
  {
    path: Module.ENERGY,
    data: { module: Module.ENERGY },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./energy/energy-page.module').then(m => m.EnergyPageModule),
  },
  {
    path: Module.VENTILATION_AND_AIR_CONDITIONING,
    data: { module: Module.VENTILATION_AND_AIR_CONDITIONING },
    canActivate: [ModuleGuard],
    loadChildren: () =>
      import('./ventilation-and-air-conditioning/ventilation-and-air-conditioning-page.module').then(
        m => m.VentilationAndAirConditioningModule,
      ),
  },
  {
    path: Module.ELEVATOR,
    data: { module: Module.ELEVATOR },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./elevator/elevator-page.module').then(m => m.ElevatorPageModule),
  },
  {
    path: Module.PARKING_DOOR,
    data: { module: Module.PARKING_DOOR },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./parking-door/parking-door-page.module').then(m => m.ParkingDoorModule),
  },
  {
    path: Module.FIRE_SAFETY,
    data: { module: Module.FIRE_SAFETY },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./fire-safety/fire-safety-page.module').then(m => m.FireSafetyPageModule),
  },
  {
    path: Module.IOT,
    data: { module: Module.IOT },
    canActivate: [ModuleGuard],
    loadChildren: () => import('./iot/iot-page.module').then(m => m.IotPageModule),
  },
  {
    path: 'administration',
    canActivate: [AdminGuard],
    loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: '**',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
