/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map } from 'lodash-es';
import { Equipment } from '../../_classes/equipment/equipment.class';
import { fadeOut } from '../../_constants/animations';
import { EquipmentDoorNameName } from '../../_constants/equipment-door/equipment-door-name';
import { EquipmentDoorStateName } from '../../_constants/equipment-door/equipment-door-state';
import { EquipmentStatusColorName, EquipmentStatusIcon, EquipmentStatusName } from '../../_constants/equipment-status';
import { EquipmentService } from '../../_services/equipment/equipment.service';

export interface IElement {
  _id: string;
}

interface IDialogData {
  elements: IElement[];
  navigateTo: (element: IElement) => Promise<void>;
}

@Component({
  selector: 'app-equipment-status-dialog',
  templateUrl: './equipment-status-dialog.component.html',
  styleUrls: ['./equipment-status-dialog.component.scss'],
  animations: [fadeOut],
})
export class EquipmentStatusDialogComponent implements OnInit {
  EquipmentStatusColor = EquipmentStatusColorName;
  EquipmentStatusName = EquipmentStatusName;
  EquipmentStatusIcon = EquipmentStatusIcon;
  EquipmentDoorNameName = EquipmentDoorNameName;
  EquipmentDoorStateName = EquipmentDoorStateName;

  equipments: Equipment[];
  loading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
    public dialogRef: MatDialogRef<EquipmentStatusDialogComponent>,
    private equipmentService: EquipmentService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.equipments = await Promise.all(map(this.data.elements, (element) => this.equipmentService.get(element._id)));
    this.loading = false;
  }
}
