<div id="live-info-block" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
    <span color="primary" light i18n>Distance</span>
    <div *ngIf="floorsData.nbTicks" class="distance" fxLayout="column" fxLayoutAlign="start start"
      [style.height.px]="floorsData.blockHeight">

      <div id="ruler" fxLayout="column" fxLayoutAlign="end center" [style.bottom.px]="floorsData.ruleBottomOffset"
        [style.height.px]="floorsData.rulerHeight">
        <div *ngFor="let meter of ' '.repeat(floorsData.nbTicks).split(' '); index as index" class="meter"
          [style.min-height.px]="floorsData.tickHeight" [style.height.px]="floorsData.tickHeight">
          <span class="meter-value" color="medium">
            {{(floorsData.nbTicks - index) + (floorsData.minGroundDistance / 100 | floor )}}
            <span i18n> m</span>
          </span>
        </div>
      </div>

      <div *ngIf="lidarData" class="current-height" [style.height.px]="floorsData.tickHeight"
        [style.bottom.px]="((( floorsData.maxDistance - lidarData.distance ) / 100) * floorsData.tickHeight)"
        fxLayout="row" fxLayoutAlign="center center">
        <app-count-up [value]="(floorsData.baseDistance - lidarData.distance ) / 100" decimalPlaces="1" i18n-suffix
          suffix=" m"></app-count-up>
      </div>

    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" fxFlex="grow">
    <span color="primary" light i18n>Niveaux</span>
    <div #floorsBlock class="floors" fxLayout="column" fxLayoutAlign="start start"
      [style.height.px]="floorsData.blockHeight">
      <div #floorRef *ngFor="let floor of floors | reverse; index as index; last as last"
        class="floor state-{{doorData?.state}} ripple-parent rectangle" [class.last]="last"
        [style.height.px]="49 * (floor.height / floorsData.minHeight)" fxLayout="column" fxLayoutAlign="center center"
        [class.doubt-removal]="!!floor.sesioNodeComponentId"
        [class.active]="lidarData.speed === 0 &&  floors.length - index - 1 === lidarData.floorIndex"
        [class.direction-up]="lidarData.direction > 0" [class.direction-down]="lidarData.direction < 0"
        [class.doors-open]="doorData && [EquipmentDoorState.OPEN].includes(doorData.state)"
        [class.activatable]="!!floor.sesioNodeComponentId " (click)="sendTo(floor)" matRipple>
        <div class="floor-info" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px" i18n>
          <span clear-style [innerHTML]="floor.name | safe:'html'"></span>
        </div>
        <div *ngIf="lidarData.speed === 0 && floors.length - index - 1 === lidarData.floorIndex"
          class="doors-open state-{{doorData?.state}}" fxLayout="column" fxLayoutAlign="center center">
          <div class="doors-open-floor-info" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px"
            [style.width.px]="floorsBlock.clientWidth" i18n>
            <span clear-style [innerHTML]="floor.name | safe:'html'"></span>
          </div>
        </div>
      </div>
      <div #currentFloorRef class="current-floor"
        [style.top]="'calc(min(' + (((lidarData | get:'distance':0) - floorsData.minDistance) / floorsData.height) * 100 + '%, calc(100% - 50px)) + 1px)'">
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
    <span color="primary" light i18n>Vitesse</span>
    <div class="speed" fxLayout="column" fxLayoutAlign="start start" [style.height.px]="floorsData.blockHeight">
      <div id="speed-line">
        <div id="max-speed-line">{{speedConfig.blocking}} <span i18n> m/s</span></div>
        <div id="nominal-speed" fxLayout="column" fxLayoutAlign="center center"
          [style.bottom.%]="(speedConfig.nominal / speedConfig.blocking) * 100">
          <span i18n-matTooltip matTooltip="vitesse nominale" i18n>{{speedConfig.nominal}} <span> m/s</span></span>
        </div>
        <div id="min-speed-line">0 <span i18n> m/s</span></div>
        <div *ngIf="lidarData"
          class="current-speed color-{{lidarData.speed >= speedConfig.blocking ? 'high' : lidarData.speed < speedConfig.nominal ? 'low' : 'normal' }}"
          [style.bottom.%]="[(lidarData.speed / speedConfig.blocking) * 100, 100] | min" fxLayout="row"
          fxLayoutAlign="center center">
          <app-count-up [value]="lidarData.speed" decimalPlaces="1" i18n-suffix suffix=" m/s"></app-count-up>
        </div>
      </div>
    </div>
  </div>

</div>
