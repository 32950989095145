/** @format */

import { findIndex } from 'lodash';
import { filter, findLastIndex, isNil, orderBy, round } from 'lodash-es';
import { stripHtml } from 'string-strip-html';
import { ElevatorFloor } from '../_classes/equipment/equipment.elevator.class';
import { EquipmentFloorKind } from '../_constants/equipment-floor-kind';

export const DISTANCE_OFFSET = 30;

interface IFloor {
  distance: number;
  minDistance: number | null;
  maxDistance: number | null;
}

export function getNextFloorIndex(floors: IFloor[], distance: number): number {
  return findIndex(floors, (f) => distance > (isNil(f.maxDistance) ? f.distance : f.maxDistance));
}

export function isInFloor(floor: IFloor, distance: number): boolean {
  const floorMinDistance = (isNil(floor.minDistance) ? floor.distance : floor.minDistance) - DISTANCE_OFFSET;
  const floorMaxDistance = (isNil(floor.maxDistance) ? floor.distance : floor.maxDistance) + DISTANCE_OFFSET;
  return distance >= floorMinDistance && distance <= floorMaxDistance;
}

export function getFloorIndex(
  floorsData: ElevatorFloor[] | undefined,
  distance: number | undefined,
): number | undefined {
  if (isNil(floorsData) || isNil(distance)) return;
  const floors = getFloors(floorsData);
  if (!floors?.length) return;
  let index = getNextFloorIndex(floors, distance);
  if (index === -1) index = floors.length - 1;
  let currentFloorIndex;
  if (index === 0 || isInFloor(floors[index], distance)) currentFloorIndex = index;
  else if (isInFloor(floors[index - 1], distance)) currentFloorIndex = index - 1;
  else {
    const floorDistance = Math.abs(floors[index].distance! - floors[index - 1].distance!);
    currentFloorIndex = index - 1 + (1 - Math.abs(distance - floors[index].distance!) / floorDistance);
  }
  return currentFloorIndex;
}

export function getFloorName(floorsData: ElevatorFloor[], distance: number, html = false): string | undefined {
  const floorIndex = getFloorIndex(floorsData, distance);
  if (isNil(floorIndex) || floorIndex === -1) return;
  if (parseInt(floorIndex.toString()) === floorIndex) {
    const name = getFloors(floorsData)[floorIndex]?.name;
    return !name || html ? name : stripHtml(name)?.result;
  }
  const top = getFloors(floorsData)[Math.ceil(floorIndex)]?.name;
  const topName = !top || html ? top : stripHtml(top)?.result;
  const bottom = getFloors(floorsData)[Math.floor(floorIndex)]?.name;
  const bottomName = !bottom || html ? bottom : stripHtml(bottom)?.result;
  return `${bottomName} <> ${topName}`;
}

export function getFloor(floorsData: ElevatorFloor[], distance: number): ElevatorFloor | undefined {
  const floorIndex = getFloorIndex(floorsData, distance);
  if (isNil(floorIndex) || floorIndex === -1) return;
  return getFloors(floorsData)[floorIndex];
}

export function getBaseFloorIndex(floorsData: ElevatorFloor[]): number {
  const floors = getFloors(floorsData);
  if (!floors?.length) return -1;
  let baseIndex = findIndex(floors, (f) => f.kind === EquipmentFloorKind.GROUND_FLOOR);
  if (baseIndex !== -1) return baseIndex;
  const lastBasementIndex = findLastIndex(floors, (f) => f.kind === EquipmentFloorKind.BASEMENT);
  if (lastBasementIndex === floors.length - 1) return lastBasementIndex;
  if (lastBasementIndex !== -1) return lastBasementIndex + 1;
  baseIndex = findIndex(floors, (f) => f.kind === EquipmentFloorKind.FLOOR);
  return baseIndex;
}

export function getBaseFloor(floorsData: ElevatorFloor[]): ElevatorFloor | undefined {
  const baseIndex = getBaseFloorIndex(floorsData);
  if (baseIndex === -1) return;
  return getFloors(floorsData)[baseIndex];
}

export function getFloorHeight(floor: ElevatorFloor, floorsData: ElevatorFloor[]): number | undefined {
  if (isNil(floor.distance)) return;
  const base = getBaseFloor(floorsData);
  if (!base) return; // TODO
  return round(base.distance! - floor.distance);
}

function getFloors(floorsData: ElevatorFloor[]): (ElevatorFloor & { distance: number })[] {
  return orderBy(
    filter(floorsData, (f) => !isNil(f.distance)) as (ElevatorFloor & { distance: number })[],
    ['distance'],
    ['desc'],
  );
}
