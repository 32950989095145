import { PropertyMap } from 'model-mapper';
import { Model3D } from './model-3d.class';

export class MapModelRotation {
  @PropertyMap()
  x: number;

  @PropertyMap()
  y: number;

  @PropertyMap()
  z: number;
}

export class MapModel {
  @PropertyMap()
  kind: 'feature' | 'model';

  @PropertyMap()
  features: any[];

  @PropertyMap({ type: Model3D })
  model: Model3D;

  @PropertyMap()
  coordinates: [number, number];

  @PropertyMap()
  altitude?: number;

  @PropertyMap({ type: MapModelRotation })
  rotation?: MapModelRotation;
}
