/** @format */

import { Injectable } from '@angular/core';
import { get, isNil, filter as lfilter, map as lmap, set, snakeCase } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { Observable, from } from 'rxjs';
import { v4 } from 'uuid';
import { MapModel } from '../_classes/map-model.class';
import { OrganizationalUnit } from '../_classes/organizational-unit.class';
import { RealEstateStructureKind } from '../_classes/real-estate-structure-kind.class';
import { BoilerRoom } from '../_classes/real-estate-structure.boiler-room.class';
import { Building } from '../_classes/real-estate-structure.building.class';
import { RealEstateStructure } from '../_classes/real-estate-structure.class';
import { SearchItem } from '../_classes/search.class';
import { TreeNode } from '../_classes/tree-node.class';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { RealEstateStructureFamily } from '../_constants/real-estate-structure-family';
import { RealEstateStructureStatus } from '../_constants/real-estate-structure-status';
import { TagSerieValue } from '../_constants/tags';
import { CrudServiceBuild } from './crud.service';

interface ITreeNode extends TreeNode {
  children: ITreeNode[];
  count: number;
}

export interface IMapInfo {
  _id: string;
  reference: string;
  coordinates: [number, number];
  status: RealEstateStructureStatus;
  family: RealEstateStructureFamily;
  organizationalUnitId: string;
}

export type PieSerieValue = {
  name: string;
  value: number;
};

export interface IRealEstateStructureFilter {
  search?: SearchItem[];
  kinds?: (RealEstateStructureKind | string | null)[];
  families?: RealEstateStructureFamily[];
  parentId?: string;
  parentPath?: string;
  organizationalUnit?: OrganizationalUnit | string | null;
}

export interface ITreeRealEstateStructureFilter extends IRealEstateStructureFilter {
  excludeEquipment?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RealEstateStructureService extends CrudServiceBuild<RealEstateStructure, IRealEstateStructureFilter>(
  RealEstateStructure,
  '/real-estate-structure',
) {
  async getTreeLevel(filter?: ITreeRealEstateStructureFilter): Promise<TreeNode[]> {
    const path = `${this.path}/tree-level`;
    return this.httpService
      .post(path, this.getTreeFilterQuery(filter))
      .then((data) => lmap(data, (d) => new ModelMapper(TreeNode).map(d)));
  }

  async getMapModels(): Promise<MapModel[]> {
    const path = `${this.path}/map-model`;
    return this.httpService.get(path).then((data) => lmap(data, (d) => new ModelMapper(MapModel).map(d)));
  }

  async countDocument(filter?: IRealEstateStructureFilter): Promise<number> {
    const path = `${this.path}/count-document`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async countByStatus(
    filter?: IRealEstateStructureFilter,
  ): Promise<{ status: RealEstateStructureStatus; count: number }[]> {
    const path = `${this.path}/count-by-status`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async countByKind(): Promise<{ kind: RealEstateStructureKind; count: number }[]> {
    const path = `${this.path}/count-by-kind`;
    return this.httpService.get(path);
  }

  async getMapInfo(filter?: IRealEstateStructureFilter): Promise<IMapInfo[]> {
    const path = `${this.path}/map-info`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getTags(filter: IRealEstateStructureFilter): Promise<{ [tag: string]: number }> {
    const path = `${this.path}/tags`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getEnergyTags(filter: IRealEstateStructureFilter): Promise<TagSerieValue[]> {
    const path = `${this.path}/energy-tags`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getClimatTags(filter: IRealEstateStructureFilter): Promise<TagSerieValue[]> {
    const path = `${this.path}/climat-tags`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getByEnergyKind(filter: IRealEstateStructureFilter): Promise<PieSerieValue[]> {
    const path = `${this.path}/by-energy-kind`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getByEquipmentMode(filter: IRealEstateStructureFilter): Promise<PieSerieValue[]> {
    const path = `${this.path}/by-equipment-mode`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getGreenEnergyTags(filter: IRealEstateStructureFilter): Promise<TagSerieValue[]> {
    const path = `${this.path}/green-energy-tags`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getBuildings(id: string): Promise<Building[]> {
    const path = `${this.path}/buildings/${id}`;
    return this.httpService.get(path).then((data) => lmap(data, (d) => this.buildType(d)));
  }

  datatableDocument(
    query: IDatatableOptions,
    filter?: { families?: RealEstateStructureFamily[]; organizationalUnitId?: string },
  ): Observable<any> {
    const path = `${this.path}/datatable-document`;
    const queryParams: any = { query };
    if (filter?.families?.length) queryParams.families = filter.families;
    if (filter?.organizationalUnitId) queryParams.organizationalUnitId = filter.organizationalUnitId;
    return from(this.httpService.post(path, queryParams));
  }

  async getTrees(filter?: IRealEstateStructureFilter, click?: (node: ITreeNode) => void): Promise<ITreeNode[]> {
    const entities = await this.list(filter);
    const roots = entities.filter((e) => isNil(e.parent));
    return this.getNodeChildren(entities, roots, click);
  }

  private getNodeChildren(
    entities: RealEstateStructure[],
    childrenEntities: RealEstateStructure[],
    click?: (node: ITreeNode) => void,
  ): ITreeNode[] {
    return lmap(childrenEntities, (entity) => {
      const children = entities.filter((e) => e.parent === entity._id);
      const childrenNode = this.getNodeChildren(entities, children, click);
      const node: ITreeNode = {
        type: RealEstateStructure.name,
        entity,
        count: children.length,
        color: entity.kind.color,
        children: childrenNode,
        onClick: async () => (click ? click(node) : (node.hideChildren = !node.hideChildren)),
      };
      return node;
    });
  }

  override buildType(data: any): RealEstateStructure {
    switch (data.kind?.family as RealEstateStructureFamily) {
      case RealEstateStructureFamily.BUILDING:
        return new ModelMapper(Building).map(data);
      case RealEstateStructureFamily.BOILER_ROOM:
        return new ModelMapper(BoilerRoom).map(data);
      default:
        return super.buildType(data);
    }
  }

  override async processData(data: any, action: 'create' | 'update'): Promise<any> {
    let s3Path = get(data, 's3Path');
    if (!s3Path && action === 'create') set(data, 's3Path', (s3Path = v4()));
    if (!s3Path) throw new Error(`missing s3Path`);
    data.family = get(data, 'family', get(data, 'kind.family'));
    if (!data.family) throw new Error(`missing family`);
    const promises: Promise<any>[] = [];
    promises.push(
      this.httpService.processEmbeddedFiles(get(data, 'metadata.blueprints'), `${s3Path}/metadata_blueprints`),
    );
    const files = ['metadata.plan3d', 'metadata.photogrammetry'];
    for (let file of files) promises.push(this.httpService.processFile(get(data, file), s3Path, snakeCase(file)));
    promises.push(this.httpService.processEmbeddedFiles(get(data, 'documents'), `${s3Path}/documents`));
    promises.push(this.httpService.processFiles(get(data, 'pictures'), `${s3Path}/picture_library`));
    await Promise.all(promises);
    return super.processData(data, action);
  }

  override getFilterQuery(filter?: IRealEstateStructureFilter, fields?: string | string[], sorts?: string): any {
    const query: any = super.getFilterQuery(filter, fields, sorts);
    if (filter?.kinds?.length) {
      query.kindIds = lmap(
        lfilter(filter.kinds, (kind) => !!kind),
        (kind) => (kind instanceof RealEstateStructureKind ? kind._id : kind),
      );
    }
    if (filter?.families?.length) query.families = filter!.families;
    if (filter?.parentId !== undefined) query.parentId = filter.parentId;
    if (filter?.parentPath) query.parentPath = filter.parentPath;
    if (filter?.organizationalUnit) {
      query.organizationalUnitId =
        filter?.organizationalUnit instanceof OrganizationalUnit
          ? filter?.organizationalUnit._id
          : filter?.organizationalUnit;
    }
    return query;
  }

  private getTreeFilterQuery(filter?: ITreeRealEstateStructureFilter): any {
    const query = this.getFilterQuery(filter);
    if (filter?.excludeEquipment === true) query.excludeEquipment = true;
    return query;
  }
}
