/** @format */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import humanizeDuration from 'humanize-duration';
import {
  concat,
  each,
  filter,
  find,
  get,
  head,
  includes,
  indexOf,
  isNil,
  join,
  last,
  map as lmap,
  lowerCase,
  map,
  merge,
  orderBy,
  pick,
  set,
  toInteger,
  uniq,
  uniqBy,
} from 'lodash-es';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';
import { getFloorIndex } from 'src/app/_helpers/equipment.helper';
import { SubSink } from 'subsink';
import {
  Elevator,
  ElevatorFloor,
  ElevatorLastDoorState,
  ElevatorMetadata,
} from '../../_classes/equipment/equipment.elevator.class';
import { Extremes } from '../../_classes/extremes.class';
import { FieldHistory } from '../../_classes/field-history.class';
import { HumanizeFieldUpdateOrigin } from '../../_classes/field-update-origin.class';
import { EmbeddedSesioNodeComponent } from '../../_classes/sesio-node-component/sesio-node-component.embedded.class';
import { EmbeddedSesioNode } from '../../_classes/sesio-node/sesio-node.embedded.class';
import { SesioIotProvider } from '../../_classes/SesioIoTProvider.class';
import { fadeIn, fadeOut } from '../../_constants/animations';
import { ConnectivityStatusColor, ConnectivityStatusIcon } from '../../_constants/connectivity-status';
import { EquipmentDoorNameName } from '../../_constants/equipment-door/equipment-door-name';
import {
  EquipmentDoorState,
  EquipmentDoorStateName,
  EquipmentDoorStateNameLabel,
  getEquipmentDoorState,
} from '../../_constants/equipment-door/equipment-door-state';
import {
  EquipmentSignatureStatus,
  EquipmentSignatureStatusColor,
  EquipmentSignatureStatusIcon,
  EquipmentSignatureStatusName,
  EquipmentSignatureStatusOptions,
} from '../../_constants/equipment-signature-status';
import {
  EquipmentStatus,
  EquipmentStatusChartOptions,
  EquipmentStatusColorName,
  EquipmentStatusIcon,
  EquipmentStatusName,
  EquipmentStatusOptions,
} from '../../_constants/equipment-status';
import {
  DoorControlKinds,
  SesioNodeComponentKind,
  SesioNodeComponentKindControlName,
} from '../../_constants/sesio-node-component/sesio-node-component-kind';
import {
  SesioNodeComponentStatusColor,
  SesioNodeComponentStatusIcon,
} from '../../_constants/sesio-node-component/sesio-node-component-status';
import {
  SesioNodeStatusColor,
  SesioNodeStatusIcon,
  SesioNodeStatusOptions,
} from '../../_constants/sesio-node/sesio-node-status';
import { getColorValue } from '../../_helpers/style.helper';
import { AuthService } from '../../_services/auth.service';
import { DoorStateDataService } from '../../_services/door-state-data.service';
import { EquipmentElevatorService } from '../../_services/equipment/equipment.elevator.service';
import { FileService } from '../../_services/file.service';
import { GraphqlService } from '../../_services/graphql.service';
import { MouvementDataService } from '../../_services/mouvement-data.service';
import { ICabinCallCommandEvent, IDoorEvent, ILidarEvent } from '../../_services/mqtt/node-mqtt.interface';
import { NodeMqttService } from '../../_services/mqtt/node.mqtt.service';
import { SesioNodeComponentCabinCallGpioService } from '../../_services/sesio-node-component/sesio-node-component.cabin-call-gpio.service';
import { SesioNodeComponentService } from '../../_services/sesio-node-component/sesio-node-component.service';
import { ChartComponent } from '../chart/chart/chart.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { FailureDatagridComponent } from '../datagrids/failure-datagrid/failure-datagrid.component';
import { IMarker, MapComponent } from '../map/map.component/map.component';
import { NotificationService } from '../notification/notification.service';
import { IPictureGridTile } from '../picture-grid/picture-grid.component';
import { InitializationControlDialog } from './initialization-control/initialization-control.dialog';
import { NetworkControlDialog } from './network-control/network-control.dialog';
import { SesioNodeComponentControlDialog } from './sesio-node-component-control/sesio-node-component-control.dialog';
import { SignatureControlDialog } from './signature-control/signature-control.dialog';

interface IUpdateFloorData {
  x: number;
  y: number;
  distance: number;
}

interface IData {
  id: string;
}

@Component({
  selector: 'app-elevator-info',
  templateUrl: './elevator-info.dialog.html',
  styleUrls: ['./elevator-info.dialog.scss'],
  animations: [fadeOut, fadeIn],
})
export class ElevatorInfoDialog implements OnInit, OnDestroy {
  // TODO bottom toash for mqtt state

  EquipmentDoorStateName = EquipmentDoorStateName;
  EquipmentDoorStateNameLabel = EquipmentDoorStateNameLabel;
  EquipmentStatus = EquipmentStatus;
  EquipmentStatusName = EquipmentStatusName;
  EquipmentStatusIcon = EquipmentStatusIcon;
  EquipmentStatusColor = EquipmentStatusColorName;
  EquipmentStatusChartOptions = EquipmentStatusChartOptions;
  EquipmentSignatureStatus = EquipmentSignatureStatus;
  EquipmentSignatureStatusColor = EquipmentSignatureStatusColor;
  EquipmentSignatureStatusIcon = EquipmentSignatureStatusIcon;
  EquipmentSignatureStatusName = EquipmentSignatureStatusName;
  SesioNodeComponentKind = SesioNodeComponentKind;
  SesioNodeComponentKindControlName = SesioNodeComponentKindControlName;
  SesioNodeComponentStatusColor = SesioNodeComponentStatusColor;
  SesioNodeComponentStatusIcon = SesioNodeComponentStatusIcon;
  SesioNodeStatusColor = SesioNodeStatusColor;
  SesioNodeStatusIcon = SesioNodeStatusIcon;
  ConnectivityStatusColor = ConnectivityStatusColor;
  ConnectivityStatusIcon = ConnectivityStatusIcon;

  private logger = inject(NGXLogger);

  elevator: Elevator;
  metadata: ElevatorMetadata;
  floors: ElevatorFloor[];
  currentSpeed: number;
  direction: number;
  currentFloorIndex: number | undefined;
  cols: number;
  tiles: IPictureGridTile[];

  @ViewChild('statistics', { read: ElementRef })
  statisticsRef: ElementRef;

  @ViewChild('histoDoor', { read: ElementRef })
  histoDoorRef: ElementRef;

  @ViewChild('histoStatus', { read: ElementRef })
  histoStatusRef: ElementRef;
  statusEntities: { id: string; label: string; field: string }[];
  statusEntityCategories: { value: any; name: string; color: string }[] = uniqBy(
    concat(EquipmentStatusOptions as any[], EquipmentSignatureStatusOptions as any[], SesioNodeStatusOptions as any[]),
    'value',
  );

  @ViewChild('histoFailure', { read: ElementRef })
  histoFailureRef: ElementRef;

  @ViewChild('histoMouvement', { read: ElementRef })
  histoMouvementRef: ElementRef;

  floorPointFormatter = () => {
    const self = this;
    return function () {
      // @ts-ignore
      const point = this as any;
      const index = point.y;
      const distance = point.distance;
      let res = '';
      if (!isNil(index)) {
        if (index >= 0 && Number.isInteger(index)) res = get(self.floors[index], 'name', res);
        else {
          const floor1 = get(self.floors[Math.floor(index)], 'name', res);
          const floor2 = get(self.floors[Math.ceil(index)], 'name', res);
          if (distance) {
            const previousDistance = get(get(self.floorChartData, point.index - 1), 1, distance);
            res = previousDistance - distance > 0 ? `${floor1} - ${floor2}` : `${floor2} - ${floor1}`;
          } else res = `${floor1} - ${floor2}`;
        }
      }
      if (distance) {
        const distanceLabel = $localize`Distance: ${toInteger(distance)} cm`;
        return `${res} <span style="color: var(--app-color-medium);">( ${lowerCase(distanceLabel)} )</span>`;
      }
      return res;
    };
  };

  floorChartOptions: any = {
    chart: {
      zoomType: 'x',
    },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: false },
    yAxis: [
      {
        min: 0,
        type: 'category',
        showLastLabel: true,
        opposite: true,
        lineColor: '#3f5264',
        labels: { style: { color: '#3f5264' } },
        lineWidth: 1,
        resize: { enabled: true },
        tooltipValueFormat: '{value}',
        endOnTick: false,
        tickInterval: 2,
      },
    ],
    xAxis: {
      type: 'datetime',
    },
    series: [{ name: 'floor', type: 'line', color: '#3f5264' }],
    tooltip: {
      useHTML: true,
      shared: true,
      split: false,
      pointFormatter: this.floorPointFormatter(),
    },
    rangeSelector: {
      selected: 0,
      buttons: [
        {
          type: 'hour',
          count: 1,
          text: '1h',
          title: 'View 1 hour',
        },
      ],
      buttonTheme: {
        style: {
          display: 'none',
        },
      },
      labelStyle: {
        display: 'none',
      },
      inputEnabled: false,
    },
    scrollbar: {
      barBackgroundColor: '#3f5264',
      rifleColor: '#fff',
      barBorderRadius: 8,
      barBorderWidth: 0,
      buttonArrowColor: '#fff',
      buttonBackgroundColor: '#3f5264',
      buttonBorderWidth: 0,
      buttonBorderRadius: 8,
      trackBackgroundColor: '#e4edf1',
      trackBorderWidth: 1,
      trackBorderRadius: 8,
      trackBorderColor: '#CCC',
    },
    exporting: { enabled: false },
    plotOptions: {
      series: {
        turboThreshold: 10000,
        dataGrouping: {
          enabled: false,
        },
      },
      line: {
        grouping: false,
      },
    },
  };
  private floorChart: ChartComponent | undefined;

  speedAverageChartOptions: Highcharts.Options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: 'transparent',
      plotBorderWidth: 0,
      plotShadow: false,
      height: 330,
      width: 500,
    },
    credits: { enabled: false },
    title: { text: '' },
    exporting: { enabled: false },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [{ backgroundColor: '#fff', borderWidth: 0 }],
      center: ['50%', '100%'],
      size: 400,
    },
    tooltip: { valueSuffix: $localize` m/s`, valueDecimals: 2 },
    yAxis: [
      {
        min: 0,
        max: 2,
        tickPixelInterval: 100,
        tickPosition: 'inside',
        tickColor: '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        labels: {
          distance: 20,
          style: {
            fontSize: '16px',
            color: '#92949c',
          },
        },
        plotBands: [
          {
            from: 0,
            to: 1,
            color: '#DDDF0D', // yellow
            thickness: 20,
          },
          {
            from: 1,
            to: 1.6,
            color: '#55BF3B', // green
            thickness: 20,
          },
          {
            from: 1.6,
            to: 2.6,
            color: '#DF5353', // red
            thickness: 20,
          },
        ],
      },
    ],
    plotOptions: {
      gauge: {
        dataLabels: {
          format: $localize`{y:.2f} m/s`,
          borderWidth: 0,
          borderRadius: 0,
          color: '#92949c',
          style: {
            fontSize: '18px',
            textOutline: 'none',
          },
          y: 0,
          x: 70,
        },
        dial: {
          radius: '80%',
          backgroundColor: 'gray',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: 'gray',
          radius: 6,
        },
      },
    },
  };
  private speedAverageChart: ChartComponent | undefined;

  mouvementHistoryChartOptions: any = {
    chart: { zoomType: 'x' },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: true },
    tooltip: {
      valueDecimals: 2,
      shared: true,
      split: false,
    },
    yAxis: [
      {
        title: { text: $localize`Vitesse m/s` },
        opposite: true,
        showLastLabel: true,
        lineWidth: 1,
        resize: { enabled: true },
        min: 0,
        softMax: 3,
        tooltip: {
          valueSuffix: $localize` m/s`,
          valueDecimals: 2,
        },
        height: '66%',
      },
      {
        title: { text: $localize`Niveaux` },
        opposite: false,
        showLastLabel: true,
        lineWidth: 1,
        resize: { enabled: true },
        lineColor: '#3f5264',
        labels: { style: { color: '#3f5264' } },
        min: 0,
        tooltip: { valueDecimals: 2 },
        top: '66%',
        height: '34%',
      },
    ],
    xAxis: {
      type: 'datetime',
      ordinal: false,
    },
    rangeSelector: { enabled: false, inputEnabled: false },
    plotOptions: {
      series: { dataGrouping: { enabled: false } },
    },
  };
  private mouvementHistoryChart: ChartComponent | undefined;

  attendancePercentageChartOptions: Highcharts.Options = {
    chart: { type: 'pie', height: 358 },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: false },
    xAxis: {
      type: 'category',
    },
    yAxis: { title: { text: null } },
    tooltip: { pointFormat: '<b>{point.percentage:.2f} %</b> ({point.y})' },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.labelName}',
          distance: -50,
        },
      },
    },
  };
  private attendancePercentageChart: ChartComponent | undefined;

  attendanceChartOptions: Highcharts.Options = {
    chart: { type: 'column', height: 358 },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: false },
    yAxis: { title: { text: null } },
    tooltip: {
      pointFormat: '<b>{point.y}</b> ({point.percentage:.2f} %)',
    },
  };
  private attendanceChart: ChartComponent | undefined;

  doorHistoryChartOptions: Highstock.Options = {
    chart: { zoomType: 'x' } as any,
    title: { text: '' },
    credits: { enabled: false },
    legend: { enabled: true },
    tooltip: {
      valueDecimals: 2,
      shared: true,
      split: false,
      formatter: function () {
        const header = `<span style="font-weight:300;">${moment(this.x).format('LLL')}</span></br>`;
        const contents = map(this.points, (d) => {
          const serieName = `<span style="color:${d.series.color};">${d.series.name}</span> : `;
          let value: string;
          if (d.point.options.low && d.point.options.high && d.point.options.low != d.point.options.high) {
            value = `<b>${humanizeDuration(d.point.options.low! * 1000, { largest: 2, maxDecimalPoints: 0 })}</b> <> <b>${humanizeDuration(d.point.options.high! * 1000, { largest: 2, maxDecimalPoints: 0 })}</b>`;
          } else {
            value = `<b>${humanizeDuration(d.y! * 1000, { largest: 2, maxDecimalPoints: 0 })}</b>`;
          }
          return `${serieName}${value}`;
        });
        return `${header}${join(contents, '</br>')}`;
      },
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
    },
    yAxis: {
      title: { text: '' },
      opposite: false,
      labels: {
        formatter: function () {
          return humanizeDuration(parseFloat(this.value.toString()) * 1000, { largest: 1, maxDecimalPoints: 0 });
        },
      },
    },
    rangeSelector: { enabled: false, inputEnabled: false },
    plotOptions: {
      series: { dataGrouping: { enabled: false } },
      // candlestick: {  },
    },
  };
  private doorHistoryChart: ChartComponent | undefined;

  signatureStatusChangeDate: number;
  signatureSince: number;
  signatureSinceInterval: any;

  node: EmbeddedSesioNode | undefined;
  components: EmbeddedSesioNodeComponent[];
  hasDoorControl: boolean = false;
  view3d = false;

  ready = false;
  loading = false;

  private lastActivityTimeout: any;
  private lastActivityModalTimeout: any;
  private activityDialogRef: MatDialogRef<ConfirmDialogComponent, any> | null;
  @HostListener('document:mousemove', ['$event'])
  onMouseMove() {
    if (this.activityDialogRef) return;
    if (this.lastActivityTimeout) clearTimeout(this.lastActivityTimeout);
    this.lastActivityTimeout = setTimeout(
      () => {
        if (this.activityDialogRef) return;
        this.activityDialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: ['dialog-no-padding'],
          data: {
            title: $localize`Inactivitée`,
            message: $localize`Vous n'avez pas été actif sur la page depuis 5 minutes, souhaitez-vous continuez à observer l'ascenseur ?`,
            ok: $localize`Continuer`,
            cancel: $localize`Fermer`,
          },
          minWidth: '400px',
        });
        this.lastActivityModalTimeout = setTimeout(() => {
          this.activityDialogRef?.close();
          this.dialogRef.close();
        }, 30000);
        this.activityDialogRef.afterClosed().subscribe((data) => {
          this.activityDialogRef = null;
          clearTimeout(this.lastActivityModalTimeout);
          if (data === true) this.onMouseMove();
          else this.dialogRef.close();
        });
      },
      1000 * 60 * 5,
    );
  }

  private subsink = new SubSink();

  public authService = inject(AuthService);
  private dialog = inject(MatDialog);
  private snackbar = inject(MatSnackBar);
  private elevatorService = inject(EquipmentElevatorService);
  private fileService = inject(FileService);
  private nodeMqttService = inject(NodeMqttService);
  private sesioNodeComponentCabinCallGpioService = inject(SesioNodeComponentCabinCallGpioService);
  private notificationService = inject(NotificationService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private mouvementDataService = inject(MouvementDataService);
  private doorStateDataService = inject(DoorStateDataService);
  private sesioNodeComponentService = inject(SesioNodeComponentService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<ElevatorInfoDialog>,
  ) {}

  async ngOnInit(): Promise<any> {
    let iotStateSnackBar: MatSnackBarRef<TextOnlySnackBar> | null;
    this.subsink.add(
      SesioIotProvider.state.subscribe((state) => {
        switch (state) {
          case 'Connected':
            if (!iotStateSnackBar) return;
            iotStateSnackBar.dismiss();
            iotStateSnackBar = null;
            iotStateSnackBar = this.snackbar.open($localize`Tous les services sésiO sont actifs`, $localize`Fermer`, {
              panelClass: 'success-snack-bar',
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
            });
            break;
          case 'Disconnected':
          case 'ConnectionDisrupted':
            iotStateSnackBar = this.snackbar.open(
              $localize`Les services de consultation en temps réel sont momentanément interrompus`,
              undefined,
              {
                panelClass: 'danger-snack-bar',
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              },
            );
            break;
        }
      }),
    );

    await this.loadElevator();

    this.registerPropertyLive();

    this.signatureStatusChangeDate = get(this.metadata, 'signature.statusInfo.updatedAt')?.valueOf() || 0;
    this.signatureSinceInterval = setInterval(() => {
      this.signatureSince = Date.now() - this.signatureStatusChangeDate;
      this.changeDetectorRef.detectChanges();
    }, 1000);

    this.floors = get(this.elevator, 'metadata.floors') || [];
    this.attendanceChartOptions.xAxis = {
      categories: this.elevator.metadata?.floors?.map((floor) => floor.shortName),
    };
    this.currentSpeed = 0;
    this.currentFloorIndex = getFloorIndex(this.floors, this.metadata.lastMouvement?.endDistance);
    await this.loadComponents();
    this.loadDoorStateData();
    this.loadStatusEntities();
    this.elevatorLive();
    this.trackCurrentFloor();
    this.cols = Math.min(this.elevator.pictures.length, 5);
    this.tiles = lmap(this.elevator.pictures, () => ({ colspan: 1, rowspan: 1 }));
    this.ready = true;
  }

  ngOnDestroy(): void {
    if (this.controlDialogRef) this.controlDialogRef.close();
    if (this.lastActivityTimeout) clearTimeout(this.lastActivityTimeout);
    if (this.liveInterval) clearInterval(this.liveInterval);
    if (this.floorUpdateInterval) clearInterval(this.floorUpdateInterval);
    if (this.signatureSinceInterval) clearInterval(this.signatureSinceInterval);
    if (this.onVisibilityChange) document.removeEventListener('visibilitychange', this.onVisibilityChange, false);
    this.subsink.unsubscribe();
  }

  componentControls = [
    SesioNodeComponentKind.LIDAR,
    SesioNodeComponentKind.DOOR_GPIO,
    SesioNodeComponentKind.CABIN_CALL_GPIO,
    SesioNodeComponentKind.INSPECTION_MODE,
  ];
  controlDialogRef: MatDialogRef<any> | null;
  async showControlInfo(control: 'initialization' | 'signature' | 'network' | SesioNodeComponentKind) {
    if (this.controlDialogRef) return;
    this.loading = true;
    try {
      let component: any;
      let data: any;
      switch (control) {
        case 'initialization':
          component = InitializationControlDialog;
          data = {
            elevator: this.elevator,
            sesioNodeComponents: filter(this.components, { kind: SesioNodeComponentKind.LIDAR }),
          };
          break;
        case 'signature':
          component = SignatureControlDialog;
          data = { elevator: this.elevator };
          break;
        case 'network':
          component = NetworkControlDialog;
          data = { nodeId: this.node?._id };
          break;
        default:
          component = SesioNodeComponentControlDialog;
          data = { equipment: this.elevator, sesioNodeComponents: filter(this.components, { kind: control }) };
          break;
      }
      this.controlDialogRef = this.dialog.open(component, {
        height: '90vh',
        maxHeight: '90vh',
        width: '90vw',
        maxWidth: '90vw',
        panelClass: ['dialog-no-padding'],
        exitAnimationDuration: '300ms',
        data,
      });
      await lastValueFrom(this.controlDialogRef.beforeClosed());
    } finally {
      this.controlDialogRef = null;
      this.loading = false;
    }
  }

  isSendingTo = false;
  async sendTo(floor: ElevatorFloor) {
    if (this.isSendingTo) return;
    this.isSendingTo = true;
    if (floor.sesioNodeComponentId) {
      await this.sesioNodeComponentCabinCallGpioService.trigger(floor.sesioNodeComponentId, floor.cabinCall!);
    }
    this.isSendingTo = false;
  }

  private floorChartData: [number, number][];
  async loadFloorChartData(chartComponent: ChartComponent): Promise<void> {
    this.floorChart = chartComponent;
    const yAxis = await this.floorChart.getYAxis();
    if (!yAxis) return;
    yAxis.update({ max: this.floors.length, categories: lmap(this.floors, 'shortName') });
    this.floorChartData = await this.mouvementDataService.getFloorChartData(
      this.elevator._id,
      moment().subtract(48, 'hours'),
    );
    if (this.floorChartData.length) {
      const lastEntry = last(this.floorChartData)!;
      this.floorChartData.push([Date.now(), lastEntry[1]]);
      const data: IUpdateFloorData[] = [];
      each(this.floorChartData, (d) => {
        const y = getFloorIndex(this.floors, d[1]);
        if (isNil(y)) return;
        data.push({ x: d[0], y, distance: d[1] });
      });
      if (data.length) {
        this.previousData = last(data)!;
        data.push({ x: Date.now(), y: this.previousData.y, distance: this.previousData.distance });
      }
      this.floorChart.setSerieData(data);
    }
    this.floorChart.setLoading(false);
  }

  private extremes: Extremes;
  async setExtremes(extremes: Extremes): Promise<any> {
    this.extremes = extremes;
    return Promise.all([
      this.loadSpeedAverageChartData(),
      this.loadAttendancePercentageChartData(),
      this.loadAttendanceChartData(),
      this.loadMouvementHistoryChartData(),
      this.loadDoorHistoryChartData(),
    ]);
  }

  private async loadElevator(): Promise<any> {
    try {
      this.elevator = await this.elevatorService.get(this.data.id);
      if (!this.elevator) return (window.location.href = '/');
      this.metadata = get(this.elevator, 'metadata', {} as ElevatorMetadata);
      this.node = get(this.elevator, 'sesioNode');
    } catch (err) {
      this.logger.error(err);
      return (window.location.href = '/');
    }
  }

  doorState: EquipmentDoorState;
  lastDoorStates: ElevatorLastDoorState[] | undefined;
  private loadDoorStateData() {
    const lastDoorState = this.elevator.metadata?.lastDoorStates?.getLastDoorState() || null;
    this.doorState = lastDoorState?.state || EquipmentDoorState.UNKNOWN;
    const doorNames = uniq(
      concat(
        ...map(
          filter(this.components, (component) => includes(DoorControlKinds, component.kind)),
          'doors',
        ),
      ),
    );
    this.lastDoorStates = this.elevator.metadata?.lastDoorStates?.toArray(doorNames);
  }

  private speedAverage: number | null;
  async loadSpeedAverageChartData(chart?: ChartComponent) {
    if (chart) {
      this.speedAverageChart = chart;
      this.speedAverageChart.setSeries([
        {
          name: $localize`Vitesse moyenne`,
          tooltip: { valueSuffix: $localize` m/s` },
        } as any,
      ]);
    }
    if (this.speedAverageChart && this.extremes) {
      this.speedAverage = await this.mouvementDataService.getSpeedAverage({
        equipmentId: this.elevator._id,
        from: this.extremes.start,
        to: this.extremes.end,
      });
      this.speedAverageChart.setSerieData(isNil(this.speedAverage) ? [] : [this.speedAverage]);
      this.speedAverageChart.setLoading(false);
    }
  }

  async loadAttendancePercentageChartData(chart?: ChartComponent) {
    if (chart) {
      this.attendancePercentageChart = chart;
      this.attendancePercentageChart.setSeries([{ type: 'pie', name: $localize`Fréquentation` }]);
    }
    if (this.attendancePercentageChart && this.extremes) {
      this.attendancePercentageChart.setLoading(true);
      const data = await this.getFloorAttendanceData();
      const consolidateData = lmap(
        this.elevator.metadata?.floors,
        (floor, index) => find(data, { index }) || { index, y: 0 },
      );
      this.attendancePercentageChart.setSerieData(consolidateData);
      this.attendancePercentageChart.setLoading(false);
    }
  }

  async loadAttendanceChartData(chart?: ChartComponent) {
    if (chart) {
      this.attendanceChart = chart;
      this.attendanceChart.setSeries([{ name: $localize`Fréquentation` } as any]);
    }
    if (this.attendanceChart && this.extremes) {
      this.attendanceChart.setLoading(true);
      const data = await this.getFloorAttendanceData();
      const consolidateData = lmap(this.elevator.metadata?.floors, (floor, index) =>
        merge({ x: index }, find(data, { index }) || { index, y: 0 }),
      );
      this.attendanceChart.setSerieData(consolidateData);
      this.attendanceChart.setLoading(false);
    }
  }

  async loadMouvementHistoryChartData(chart?: ChartComponent) {
    if (chart) {
      this.mouvementHistoryChart = chart;
      this.mouvementHistoryChart.setSeries([
        {
          name: $localize`Min / Max des vitesses`,
          type: 'areasplinerange',
          showInNavigator: false,
          fillOpacity: 0.5,
          zones: [
            { value: 0.01, color: 'var(--app-color-medium-50)' },
            { value: 1, color: 'var(--app-color-warning-50)' },
            { value: 1.6, color: 'var(--app-color-success-50)' },
            { color: 'var(--app-color-danger-50)' },
          ],
        },
        {
          name: $localize`Moyenne des vitesses`,
          showInNavigator: true,
          zIndex: 10,
          lineWidth: 2,
          shadow: true,
          marker: {
            fillColor: 'white',
            lineWidth: 2,
            lineColor: 'var(--app-color-primary)',
          },
          zones: [
            { value: 0.01, color: 'var(--app-color-medium)' },
            { value: 1, color: 'var(--app-color-warning)' },
            { value: 1.6, color: 'var(--app-color-success)' },
            { color: 'var(--app-color-danger)' },
          ],
        },
        {
          yAxis: 1,
          name: $localize`Niveaux`,
          type: 'area',
          color: '#3f5264',
          fillOpacity: 0.1,
          showInNavigator: false,
          dataGrouping: { approximation: 'averages' },
          tooltip: { pointFormatter: this.floorPointFormatter() },
        },
        ,
      ] as any);
    }
    if (this.mouvementHistoryChart && this.extremes) {
      this.mouvementHistoryChart.setLoading(true);

      const data = await Promise.all([
        this.mouvementDataService.getSpeedChartData(this.elevator._id, this.extremes.start, this.extremes.end),
        this.mouvementDataService.getFloorChartData(this.elevator._id, this.extremes.start, this.extremes.end),
      ]);

      this.mouvementHistoryChart.setSerieData(
        lmap(data[0], (d) => [d.timestamp, d.min, Math.min(d.max, 3)]),
        0,
      );
      (await this.mouvementHistoryChart.getYAxis(0))?.update({
        plotLines: [
          {
            zIndex: 100,
            value: this.speedAverage!,
            color: 'var(--app-color-secondary)',
            width: 2,
            dashStyle: 'Dot',
            label: {
              text: $localize`${this.speedAverage?.toFixed(2)} m/s`,
              style: { color: 'var(--app-color-secondary)' },
              x: -40,
            },
          },
        ],
      });

      this.mouvementHistoryChart.setSerieData(
        lmap(data[0], (d) => [d.timestamp, Math.min(d.avg, 3)]),
        1,
      );
      (await this.mouvementHistoryChart.getYAxis(1))?.update({ max: this.floors.length - 1 });

      this.mouvementHistoryChart.setSerieData(
        lmap(data[1], (d) => [d[0], getFloorIndex(this.floors, d[1])]),
        2,
      );
      (await this.mouvementHistoryChart.getYAxis(2))?.update({ max: this.floors.length - 1 });

      this.mouvementHistoryChart.setExtremes(this.extremes, true);
      this.mouvementHistoryChart.redraw();
      this.mouvementHistoryChart.setLoading(false);
    }
  }

  async loadDoorHistoryChartData(chart?: ChartComponent) {
    if (chart) this.doorHistoryChart = chart;
    if (this.doorHistoryChart && this.extremes) {
      this.doorHistoryChart.setLoading(true);

      const data = await this.doorStateDataService.getMouvementChartData(
        this.elevator._id,
        this.extremes.start,
        this.extremes.end,
      );
      const series: Highstock.SeriesOptionsType[] = [];
      each(data, (d, i) => {
        series.push({
          type: 'candlestick',
          name: $localize`${EquipmentDoorNameName[d.doorName]}`,
          pointWidth: 4,
          color: 'var(--app-color-primary)',
          data: map(d.data, ({ timestamp, min, max, avg }) => [timestamp, avg, max, min, avg]),
        });
      });

      this.doorHistoryChart.setSeries(series);

      this.doorHistoryChart.setExtremes(this.extremes, true);
      this.doorHistoryChart.setLoading(false);
    }
  }

  async showMarker(map: MapComponent) {
    const icon = this.elevator.getIconFile()
      ? await this.fileService.getFileUrl(this.elevator.getIconFile()!)
      : this.elevator.getDefaultIconUrl();
    const markers: IMarker[] = [
      {
        type: 'icon',
        icon,
        position: this.elevator.coordinates,
        color: getColorValue(EquipmentStatusColorName[this.elevator.status]),
        properties: this.elevator,
      },
    ];

    let position;
    if (this.elevator.realEstateStructure?.location?.coordinates?.length === 2) {
      position = this.elevator.realEstateStructure.location.coordinates;
    }
    if (this.elevator.organizationalUnit.location?.coordinates?.length === 2) {
      position = this.elevator.organizationalUnit.location.coordinates;
    }
    if (position) markers.push({ type: 'default', color: 'var(--app-color-primary)', position });

    map.setMarkers(markers);
    if (!!markers.length) map.flyTo(head(markers)!.position, { maxZoom: 18 });
  }

  updatingEquipmentStatus = false;
  async updateEquipmentSignatureStatus() {
    if (this.updatingEquipmentStatus) return;
    this.updatingEquipmentStatus = true;
    this.loading = true;
    try {
      const res = await this.elevatorService.update(this.elevator._id, {
        s3Path: this.elevator.s3Path,
        metadata: { signature: { status: EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER } },
      });
      if (res) {
        this.signatureStatusChangeDate = Date.now();
        set(this.metadata, 'signature.status', EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER);
      }
    } finally {
      this.loading = false;
      this.updatingEquipmentStatus = false;
    }
  }

  async updateEquipmentStatus(status: EquipmentStatus) {
    if (this.updatingEquipmentStatus) return;
    this.updatingEquipmentStatus = true;
    this.loading = true;
    try {
      await this.elevatorService.update(this.elevator._id, { status, s3Path: this.elevator.s3Path });
    } finally {
      this.loading = false;
      this.updatingEquipmentStatus = false;
    }
  }

  @ViewChild(FailureDatagridComponent, { static: false })
  failureDatagridComponent: FailureDatagridComponent;
  histoStatusOver($event: FieldHistory | null) {
    if (this.failureDatagridComponent) {
      this.failureDatagridComponent.highlightRow($event?.metadata?.failure);
    }
  }

  private registerPropertyLive() {
    if (this.elevator.sesioNode) {
      this.subsink.add(
        GraphqlService.onPropertyUpsert(this.elevator.sesioNode._id, 'connectivity.status').subscribe((data) =>
          set(this.elevator, 'sesioNode.connectivity.status', data.value),
        ),
      );
    }
    this.onMouseMove();
  }

  private async loadComponents() {
    if (this.elevator.sesioNode) {
      this.components = orderBy(
        // this.elevator.sesioNodeComponents,
        await this.sesioNodeComponentService.list({ nodeId: this.elevator.sesioNode?._id }),
        [(component) => indexOf(this.componentControls, component.kind)],
        ['asc'],
      );
    } else this.components = [];
    this.hasDoorControl = !!find(this.components, (component) => includes(DoorControlKinds, component.kind));
  }

  private loadStatusEntities() {
    this.statusEntities = [
      { id: this.elevator._id, label: $localize`Statut de l'ascenseur`, field: 'status' },
      { id: this.elevator._id, label: $localize`Statut de la signature`, field: 'metadata.signature.status' },
    ];
    if (this.node) {
      this.statusEntities.push({ id: this.node._id, label: $localize`Statut du sésiO nOde`, field: 'status' });
    }
  }

  private floorAttendancePromise:
    | Promise<{ name: string; shortName: string; index: number; count: number }[]>
    | undefined;
  private getFloorAttendanceData(): Promise<
    { name: string; labelName: string; index: number; y: number; percentage: number; color: string }[]
  > {
    const promise = this.floorAttendancePromise
      ? this.floorAttendancePromise
      : (this.floorAttendancePromise = this.mouvementDataService.getFloorAttendance(
          this.elevator._id,
          this.extremes.start,
          this.extremes.end,
        ));
    return promise.then((data) => {
      const total = data.reduce((pv, cv) => (pv += cv.count), 0);
      this.floorAttendancePromise = undefined;
      return orderBy(
        lmap(orderBy(data, ['count'], ['asc']), (d, i) => ({
          name: d.name,
          labelName: d.shortName,
          index: d.index,
          y: d.count,
          percentage: (d.count / total) * 100,
          color: get(Highcharts.getOptions().colors, i % Highcharts.getOptions().colors!.length) as string,
        })),
        ['index'],
        ['asc'],
      );
    });
  }

  private trackCurrentFloorInterval = 2000;
  private previousData: IUpdateFloorData;
  private floorUpdateInterval: any;

  private trackCurrentFloor() {
    this.previousData = {
      x: this.metadata.lastMouvement?.end.valueOf() || Date.now(),
      y: this.currentFloorIndex || 0,
      distance: this.metadata.lastMouvement?.endDistance || 0,
    };

    this.floorUpdateInterval = setInterval(() => {
      if (
        this.previousData &&
        this.previousData &&
        Date.now() - this.previousData.x >= this.trackCurrentFloorInterval + 1000
      ) {
        if (!this.currentSpeed) this.updateFloor();
      }
    }, this.trackCurrentFloorInterval);

    document.addEventListener('visibilitychange', this.onVisibilityChange.bind(this), false);
  }

  private onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      if (!this.metadata.lastMouvement?.endDistance) return;
      if (Date.now() - this.previousData.x.valueOf() >= 15000) {
        this.updateFloor(undefined, false);
        this.currentSpeed = 0;
        this.updateFloor({
          x: Date.now(),
          y: (this.currentFloorIndex = getFloorIndex(this.floors, this.metadata.lastMouvement?.endDistance)!),
          distance: this.metadata.lastMouvement?.endDistance,
        });
      }
    }
  }

  private updateFloor(data?: IUpdateFloorData, redraw = true) {
    if (!this.floorChart || this.floorChart.loading) return;
    if (!data) data = merge({ x: Date.now() }, pick(this.previousData, 'y', 'distance'));
    if (!data) return;
    this.previousData = data;
    this.floorChartData.push([data.x.valueOf(), data.distance]);
    const point = { x: data.x.valueOf(), y: data.y, distance: data.distance };
    this.floorChart?.addPoint(point, 0, { redraw, shift: false });
  }

  private liveInterval: any;
  private async elevatorLive() {
    const components = filter(this.components, (component) =>
      includes(
        [SesioNodeComponentKind.LIDAR, SesioNodeComponentKind.DOOR_GPIO, SesioNodeComponentKind.CABIN_CALL_GPIO],
        component.kind,
      ),
    );
    each(components, (component) => {
      this.subsink.add(
        GraphqlService.onPropertyUpsert(component._id, 'status').subscribe((data) => (component.status = data.value)),
        this.nodeMqttService.getReadTopicSubscription(component).subscribe((data: any) => {
          switch (component.kind) {
            case SesioNodeComponentKind.LIDAR:
              this.processLidarEvent(data);
              break;
            case SesioNodeComponentKind.DOOR_GPIO:
              this.processDoorEvent(data);
              break;
            case SesioNodeComponentKind.CABIN_CALL_GPIO:
              this.processCabinCallEvent(data);
              break;
          }
          this.changeDetectorRef.detectChanges();
        }),
      );
      this.nodeMqttService.publishLiveAction(component);
    });

    this.liveInterval = setInterval(async () => {
      if (document.visibilityState !== 'visible') return;
      each(components, (component) => this.nodeMqttService.publishLiveAction(component));
    }, 50000);
  }

  private processLidarEvent(data: ILidarEvent) {
    if (data.speed > 3) return;
    const distance = get(data, 'distance');
    if (this.metadata.lastMouvement) this.direction = this.metadata.lastMouvement.endDistance - distance;
    this.currentSpeed = get(data, 'speed');
    this.currentFloorIndex = getFloorIndex(get(this.elevator, 'metadata.floors'), get(data, 'distance'));
    if (this.currentFloorIndex) {
      if (!this.currentSpeed && !!data.speed) this.updateFloor(undefined, false);
      this.updateFloor({ x: data.time, y: this.currentFloorIndex, distance });
    }
  }

  private processDoorEvent(data: IDoorEvent) {
    const group = get(data, 'group');
    if (!group) return;
    const state = getEquipmentDoorState(get(data, 'state'));
    if (!state) return;
    this.doorState = state;
    set(find(this.lastDoorStates, { doorName: group })!, 'state', state);
  }

  private processCabinCallEvent(data: ICabinCallCommandEvent) {
    if (data.status === 'success') {
      return this.notificationService.success(
        $localize`Appel cabine confirmée<br/>
          <i>Demande effectuée par: ${HumanizeFieldUpdateOrigin(data.origin)}</i>`,
        { duration: 5000 },
      );
    }
    return this.notificationService.error(
      $localize`Erreur d'appel cabine<br/>
        <span>${data.statusMessage}</span><br/>
        <i>Demande effectuée par: ${HumanizeFieldUpdateOrigin(data.origin)}</i>`,
      { duration: 5000 },
    );
  }
}
