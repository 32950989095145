/** @format */

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map } from 'rxjs/operators';
import { IDatatableOptions, IDatatableRecords } from './datatable.class';

export interface IRecord {
  _id: string;
}

export type Service<Record extends IRecord> = (options: IDatatableOptions) => Observable<IDatatableRecords<Record>>;

export class DatatableDataSource<Record extends IRecord> implements DataSource<Record> {
  public dataSubject = new BehaviorSubject<Record[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private options!: IDatatableOptions;
  private loadingTm: any;

  public loading$ = this.loadingSubject.asObservable();
  public recordsTotal = 0;
  public recordsFiltered = 0;
  public get data(): Record[] {
    return this.dataSubject.value;
  }

  constructor(private service: Service<Record>) {}

  connect(): Observable<Record[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  public loadData(options: IDatatableOptions): void {
    this.options = options;
    if (this.loadingTm) clearTimeout(this.loadingTm);
    this.loadingTm = setTimeout(() => {
      this.loadingTm = null;
      this.loadingSubject.next(true);
      const subscription = this.service(options)
        .pipe(
          catchError(() => of({} as IDatatableRecords<Record>)),
          filter((datasource: IDatatableRecords<Record>) => datasource.draw === this.options.draw),
          map((datasource: IDatatableRecords<Record>) => {
            this.recordsTotal = datasource.recordsTotal;
            this.recordsFiltered = datasource.recordsFiltered;
            this.dataSubject.next(datasource.data);
          }),
          finalize(() => {
            this.loadingSubject.next(false);
            subscription.unsubscribe();
          }),
        )
        .subscribe();
    }, 100);
  }

  public fetchData(options: IDatatableOptions): Promise<IDatatableRecords<Record>> {
    return lastValueFrom(this.service(options).pipe(catchError(() => of({} as IDatatableRecords<Record>))));
  }

  public refresh(): void {
    return this.loadData(this.options);
  }
}
