<div id="container" (obsResize)="resized($event)">
  <div class="map" [class.loading]="!isReady" id="map-{{id}}" intersectionObserver (intersection)="inview($event)">
  </div>
  <div id="not-supported" *ngIf="notSupported" fxLayout="row" fxLayoutAlign="center center">
    <span i18n>
      WebGL n'est pas supporté par votre navigateur ou est désactivé, la carte nécessite cette fonctionnalité pour
      s'afficher.
    </span>
  </div>
  <div id="actions" *ngIf="!notSupported" fxLayout="column" fxLayoutAlign="center center">
    <!-- <mat-fab-menu *ngIf="!options.disableLayers" id="layers" color="primary" direction="top" [fabButtons]="MapLayers"
      (onFabMenuItemSelected)="switchLayer($any($event))">
    </mat-fab-menu> -->
    <div id="user-actions" fxLayout="column" fxLayoutAlign="center center">
      <ng-content select="[mapActions]"></ng-content>
    </div>
  </div>
  <div id="loading" *ngIf="isLoading" @fade fxLayoutAlign="center center">
    <app-loader></app-loader>
  </div>
</div>
