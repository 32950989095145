/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IDatagridOptions, IRow } from '../datagrid.component';
import { IRecord } from '../datasource';

@Pipe({
  name: 'rowBackgroundColor',
  pure: true,
})
export class RowBackgroundColorPipe<Record extends IRecord> implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(record: Record, options: IDatagridOptions<Record>): string | undefined {
    if (options.rowBackgroundColor) return options.rowBackgroundColor(record);
    return;
  }
}
