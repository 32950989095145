/** @format */

import { PropertyMap } from 'model-mapper';

export class SpaceEntry {
  @PropertyMap()
  value: number;

  @PropertyMap()
  label: number;
}

export class Space {
  @PropertyMap()
  totalSpace: number;

  @PropertyMap()
  totalChildrenSpace: number;

  @PropertyMap({ type: [SpaceEntry] })
  totalSpaceEntries: SpaceEntry[];

  @PropertyMap()
  livingSpace: number;

  @PropertyMap()
  livingChildrenSpace: number;

  @PropertyMap({ type: [SpaceEntry] })
  livingSpaceEntries: SpaceEntry[];

  @PropertyMap()
  energyPerformanceSpace: number;

  @PropertyMap()
  energyPerformanceChildrenSpace: number;

  @PropertyMap({ type: [SpaceEntry] })
  energyPerformanceSpaceEntries: SpaceEntry[];
}
