/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://awwn4kv5d5anxfkkygo5poayy4.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-w7kic4vh3vc4vk2hqexkhd3xtq",
    "aws_cognito_identity_pool_id": "eu-west-1:19656717-50a1-48a8-9fad-8caac51b76df",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_zQfNEq9MT",
    "aws_user_pools_web_client_id": "22p7lr7vnc0ape5ri7ubo8ctnr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sesiodf72c19c9c644d01a01fb08f7664421e214042-edge",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
