/** @format */

import { PropertyMap } from 'model-mapper';
import { SesioNodeComponentKind } from '../../_constants/sesio-node-component/sesio-node-component-kind';
import { SesioNodeComponentStatus } from '../../_constants/sesio-node-component/sesio-node-component-status';
import { embeddedSerialize } from '../../_helpers/class.helper';
import { Archived } from '../archived.class';
import { EmbeddedCustomerAccount } from '../customer-account.embedded.class';
import { FieldUpdate } from '../field-update.class';
import { EmbeddedSesioNode } from '../sesio-node/sesio-node.embedded.class';

export class SesioNodeComponent {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedCustomerAccount, serialize: embeddedSerialize })
  customerAccount?: EmbeddedCustomerAccount;

  @PropertyMap()
  organization?: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  version: string;

  @PropertyMap()
  label: string;

  @PropertyMap()
  serialNumber?: string;

  @PropertyMap()
  status: SesioNodeComponentStatus;

  @PropertyMap({ type: FieldUpdate })
  statusInfo: FieldUpdate<SesioNodeComponentStatus>;

  @PropertyMap({ type: Date })
  lastStatusReportDate: Date;

  @PropertyMap()
  kind: SesioNodeComponentKind;

  @PropertyMap({ type: EmbeddedSesioNode, serialize: embeddedSerialize })
  node: EmbeddedSesioNode;

  @PropertyMap()
  commandTopicAction: string;

  @PropertyMap()
  zwaveNodeId: number;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
