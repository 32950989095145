/** @format */

import { Component, Input } from '@angular/core';
import { EntityDocument } from '../../_classes/entity-document.class';
import { FileService } from '../../_services/file.service';

@Component({
  selector: 'app-entity-document-viewer',
  templateUrl: './entity-document-viewer.component.html',
  styleUrls: ['./entity-document-viewer.component.scss'],
})
export class EntityDocumentViewerComponent {
  @Input()
  document: EntityDocument;

  constructor(private fileService: FileService) {}

  async openDocument(document: EntityDocument) {
    window.open(await this.fileService.getFileUrl(document.file), '_blank');
  }
}
