/** @format */

import { map, values } from 'lodash-es';

export enum SensorIndicator {
  INSUFFICIENT = 'insufficient',
  BAD = 'bad',
  FAIR = 'fair',
  GOOD = 'good',
  UNKNOWN = 'unknown',
}

export const SensorIndicatorColorName: { [key: string]: string } = {
  [SensorIndicator.INSUFFICIENT]: 'out-of-order',
  [SensorIndicator.BAD]: 'critical',
  [SensorIndicator.FAIR]: 'fair',
  [SensorIndicator.GOOD]: 'ok',
  [SensorIndicator.UNKNOWN]: 'medium',
};
export const SensorIndicatorColor: { [key: string]: string } = {
  [SensorIndicator.INSUFFICIENT]: `var(--app-color-${SensorIndicatorColorName[SensorIndicator.INSUFFICIENT]})`,
  [SensorIndicator.BAD]: `var(--app-color-${SensorIndicatorColorName[SensorIndicator.BAD]})`,
  [SensorIndicator.FAIR]: `var(--app-color-${SensorIndicatorColorName[SensorIndicator.FAIR]})`,
  [SensorIndicator.GOOD]: `var(--app-color-${SensorIndicatorColorName[SensorIndicator.GOOD]})`,
  [SensorIndicator.UNKNOWN]: `unknown(--app-color-${SensorIndicatorColorName[SensorIndicator.BAD]})`,
};

export const SensorIndicatorIcon: { [key: string]: string } = {
  [SensorIndicator.INSUFFICIENT]: 'signal_cellular_1_bar',
  [SensorIndicator.BAD]: 'signal_cellular_2_bar',
  [SensorIndicator.FAIR]: 'signal_cellular_3_bar',
  [SensorIndicator.GOOD]: 'signal_cellular_4_bar',
  [SensorIndicator.UNKNOWN]: 'signal_cellular_connected_no_internet_0_bar',
};

export const SensorIndicatorName: { [key: string]: string } = {
  [SensorIndicator.INSUFFICIENT]: $localize`Insuffisant`,
  [SensorIndicator.BAD]: $localize`Mauvais`,
  [SensorIndicator.FAIR]: $localize`Passable`,
  [SensorIndicator.GOOD]: $localize`Bon`,
  [SensorIndicator.UNKNOWN]: $localize`Inconnu`,
};

export const SensorIndicatorOptions: { value: any; name: string }[] = map(values(SensorIndicator), (value) => ({
  value,
  name: SensorIndicatorName[value],
}));

export const SensorIndicatorDatagridOptions = map(values(SensorIndicator), (value) => ({
  value: value,
  name: `<span nowrap>${SensorIndicatorName[value]}</span>`,
  icon: { name: SensorIndicatorIcon[value], color: SensorIndicatorColor[value] },
}));

export function getSensorIndicator(indicator: number): SensorIndicator {
  return indicator <= 2
    ? SensorIndicator.GOOD
    : indicator < 4
      ? SensorIndicator.FAIR
      : indicator < 6
        ? SensorIndicator.BAD
        : SensorIndicator.INSUFFICIENT;
}
