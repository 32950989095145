/** @format */

import { map, values } from 'lodash-es';

export enum SesioNodeComponentKind {
  LIDAR = 'lidar',
  INSPECTION_MODE = 'inspection-mode',
  DOOR_GPIO = 'door-gpio',
  CABIN_CALL_GPIO = 'cabin-call-gpio',
  CONTACT_GPIO = 'contact-gpio',

  STATUS = 'status',
  LED = 'led',

  CAMERA = 'camera',
  TREND = 'trend',
}

export const DoubtRemovalKinds = [SesioNodeComponentKind.CABIN_CALL_GPIO];
export const DistanceControlKinds = [SesioNodeComponentKind.LIDAR];
export const DoorControlKinds = [SesioNodeComponentKind.DOOR_GPIO];

export const hasCommandTopic = [...DistanceControlKinds, ...DoorControlKinds, ...DoubtRemovalKinds];
export const hasLiveTopic = [...DistanceControlKinds, ...DoorControlKinds];
export const hasDebugTopic = [...DistanceControlKinds, ...DoorControlKinds];

export const SesioNodeComponentKindName: { [kind: string]: string } = {
  [SesioNodeComponentKind.LIDAR]: $localize`LiDar`,
  [SesioNodeComponentKind.INSPECTION_MODE]: $localize`Bouton d'inspection`,
  [SesioNodeComponentKind.DOOR_GPIO]: 'Supervision des portes filaire',
  [SesioNodeComponentKind.CABIN_CALL_GPIO]: 'Appel cabine filaire',
  [SesioNodeComponentKind.CONTACT_GPIO]: 'Contact',

  [SesioNodeComponentKind.STATUS]: $localize`Statut`,
  [SesioNodeComponentKind.LED]: $localize`Led`,

  [SesioNodeComponentKind.CAMERA]: $localize`Stream vidéo`,
  [SesioNodeComponentKind.TREND]: $localize`Automate Trend`,
};

export const SesioNodeComponentKindControlName: { [kind: string]: string } = {
  [SesioNodeComponentKind.LIDAR]: $localize`LiDar`,
  [SesioNodeComponentKind.INSPECTION_MODE]: $localize`Inspection`,
  [SesioNodeComponentKind.DOOR_GPIO]: 'Supervision des portes',
  [SesioNodeComponentKind.CABIN_CALL_GPIO]: 'Appel cabine',

  [SesioNodeComponentKind.STATUS]: $localize`Statut`,
  [SesioNodeComponentKind.LED]: $localize`Led`,

  [SesioNodeComponentKind.CAMERA]: $localize`Caméra vidéo`,
  [SesioNodeComponentKind.TREND]: $localize`Automate Trend`,
};

export const SesioNodeComponentKindOptions: { value: any; name: string }[] = map(
  values(SesioNodeComponentKind),
  (value) => ({
    value,
    name: SesioNodeComponentKindName[value],
  }),
);
