/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { AppLogger } from '../../_services/logger.service';

@Pipe({ name: 'toString' })
export class ToStringPipe implements PipeTransform {
  private logger = new AppLogger('ToStringPipe');

  transform(data: any, log: boolean = true): any {
    if (log) this.logger.debug(data);
    return data ? JSON.stringify(data) : null;
  }
}
