<mat-drawer-container id="container" autosize class="mat-elevation-z2">
  <mat-drawer #drawer class="example-sidenav" mode="over" position="end">
    <div fxLayout="column" fxLayoutGap="8px">
      <div *ngFor="let info of drawerInfos" fxLayout="row">
        <button mat-button (click)="navigateTo(info)">
          <div fxLayoutAlign="start center" fxLayoutGap="8px">
            <span [style.color]="info.color">{{info.reference}}</span>
          </div>
        </button>
      </div>
    </div>
  </mat-drawer>
  <div fxLayout="column">
    <div id="indicators" class="mat-elevation-z2" fxLayoutAlign="start center" fxLayoutGap="32px" [style.width.%]="100">
      <div fxLayoutAlign="start center" fxLayoutGap="4px" [style.color]="'var(--app-color-primary)'">
        <span *ngIf="families?.length === 1" [translateParams]="{ count : countByStatus | pluck:'count' | sum }"
          translate>{{families | head}}</span>
        <span *ngIf="families?.length !== 1" [translateParams]="{ count : countByStatus | pluck:'count' | sum }"
          translate>equipments</span>
        <app-count-up [value]="countByStatus | pluck:'count' | sum"></app-count-up>
      </div>
      <div *ngFor="let option of countByStatus" fxLayoutAlign="start center" fxLayoutGap="4px"
        [style.color]="option.color">
        <mat-icon>{{option.icon}}</mat-icon>
        <span translate>status-{{option.status}}</span>
        <app-count-up [value]="option.count"></app-count-up>
      </div>
    </div>
    <app-map class="mat-elevation-z2" [options]="mapOptions" (ready)="loadMap($event)" [class.zoomed]="mapZoomed">
      <button mat-icon-button mapActions (click)="toggleMapZoom()">
        <mat-icon *ngIf="mapZoomed">zoom_in_map</mat-icon>
        <mat-icon *ngIf="!mapZoomed">zoom_out_map</mat-icon>
      </button>
    </app-map>
  </div>
</mat-drawer-container>
