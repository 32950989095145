/** @format */

import { filter, map } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import { SensorBrand, SensorModel } from '../../_constants/sensor/sensor-brand';
import { SensorKind } from '../../_constants/sensor/sensor-kind';
import { SensorMetric } from '../../_constants/sensor/sensor-metric';
import { ISensorSettings } from '../../_constants/sensor/sensor-settings';
import { SensorState } from '../../_constants/sensor/sensor-state';
import { SensorStatus } from '../../_constants/sensor/sensor-status';
import { embeddedSerialize } from '../../_helpers/class.helper';
import { Archived } from '../archived.class';
import { EmbeddedCustomerAccount } from '../customer-account.embedded.class';
import { EmbeddedEquipment } from '../equipment/equipment.embedded.class';
import { FieldUpdate } from '../field-update.class';
import { EmbeddedOrganizationalUnit } from '../organizational-unit.embedded.class';
import { EmbeddedRealEstateStructure } from '../real-estate-structure.embedded.class';

export class SensorMetricDefinition {
  @PropertyMap()
  metric: SensorMetric;

  @PropertyMap()
  historized: boolean;
}

export class SensorMetricValue {
  @PropertyMap()
  metric: SensorMetric;

  @PropertyMap()
  value: number;

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap()
  payloadId: string;
}

export class SensorSettings {
  @PropertyMap()
  status: 'queued' | 'sent' | 'partially-applied' | 'applied' | 'error';

  @PropertyMap()
  messageId: string;

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap()
  requested: ISensorSettings;

  @PropertyMap()
  applied: ISensorSettings;

  @PropertyMap()
  retry: number;
}

export class SensorReportedStatus {
  @PropertyMap()
  hardwareError: boolean;

  @PropertyMap()
  lowBattery: boolean;
}

export class Sensor {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  kind: SensorKind;

  @PropertyMap({ type: EmbeddedCustomerAccount, serialize: embeddedSerialize })
  customerAccount?: EmbeddedCustomerAccount;

  @PropertyMap()
  organization?: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  label: string;

  @PropertyMap()
  description: string;

  @PropertyMap()
  awsId: string; // WirelessDeviceId

  @PropertyMap()
  state: SensorState;

  @PropertyMap({ type: FieldUpdate })
  stateInfo: FieldUpdate<SensorState>;

  @PropertyMap()
  status: SensorStatus;

  @PropertyMap({ type: FieldUpdate })
  statusInfo: FieldUpdate<SensorStatus>;

  @PropertyMap()
  indicatorValue: number;

  @PropertyMap({ type: Date })
  lastMessageDate: Date;

  @PropertyMap()
  brand: SensorBrand;

  @PropertyMap()
  model: SensorModel;

  @PropertyMap({ type: EmbeddedOrganizationalUnit, serialize: embeddedSerialize })
  organizationalUnit: EmbeddedOrganizationalUnit;

  @PropertyMap({ type: EmbeddedRealEstateStructure, serialize: embeddedSerialize })
  realEstateStructure?: EmbeddedRealEstateStructure;

  @PropertyMap({ type: EmbeddedEquipment, serialize: embeddedSerialize })
  equipment?: EmbeddedEquipment;

  @PropertyMap()
  coordinates: [number, number, number];

  @PropertyMap()
  position: [number, number];

  @PropertyMap({ type: [SensorMetricDefinition] })
  metrics: SensorMetricDefinition[];

  @PropertyMap({ type: [SensorMetricValue] })
  values: SensorMetricValue[];

  @PropertyMap({ type: SensorSettings })
  settings: SensorSettings;

  @PropertyMap({ type: SensorReportedStatus })
  reportedStatus: SensorReportedStatus;

  @PropertyMap({ type: Archived })
  __archived: Archived;

  getHistorizedMetrics(): SensorMetric[] {
    return map(filter(this.metrics, { historized: true }), 'metric');
  }
}
