import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-rate',
  templateUrl: './star-rate.component.html',
  styleUrls: ['./star-rate.component.scss'],
})
export class StarRateComponent implements OnInit {
  @Input()
  shrink = false;

  @Input()
  max: number = 5;

  @Input('value')
  public set setValue(value: number | string) {
    if (typeof value === 'string') value = parseFloat(value);
    if (typeof value === 'number') {
      this.value = value;
      if (this.step) {
        const stepValue = 1 / this.step;
        this.percent = (Math.round(this.value * stepValue) / stepValue / this.max) * 100;
      } else this.percent = (this.value / this.max) * 100;
    } else {
      this.percent = 0;
      this.value = 0;
    }
  }
  public value = 0;
  public percent = 0;

  @Input()
  public step: number | null = null;

  constructor() {}

  ngOnInit() {}
}
