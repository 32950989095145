/** @format */

import { map, values } from 'lodash-es';

export enum SesioNodeComponentStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  UNKNOWN = 'unknown',
  ND = 'nd',
}

export const SesioNodeComponentStatusColorName: { [key: string]: string } = {
  [SesioNodeComponentStatus.ONLINE]: 'success',
  [SesioNodeComponentStatus.OFFLINE]: 'out-of-order',
  [SesioNodeComponentStatus.UNKNOWN]: 'medium',
  [SesioNodeComponentStatus.ND]: 'nd',
};

export const SesioNodeComponentStatusColor: { [key: string]: string } = {
  [SesioNodeComponentStatus.ONLINE]: `var(--app-color-${SesioNodeComponentStatusColorName[SesioNodeComponentStatus.ONLINE]})`,
  [SesioNodeComponentStatus.OFFLINE]: `var(--app-color-${SesioNodeComponentStatusColorName[SesioNodeComponentStatus.OFFLINE]})`,
  [SesioNodeComponentStatus.UNKNOWN]: `var(--app-color-${SesioNodeComponentStatusColorName[SesioNodeComponentStatus.UNKNOWN]})`,
  [SesioNodeComponentStatus.ND]: `var(--app-color-${SesioNodeComponentStatusColorName[SesioNodeComponentStatus.ND]})`,
};

export const SesioNodeComponentStatusIcon: { [key: string]: string } = {
  [SesioNodeComponentStatus.ONLINE]: 'check_circle',
  [SesioNodeComponentStatus.OFFLINE]: 'error',
  [SesioNodeComponentStatus.UNKNOWN]: 'info',
  [SesioNodeComponentStatus.ND]: 'help_outline',
};

export const SesioNodeComponentStatusName: { [key: string]: string } = {
  [SesioNodeComponentStatus.ONLINE]: $localize`En fonctionnement`,
  [SesioNodeComponentStatus.OFFLINE]: $localize`En panne`,
  [SesioNodeComponentStatus.UNKNOWN]: $localize`Statut inconnu`,
  [SesioNodeComponentStatus.ND]: $localize`Non défini`,
};

export const SesioNodeComponentStatusOptions = map(values(SesioNodeComponentStatus), (value) => ({
  value,
  name: SesioNodeComponentStatusName[value],
  prefixIcon: SesioNodeComponentStatusIcon[value],
  color: SesioNodeComponentStatusColor[value],
}));

export const SesioNodeComponentStatusDatagridOptions = map(values(SesioNodeComponentStatus), (value) => ({
  value,
  name: SesioNodeComponentStatusName[value],
  icon: { name: SesioNodeComponentStatusIcon[value], color: SesioNodeComponentStatusColor[value] },
}));
