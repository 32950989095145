<mat-tab-group mat-align-tabs="start" fill sticky-header mat-stretch-tabs="false" mat-align-tabs="start">

  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>table_chart</mat-icon>
        <span i18n>Données</span>
      </div>
    </ng-template>
    <div class="tab-content">
      <ng-container *ngTemplateOutlet="datagridTabTmpl"></ng-container>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>manage_history</mat-icon>
        <span i18n>Historique</span>
      </div>
    </ng-template>
    <div class="tab-content">
      <ng-container *ngTemplateOutlet="historicTabTmpl"></ng-container>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon [class.spin]="play">sync</mat-icon>
        <span i18n>Live</span>
      </div>
    </ng-template>
    <div class="tab-content">
      <ng-container *ngTemplateOutlet="liveTabTmpl"></ng-container>
    </div>
  </mat-tab>

</mat-tab-group>

<div id="actions" fxLayout="row">
  <button *ngIf="play" color="primary" mat-icon-button (click)="play = false" i18n-matTooltip
    matTooltip="Mettre en pause le live">
    <mat-icon>pause_circle</mat-icon>
  </button>
  <button *ngIf="!play" color="primary" mat-icon-button (click)="play = true" i18n-matTooltip
    matTooltip="Relancer le live">
    <mat-icon>play_circle</mat-icon>
  </button>
  <button color="primary" mat-icon-button (click)="clearLive()" i18n-matTooltip
    matTooltip="Supprimer l'historique du live">
    <mat-icon>delete_history</mat-icon>
  </button>
  <button color="medium" mat-icon-button matDialogClose>
    <mat-icon>close</mat-icon>
  </button>
</div>


<ng-template #liveTabTmpl>
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let component of data.sesioNodeComponents; index as index" [style.min-width.%]="100"
      [expanded]="index === 0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container *ngTemplateOutlet="datagridPanelTitleTmpl; context: { component: component }"></ng-container>
        </mat-panel-title>
        <mat-panel-description [style.flex-grow]="1">
          <span nowrap i18n [style.margin-left]="'auto'">Messages: {{componentMessages[component._id].length}}</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-accordion multi>
        <mat-expansion-panel *ngFor="let message of componentMessages[component._id]">
          <mat-expansion-panel-header>
            <mat-panel-title>{{message.date | date:'mediumTime'}}</mat-panel-title>
            <mat-panel-description>
              <dif fxLayout="row" fxLayoutGap="16px">
                <ng-container
                  *ngTemplateOutlet="liveMessageInfoTmpl; context: { component: component, message: message }"></ng-container>
              </dif>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <pre>{{message.data | json}}</pre>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #datagridTabTmpl>
  <ng-container [ngSwitch]="kind">

    <div *ngSwitchCase="SesioNodeComponentKind.CABIN_CALL_GPIO" class="datagrid-content" fxLayout="column"
      fxLayoutGap="8px">
      <app-cabin-call-datagrid class="datagrid" [equipment]="data.equipment"></app-cabin-call-datagrid>
    </div>

    <div *ngSwitchCase="SesioNodeComponentKind.DOOR_GPIO" class="datagrid-content" fxLayout="column" fxLayoutGap="8px">
      <app-door-state-datagrid class="datagrid" [equipment]="data.equipment"></app-door-state-datagrid>
    </div>

    <div *ngSwitchCase="SesioNodeComponentKind.LIDAR" class="datagrid-content" fxLayout="column" fxLayoutGap="8px">
      <div #info class="info" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="8px">
        <div *ngFor="let info of floorInfo" [innerHTML]="info | safe:'html'">
        </div>
      </div>
      <app-mouvement-data-datagrid class="datagrid lidar" [equipment]="data.equipment"></app-mouvement-data-datagrid>
    </div>
  </ng-container>
</ng-template>

<ng-template #historicTabTmpl>
  <app-chart-range-selector selectedZoom="today">
    <div content fxLayout="column" fxLayoutGap="16px">

      <div *ngFor="let component of data.sesioNodeComponents; index as index" fxLayout="column"
        fxLayoutAlign="start start" [style.min-width.%]="100">
        <ng-container *ngTemplateOutlet="datagridPanelTitleTmpl; context: { component: component }"></ng-container>
        <app-field-history-chart #rangeElement [categories]="SesioNodeComponentStatusOptions" [entityId]="component._id"
          field="status" [style.width.%]="100"></app-field-history-chart>
      </div>
    </div>
  </app-chart-range-selector>
</ng-template>

<ng-template #datagridPanelTitleTmpl let-component="component">
  <div fxLayoutAlign="start center" fxLayoutGap="8px" fxFlex="grow">
    <mat-icon class="icon-title"
      [style.color]="SesioNodeComponentStatusColor[component.status]">{{SesioNodeComponentStatusIcon[component.status]}}</mat-icon>
    <span class="info-title" color="secondary" nowrap>{{component.label}}</span>
    <span class="title" color="primary">{{component.reference}}</span>
    <span class="subtitle" color="medium">{{component.node.reference}}</span>
  </div>
</ng-template>

<ng-template #liveMessageInfoTmpl let-component="component" let-message="message">
  <ng-container [ngSwitch]="component.kind">
    <ng-container *ngSwitchCase="SesioNodeComponentKind.LIDAR">
      <ng-container *ngTemplateOutlet="liveMessageInfoLidar; context: { message: message }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="SesioNodeComponentKind.DOOR_GPIO">
      <ng-container *ngTemplateOutlet="liveMessageInfoDoorMonitoring; context: { message: message }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #liveMessageInfoLidar let-message="message">
  <div class="message-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container *ngIf="message.topic==='debug'">
      <span [style.width.px]="80">{{message.data.distance | number:'0.0-0'}} cm</span>
      <span [style.width.px]="80">{{message.data.speed | number:'0.2-2'}} m/s</span>
      <span [style.width.px]="80">({{message.data.signalStrength}})</span>
      <span class="floor-info" [innerHTML]="data.equipment | elevatorFloorFromDistance:message.data.distance"></span>
    </ng-container>
    <ng-container *ngIf="message.topic==='data'">
      <span [style.width.px]="80">{{message.data.start | toDate | date:'mediumTime'}}</span>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>{{message.data.startDistance | number:'0.0-0'}} cm </span>
        <span>(</span>
        <span class="floor-info"
          [innerHTML]="data.equipment | elevatorFloorFromDistance:message.data.startDistance"></span>
        <span>)</span>
      </div>
      <span [style.width.px]="80" [style.text-align]="'center'"> => </span>
      <span [style.width.px]="80">{{message.data.end | toDate | date:'mediumTime'}}</span>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <span>{{message.data.endDistance | number:'0.0-0'}} cm </span>
        <span>(</span>
        <span class="floor-info"
          [innerHTML]="data.equipment | elevatorFloorFromDistance:message.data.endDistance"></span>
        <span>)</span>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #liveMessageInfoDoorMonitoring let-message="message">
  <div class="message-info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container *ngIf="message.topic==='debug'">
    </ng-container>
    <ng-container *ngIf="message.topic==='data'">
    </ng-container>
  </div>
</ng-template>
