/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentFamily } from '../../_constants/equipment-family';
import { EquipmentStatus } from '../../_constants/equipment-status';
import { Embedded, EmbeddedClass } from '../../_decorators/embedded-class.decorator';
import { embeddedMap } from '../../_helpers/class.helper';
import { EmbeddedEquipmentKind } from '../equipment-kind.embedded.class';
import { ExternalReferences } from '../external-references.class';

@Embedded({ sortFields: 'family kind.name reference' })
export class EmbeddedEquipment extends EmbeddedClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  family: EquipmentFamily;

  @PropertyMap({ map: embeddedMap })
  organizationalUnitId: string;

  @PropertyMap({ map: embeddedMap })
  realEstateStructureId: string;

  @PropertyMap({ type: EmbeddedEquipmentKind })
  kind: EmbeddedEquipmentKind;

  @PropertyMap()
  reference: string;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap()
  disableSerenite: boolean;

  @PropertyMap()
  name?: string;

  @PropertyMap()
  status: EquipmentStatus;
}
