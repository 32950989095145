/** @format */

import { Component, Input, inject } from '@angular/core';
import { map } from 'lodash-es';
import { SearchKey } from '../../_constants/search';
import { buildSearchString } from '../../_helpers/string.helper';
import { OrganizationalUnitService } from '../../_services/organizational-unit.service';
import { ColumnDef, ColumnOption } from '../datagrid/column-def.class';
import { ICell, IRow } from '../datagrid/datagrid.component';

type Value = {
  kind: {
    name: string;
    color: {
      hex: string;
    };
  };
  reference: string;
  name: string;
};

@Component({
  selector: 'app-organizational-unit-cell',
  templateUrl: './organizational-unit-cell.component.html',
  styleUrls: ['./organizational-unit-cell.component.scss'],
})
export class OrganizationalUnitCellComponent {
  static optionTmpl(value: Value) {
    return `<span style="white-space: nowrap;">
      <span class="only-in-options" light style="display: block; font-size: 0.8em; line-height: 0.8em; color: ${value.kind.color.hex};">${value.kind.name}</span>
      <span>${value.reference} ${value.name}</span>
    </span>`;
  }

  static getDatagridColumn(prefix?: string): ColumnOption {
    const service = inject(OrganizationalUnitService);
    let path = 'organizationalUnit';
    if (prefix) path = `${prefix}.${path}`;
    return {
      property: path,
      linkedProperties: [`${path}._id`, `${path}.reference`, `${path}.name`, `${path}.kind`],
      searchProperty: `${path}._id`,
      label: $localize`Unité organisationnelle`,
      searchable: true,
      sortable: true,
      type: 'autocomplete',
      optionsAsync: (limit, skip, search) => {
        const filter = { search: [{ key: SearchKey.SEARCH, values: [buildSearchString(search)] }] };
        return service.search(limit, skip, filter, 'kind reference name', 'kind.name name').then((res) => {
          return {
            matchCount: res.matchCount,
            data: map(res.data, (d) => ({ name: this.optionTmpl(d), value: d._id })),
          };
        });
      },
      maxWidth: 250,
      cellComponent: OrganizationalUnitCellComponent,
    };
  }

  value?: Value;

  @Input()
  column: ColumnDef;

  @Input()
  record: any;

  @Input()
  row: IRow;

  @Input('cell')
  public set setCell(cell: ICell) {
    this.load(cell?.rawValue);
  }

  private async load(value: any) {
    if (typeof value === 'object') this.value = value;
  }
}
