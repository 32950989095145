import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get } from 'lodash-es';

@Pipe({ name: 'controlValue' })
export class FormValuePipe implements PipeTransform {
  transform(formGroup: FormGroup, field: string, embedded?:string): any {
    const value = formGroup?.get(field)?.value;
    return embedded ? get(value, embedded) : value;
  }
}
