<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" [style.font-size.px]="24">
  <mat-icon *ngIf="failure?.isIgnored" large color="primary">
    notifications_paused
  </mat-icon>
  <span color="primary" i18n>
    Panne {{EquipmentFamilyName[equipment.family] | lowercase}} {{equipment.name}} {{equipment.reference}}
  </span>
  <span *ngIf="failure?.isIgnored" light [style.font-size.px]="14" i18n>
    cette panne n'est pas comptabilisée dans vos statistiques de pannes et n'impacte donc pas votre taux de
    disponibilité
  </span>
  <button id="close-btn" mat-icon-button size="small" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutGap="16px">
  <ng-container *ngIf="failure; else loadingTmpl">

    <div fxLayout="row" fxLayoutGap="32px">
      <div fxLayout="column">
        <span color="primary" i18n>Date</span>
        <span *ngIf="failure.end" i18n>
          <span light>du</span> {{failure.start | date:'medium'}}
          <span light>au</span> {{failure.end | date:'medium'}}
        </span>
        <span *ngIf="!failure.end" color="danger" i18n>
          <span light>depuis le</span> {{failure.start | date:'medium'}}
        </span>
        <span nowrap light>{{failure.start | duration:failure.end | humanizeDuration}}</span>
      </div>
      <div fxLayout="column">
        <span color="primary" i18n>Position de la cabine</span>
        <span [innerHTML]="position | safe:'html'"></span>
      </div>
      <div fxLayout="column">
        <span color="primary" i18n>État de la porte</span>
        <span [innerHTML]="door| safe:'html'"></span>
      </div>
      <div fxLayout="column">
        <span color="primary" i18n>Levées de doute</span>
        <span [innerHTML]="doubtRemovals| safe:'html'"></span>
      </div>
      <div fxLayout="column">
        <span color="primary" i18n>Ouverture de la panne</span>
        <span>{{openFailureOrigin}}</span>
      </div>
      <div fxLayout="column">
        <span color="primary" i18n>Fermeture de la panne</span>
        <span>{{closeFailureOrigin}}</span>
      </div>
    </div>

    <app-chart kind="gantt" [options]="chartOptions" (ready)="loadChart($event)">
    </app-chart>

    <ng-container *ngTemplateOutlet="infoSereniteTmpl"></ng-container>


  </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="failure?.end" align="end">
  <button *ngIf="failure.isIgnored" [disabled]="toggleIsIgnoredLoading" mat-raised-button color="primary" confirm
    i18n-confirmTitle confirmTitle="Êtes-vous sûr de vouloir prendre en compte cette panne ?" i18n-confirmMessage
    confirmMessage="Si vous prenez en compte cette panne, elle impactera vos statistiques de pannes et votre taux de disponibilité."
    (confirmed)="toggleIsIgnored(false)">
    <span i18n>Prendre en compte cette panne</span>
  </button>
  <button *ngIf="!failure.isIgnored" [disabled]="toggleIsIgnoredLoading" mat-raised-button color="primary" confirm
    i18n-confirmTitle confirmTitle="Êtes-vous sûr de vouloir ignorer cette panne ?" i18n-confirmMessage
    confirmMessage="Si vous ignorez cette panne, elle sera décomptée de vos statistiques de pannes et du taux de disponibilité."
    (confirmed)="toggleIsIgnored(true)">
    <span i18n>Ignorer cette panne</span>
  </button>
</mat-dialog-actions>

<ng-template #infoSereniteTmpl>
  <div *ngIf="sereniteError" fxLayout="column" fxLayoutGap="16px">
    <span class="title" i18n>Information sérénité 24h24</span>
    <span color="danger" i18n>Erreur d'accès à l'api sérénité 24h24</span>
  </div>
  <div *ngIf="sereniteTicket" fxLayout="column" fxLayoutGap="16px">
    <span class="title" i18n>Information sérénité 24h24</span>
    <table mat-table [dataSource]="sereniteTicket.historique" class="mat-elevation-z8">

      <ng-container matColumnDef="datetime">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n>Date</span>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.datetime | date:'medium'}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n>Type</span>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="texte">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n>Message</span>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.texte}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="sereniteHistoryColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: sereniteHistoryColumns;"></tr>
    </table>
  </div>
</ng-template>

<ng-template #loadingTmpl>
  <app-loader id="loader" @fadeOut [diameter]="60"></app-loader>
</ng-template>
