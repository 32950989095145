<mat-tab-group mat-align-tabs="start" fill sticky-header mat-stretch-tabs="false" mat-align-tabs="start">

  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon class="spin">sync</mat-icon>
        <span *ngIf="data.elevator.status === EquipmentStatus.INITIALIZING" i18n>
          Initialisation des niveaux
        </span>
        <span *ngIf="data.elevator.status !== EquipmentStatus.INITIALIZING" i18n>
          Consolidation des niveaux
        </span>
      </div>
    </ng-template>
    <div class="tab-content">
      <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </div>
  </mat-tab>

</mat-tab-group>
<button color="medium" mat-icon-button matDialogClose id="close-btn">
  <mat-icon>close</mat-icon>
</button>

<ng-template #contentTmpl>

  <div *ngIf="initialization" id="initialization" fxLayout="column" fxFlex="grow">
    <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
      <span i18n>Déclenchée par :
        <span color="secondary">{{initialization.triggeredBy.email}}</span>
      </span>
      <span i18n>Niveaux relevés :
        <span color="secondary">{{stops.length}}</span>
        /
        <span color="secondary">{{data.elevator.metadata?.floors?.length || 0}}</span>
      </span>
      <span i18n>
        LiDAR
        <span *ngIf="distance !== null" color="secondary">{{distance / 100 | round:2}} m</span>
        <ng-container *ngIf="speed !== null">
          ( <span color="secondary">{{speed | round:3}} m/s</span> )
        </ng-container>
      </span>
      <span i18n>
        Déclenchée le
        <span color="secondary">{{initialization.startDate | date:'short'}}</span>
      </span>
    </div>
    <div id="stops" fxLayout="column" fxLayoutGap="8px">
      <ng-container *ngFor="let stop of stops">
        <div class="stop" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <app-count-up class="stop-distance" color="secondary" [value]="stop.avgDistance / 100" i18n-suffix suffix=" m"
            [decimalPlaces]="2"></app-count-up>
          <mat-form-field no-subscript>
            <mat-label i18n>Distance minimale</mat-label>
            <input matInput type="number" [(ngModel)]="stop.minDistance">
            <span matTextSuffix i18m> cm</span>
          </mat-form-field>
          <mat-form-field no-subscript>
            <mat-label i18n>Distance maximale</mat-label>
            <input matInput type="number" [(ngModel)]="stop.maxDistance">
            <span matTextSuffix i18m> cm</span>
          </mat-form-field>
          <mat-form-field no-subscript>
            <mat-label i18n>Niveau détecté</mat-label>
            <mat-select [(value)]="stop.floorId">
              <mat-option><span i18n>Aucun</span></mat-option>
              <mat-option *ngFor="let floor of data.elevator.metadata?.floors" [value]="floor._id"
                [disabled]="stop.floorId !== floor._id && !!(stops | filterByImpure:['floorId']:[floor._id]).length">
                <span [innerHTML]="floor.name | safe:'html'"></span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="stop-occurence" fxLayout="row" fxLayoutGap="4px">
            <app-count-up [value]="stop.occurrence"></app-count-up>
            <span i18n>arrêt{stop.occurrence, plural, =0{} one{} other{s}}</span>
          </div>
          <button mat-icon-button color="danger" [disabled]="saving" confirm (confirmed)="deleteStop(stop._id)"
            i18n-confirmMessage confirmMessage="Êtes-vous sûr de vouloir supprimer l'arrêt ?">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>
    <mat-toolbar *ngIf="data.elevator.metadata?.floorInitialization?.active" class="footer" fxLayoutAlign="end center">
      <button mat-raised-button color="primary" [disabled]="saving" confirm (confirmed)="saveInitialization()"
        i18n-confirmMessage confirmMessage="Êtes-vous sûr de vouloir enregistrer les arrêt ?">
        <span i18n>Enregistrer</span>
      </button>
    </mat-toolbar>
  </div>

</ng-template>
