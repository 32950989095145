/** @format */

import { Component, inject, Input, OnInit } from '@angular/core';
import { get } from 'lodash-es';
import { Elevator, ElevatorFloor } from '../../../_classes/equipment/equipment.elevator.class';
import { EquipmentDoorState } from '../../../_constants/equipment-door/equipment-door-state';
import { AppLogger } from '../../../_services/logger.service';
import { SesioNodeComponentCabinCallGpioService } from '../../../_services/sesio-node-component/sesio-node-component.cabin-call-gpio.service';

@Component({
  selector: 'app-elevator-live-3d',
  templateUrl: './elevator-live3d.component.html',
  styleUrls: ['./elevator-live3d.component.scss'],
})
export class ElevatorLive3dComponent implements OnInit {
  @Input()
  elevator: Elevator;

  @Input()
  currentSpeed: number;

  @Input()
  currentFloorIndex: number | undefined;

  @Input()
  doorState: EquipmentDoorState;

  @Input()
  direction: number;

  floors: ElevatorFloor[];

  private logger = new AppLogger('ElevatorLive3dComponent');
  private sesioNodeComponentCabinCallGpioService = inject(SesioNodeComponentCabinCallGpioService);

  ngOnInit(): void {
    this.load();
  }

  isSendingTo = false;
  async sendTo(floor: ElevatorFloor) {
    if (!floor.sesioNodeComponentId || this.isSendingTo) return;
    this.isSendingTo = true;
    this.logger.debug('sendTo', floor);
    if (floor.sesioNodeComponentId) {
      await this.sesioNodeComponentCabinCallGpioService.trigger(floor.sesioNodeComponentId, floor.cabinCall!);
    }
    this.isSendingTo = false;
  }

  private async load() {
    this.floors = get(this.elevator, 'metadata.floors') || [];
  }
}
