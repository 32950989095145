/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { differenceWith, get } from 'lodash-es';

export interface DifferencePipeOptions {
  arrayPluck?: string;
  ignoredPluck?: string;
  by?: (v: any) => any;
  with?: (v1: any, v2: any) => boolean;
}

@Pipe({ name: 'difference' })
export class DifferencePipe implements PipeTransform {
  transform(array: any[], ignored: any[], options?: DifferencePipeOptions): any[] {
    const data = differenceWith(array, ignored, (o1: any, o2: any) => {
      let v1 = options?.arrayPluck ? get(o1, options.arrayPluck) : o1;
      let v2 = options?.ignoredPluck ? get(o2, options.ignoredPluck) : o2;
      if (options?.by) {
        v1 = options.by(v1);
        v2 = options.by(v2);
      }
      return options?.with ? options.with(v1, v2) : v1 === v2;
    });
    return data;
  }
}
