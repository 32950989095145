/** @format */

import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCurrentScope } from '@sentry/angular-ivy';
import { Auth, Hub } from 'aws-amplify';
import { deburr, filter, find, get, head, orderBy, pick, set, toLower, trim } from 'lodash-es';
import { Subject, Subscription, take } from 'rxjs';
import { User } from '../_classes/user.class';
import { SessionData } from '../app.session.data';
import { GraphqlService } from './graphql.service';
import { AppLogger, MethodLogger } from './logger.service';
import { NavigationService } from './navigation.service';
import { OrganizationService } from './organization.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public $user: Subject<User | null | undefined> = SessionData.$user;
  public user: User | null | undefined = SessionData.user;

  private logger = new AppLogger('AuthService');
  private navigationService = inject(NavigationService);

  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private organizationService: OrganizationService,
    private dialogRef: MatDialog,
  ) {
    this.$user.subscribe((user) => {
      if (this.user === user) return;
      this.user = SessionData.user = user;
      this.logger.debug('user', this.user);
      getCurrentScope().setUser(this.user ? pick(this.user, '_id', 'email') : null);
      this.subscribeCache();
    });
    Auth.currentAuthenticatedUser().then(async (data: any) => {
      this.logger.debug('currentAuthenticatedUser', data);
      this.$user.next(await this.userService.me());
      await this.sessionService.load();
      await this.loadOrganizations();
    });
    Hub.listen('auth', async ({ payload: { event, data, message } }) => {
      if (event === 'signIn') {
        this.userService
          .me()
          .then((user) => (this.user = SessionData.user = user))
          .then(async (user) => (await this.sessionService.load(), user))
          .then((user) => this.$user.next(user))
          .then(() => this.loadOrganization());
      } else if (event === 'signOut') {
        await this.navigationService.navigate('/');
      }
    });
  }

  public async getUser(): Promise<User | null | undefined> {
    if (this.user) return this.user;
    return new Promise((resolve) => {
      this.$user.pipe(take(1)).subscribe((user) => resolve(user));
    });
  }

  @MethodLogger()
  public async signOut(): Promise<void> {
    try {
      this.dialogRef.closeAll();
      this.$user.next(null);
      await this.sessionService.clearSession();
      await Auth.signOut();
    } catch (err: any) {
      this.logger.error(err);
    }
  }

  private async loadOrganizations(): Promise<any> {
    this.sessionService.setOrganizations(
      orderBy(
        await this.organizationService.list(),
        [(organization) => deburr(toLower(trim(organization.name)))],
        ['asc'],
      ),
    );
    if (!this.sessionService.$organization.value) await this.setSelectedOrganization();
  }

  private async loadOrganization(): Promise<any> {
    await this.loadOrganizations();
    const value = this.sessionService.$organization.value;
    if (value) return this.navigate(['/', head(value.modules)]);
    await this.setSelectedOrganization();
  }

  private async setSelectedOrganization(): Promise<any> {
    const organizationId = get(head(get(this.user, 'organizations')), 'organization._id');
    if (organizationId) {
      const organization = find(this.sessionService.$organizations.value, { _id: organizationId });
      this.sessionService.setOrganization(organization);
      if (organization) return this.navigate(['/', head(organization.modules)]);
    }
    this.sessionService.setOrganization(null);
    await this.navigate(['/']);
  }

  @MethodLogger()
  private async navigate(commands: (string | undefined)[]) {
    if (!this.navigationService.isOrganizationScoped) return;
    await this.navigationService.navigate(filter(commands, (command) => !!command));
  }

  private subscribeCache(): void {
    this.subscribeUserCache();
    this.subscribeUserUnreadNotifications();
  }

  private userCacheSubscription: Subscription;
  private subscribeUserCache(): void {
    if (this.userCacheSubscription) this.userCacheSubscription.unsubscribe();
    if (!this.user) return;
    this.userCacheSubscription = GraphqlService.onCachedUpdated(this.user._id).subscribe(async (data) =>
      this.$user.next(await this.userService.me()),
    );
  }

  private userUnreadNotificationsSubscription: Subscription;
  private subscribeUserUnreadNotifications(): void {
    if (this.userUnreadNotificationsSubscription) this.userUnreadNotificationsSubscription.unsubscribe();
    if (!this.user) return;
    this.userUnreadNotificationsSubscription = GraphqlService.onPropertyUpsert<number>(
      this.user._id,
      'unreadNotifications',
    ).subscribe((data) => set(this.user!, 'unreadNotifications', data.value));
  }
}
