/** @format */

export enum SearchKey {
  SEARCH = 'search',
  TEXT = 'text',
  ORGANIZATIONAL_UNIT = 'organizational-unit',
  REAL_ESTATE_STRUCTURE = 'real-estate-structure',
  EQUIPMENT_CATEGORY = 'equipment-category',
  EQUIPMENT_KIND = 'equipment-kind',
  EQUIPMENT_ASSIGNATION = 'equipment-assignation',
  EQUIPMENT_STATUS = 'equipment-status',
}
