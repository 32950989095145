/** @format */

import { PropertyMap } from 'model-mapper';
import { ProductionKind } from '../_constants/production-kind';
import { EquipmentConsumptionKind } from './equipment-consumption-kind.class';

export class EquipmentProductionKind {
  @PropertyMap()
  kind: ProductionKind;

  @PropertyMap({ type: [EquipmentConsumptionKind] })
  sources: EquipmentConsumptionKind[];
}
