/** @format */

import { concat, map, merge, values } from 'lodash-es';
import { GpioDirection } from './gpio-direction';

export enum GpioReadKind {
  DOOR = 'door',
  CROSSING_COUNTER = 'crossing-counter',
  PEOPLE_COUNTER = 'people-counter',
  CONTACT_INPUT = 'contact-input',
}

export enum GpioWriteKind {
  CABIN_CALL = 'cabin-call',
  PRIORITY_CALL = 'priority-call',
  NONE_STOP = 'none-stop',
  INHIBITION = 'inhibition',
  CONTACT_OUTPUT = 'contact-output',
}

export const GpioKind = {
  ...GpioReadKind,
  ...GpioWriteKind,
};
// export type GpioKind = typeof GpioKind;
export type GpioKind = GpioReadKind | GpioWriteKind;

export const GpioReadKindName: { [kind: string]: string } = {
  [GpioReadKind.DOOR]: $localize`Porte de service`,
  [GpioReadKind.CROSSING_COUNTER]: $localize`Compteur de passage`,
  [GpioReadKind.PEOPLE_COUNTER]: $localize`Compteur de personne`,
  [GpioReadKind.CONTACT_INPUT]: $localize`Contact entrant`,
};

export const GpioWriteKindName: { [kind: string]: string } = {
  [GpioWriteKind.CABIN_CALL]: $localize`Appel cabine`,
  [GpioWriteKind.PRIORITY_CALL]: $localize`Rappel prioritaire`,
  [GpioWriteKind.NONE_STOP]: $localize`Non stop`,
  [GpioWriteKind.INHIBITION]: $localize`Inhibition ascenseur`,
  [GpioWriteKind.CONTACT_OUTPUT]: $localize`Contact sortant`,
};

export const GpioKindName: { [kind: string]: string } = {
  ...GpioReadKindName,
  ...GpioWriteKindName,
};

export const GpioWriteKindOptions = map(values(GpioWriteKind), (value) => ({
  value,
  name: GpioWriteKindName[value],
}));

export const GpioReadKindOptions = map(values(GpioReadKind), (value) => ({
  value,
  name: GpioReadKindName[value],
}));

export const GpioKindOptions: {
  value: GpioKind;
  name: string;
  directon: GpioDirection;
}[] = concat(
  map(GpioReadKindOptions, (option) => merge(option, { direction: GpioDirection.READ })) as any,
  map(GpioWriteKindOptions, (option) => merge(option, { direction: GpioDirection.WRITE })) as any,
);
