/** @format */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { get, head, keys, map, merge, reduce } from 'lodash-es';
import { SubSink } from 'subsink';
import { OrganizationalUnit } from '../../../_classes/organizational-unit.class';
import { RealEstateStructureFamily } from '../../../_constants/real-estate-structure-family';
import {
  RealEstateStructureStatus,
  RealEstateStructureStatusColor,
  RealEstateStructureStatusDatagridOptions,
} from '../../../_constants/real-estate-structure-status';
import { getColorValue } from '../../../_helpers/style.helper';
import { NavigationService } from '../../../_services/navigation.service';
import { IMapInfo, RealEstateStructureService } from '../../../_services/real-estate-structure.service';
import { SessionService } from '../../../_services/session.service';
import { IMarker, MapComponent } from '../map.component/map.component';

interface IDrawerInfo extends IMapInfo {
  color: string;
}
@Component({
  selector: 'app-real-estate-structure-status-map',
  templateUrl: './real-estate-structure-status-map.component.html',
  styleUrls: ['./real-estate-structure-status-map.component.scss'],
})
export class RealEstateStructureStatusMapComponent implements OnInit, OnDestroy {
  @Input('organizationalUnit')
  set setOrganizationalUnit(organizationalUnit: OrganizationalUnit | string | null | undefined) {
    this.organizationalUnit = organizationalUnit;
    this.loadData();
  }
  private organizationalUnit?: OrganizationalUnit | string | null;

  @Input()
  families: RealEstateStructureFamily[];

  @Input()
  buildCommands: (info: IMapInfo) => Promise<any[]>;

  @Input()
  buildQueryParams: (info: IMapInfo) => Promise<any>;

  mapOptions = {
    maxZoom: 20,
    cluster: true,
    enableNavControl: false,
    clusterProperties: {
      ...reduce(
        keys(RealEstateStructureStatusColor),
        (pv, key) => {
          pv[key] = {
            color: RealEstateStructureStatusColor[key],
            merge: ['+', ['case', ['==', ['get', 'status'], key], 1, 0]],
          };
          return pv;
        },
        {} as any,
      ),
    },
    popup: (markers: IMarker[]) => {
      this.drawerInfos = map(markers, (m: any) => merge({ color: m.color }, m.properties));
      this.drawerRef.open();
    },
    click: (markers: IMarker[]) => this.navigateTo(get(head(markers), 'properties', {}) as IMapInfo),
  };

  @ViewChild('drawer', { read: MatDrawer })
  drawerRef: MatDrawer;

  drawerInfos: IDrawerInfo[];

  countByStatus: { status: RealEstateStructureStatus; icon: string; color: string; count: number }[];

  mapZoomed = false;
  private mapRef: MapComponent;
  private subsink = new SubSink();

  constructor(
    private sessionService: SessionService,
    private service: RealEstateStructureService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subsink.add(this.sessionService.$organization.subscribe(() => this.loadData()));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  async loadMap(mapRef: MapComponent) {
    this.mapRef = mapRef;
    this.loadMapData();
  }

  toggleMapZoom() {
    this.mapZoomed = !this.mapZoomed;
    setTimeout(() => this.mapRef.resize());
  }

  private loadDataTm: any;
  public async loadData() {
    if (this.loadDataTm) clearTimeout(this.loadDataTm);
    this.loadDataTm = setTimeout(() => {
      this.loadDataTm = null;
      this.loadStatusData();
      this.loadMapData();
    }, 300);
  }

  async navigateTo(info: IMapInfo) {
    let commands: any[];
    if (typeof this.buildCommands === 'function') commands = await this.buildCommands(info);
    else commands = [info._id];
    let queryParams: any;
    if (typeof this.buildQueryParams === 'function') queryParams = await this.buildQueryParams(info);
    else queryParams = {};
    this.navigationService.navigate(commands, { queryParams, relativeTo: this.route });
  }

  private async loadStatusData() {
    this.service.countByStatus({ organizationalUnit: this.organizationalUnit, families: this.families }).then(
      (data) =>
        (this.countByStatus = map(RealEstateStructureStatusDatagridOptions, (option) => ({
          status: option.value,
          icon: option.icon.name,
          color: option.icon.color,
          count: get(
            data.find((d) => d.status === option.value),
            'count',
            0,
          ),
        }))),
    );
  }

  private async loadMapData() {
    if (!this.mapRef) return;
    const data = await this.service.getMapInfo({
      families: this.families,
      organizationalUnit: this.organizationalUnit,
    });
    const markers: IMarker[] = [];
    const coordinates: [number, number][] = [];
    for (let d of data) {
      if (d.coordinates?.length !== 2) continue;
      markers.push({
        type: 'text',
        text: '1',
        position: d.coordinates,
        color: getColorValue(RealEstateStructureStatusColor[d.status]),
        properties: d,
      });
      coordinates.push(d.coordinates);
    }
    this.mapRef.setMarkers(markers);
    this.mapRef.fitBounds(coordinates, { padding: 64 });
  }
}
