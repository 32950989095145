/** @format */

import { PropertyMap } from 'model-mapper';
import { embeddedSerialize } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { Color } from './color.class';
import { ExternalReferences } from './external-references.class';
import { Location } from './location.class';
import { EmbeddedOrganizationalUnitKind } from './organizational-unit-kind.embedded.class';
import { Space } from './space.class';

export class OrganizationalUnit {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap({ type: EmbeddedOrganizationalUnitKind, serialize: embeddedSerialize })
  kind: EmbeddedOrganizationalUnitKind;

  @PropertyMap()
  reference: string;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap({ type: Color })
  color: Color;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  perimeter: any;

  @PropertyMap({ type: Space })
  space: Space;

  @PropertyMap()
  parent: string;

  @PropertyMap()
  path: string;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
