/** @format */

import { PropertyMap } from 'model-mapper';
import { EmissionKind } from '../_constants/emission-kind';
import { EquipmentMode } from '../_constants/equipment-mode';
import { embeddedSerialize } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { EmbeddedEquipmentCategory } from './equipment-category.embedded.class';
import { EquipmentConsumptionKind } from './equipment-consumption-kind.class';
import { EquipmentProductionKind } from './equipment-production-kind.class';
import { ExternalReferences } from './external-references.class';

export class EquipmentKind {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap({ type: EmbeddedEquipmentCategory, serialize: embeddedSerialize })
  category: EmbeddedEquipmentCategory;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: [EquipmentConsumptionKind] })
  consumptionKinds: EquipmentConsumptionKind[];

  @PropertyMap({ type: [EquipmentProductionKind] })
  productionKinds: EquipmentProductionKind[];

  @PropertyMap()
  emissionKinds: EmissionKind[];

  @PropertyMap()
  modes: EquipmentMode[];

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
