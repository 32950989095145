/** @format */

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  appName: 'Sesio Edge',
  production: false,
  region: 'eu-west-1',
  env: 'edge',
  mapboxAccessToken: 'pk.eyJ1IjoiZWhlcnZlIiwiYSI6ImNsOHlrZGFlcDA5NmUzdnBrOHR3bHdxY3gifQ.1tU0KXJak2Dh1yqA6sOpZQ',
};
