/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentFamily } from '../_constants/equipment-family';
import { EquipmentStatus } from '../_constants/equipment-status';
import { s3PathPropertyMap } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { ExternalReferences } from './external-references.class';
import { S3File } from './s3-file.class';

export class EquipmentCategoryIcon {
  @PropertyMap({ type: S3File })
  [EquipmentStatus.OK]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.OUT_OF_ORDER]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.STOPPED]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.MAINTENANCE]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.REPARATION]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.NOFLOW]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.INITIALIZING]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.UNKNOWN]: S3File;

  @PropertyMap({ type: S3File })
  [EquipmentStatus.ND]: S3File;
}

export class EquipmentCategory {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap()
  family: EquipmentFamily;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap()
  name: string;

  @PropertyMap(s3PathPropertyMap)
  s3Path: string;

  @PropertyMap({ type: EquipmentCategoryIcon })
  icons: EquipmentCategoryIcon;

  @PropertyMap()
  isConsumptionEquipment: boolean;

  @PropertyMap()
  isProductionEquipment: boolean;

  @PropertyMap()
  isEmissionEquipment: boolean;

  @PropertyMap()
  hasModes: boolean;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
