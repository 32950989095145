/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { S3File } from '../../_classes/s3-file.class';
import { FileService } from '../../_services/file.service';

declare const THREE: any;

@Pipe({
  name: 'aframeFileUrl',
})
export class AframeFileUrlPipe implements PipeTransform {
  constructor(private fileService: FileService) {}

  async transform(file: File | S3File): Promise<string> {
    if (file instanceof File) return this.readFile(file);
    const data = await this.fileService.getFileContent(file);
    return this.readFile(new File([data], file.filename, { type: file.type }));
  }

  private async readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = `buffer/${file.name}`;
        THREE.Cache.add(previewUrl, reader.result);
        resolve(previewUrl);
      };
      reader.readAsDataURL(file);
    });
  }
}
