<mat-expansion-panel #element class="document">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="document-name">{{document.name}}</span>
    </mat-panel-title>
    <mat-panel-description fxLayoutAlign="end center" fxFlex="1">
      <button mat-icon-button color="primary" (click)="$event.stopPropagation(); openDocument(document)">
        <mat-icon>file_download</mat-icon>
      </button>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <ng-container [ngSwitch]="document.file.type">
      <div *ngSwitchCase="'application/pdf'" class="document-content document-pdf">
        <ngx-doc-viewer [url]=" $any(document.file.key | storageFileUrl | async)" viewer="pdf"
          style="width:100%;height:100%;"></ngx-doc-viewer>
      </div>
      <app-xlsx-viewer *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
        class="document-content document-xlsx" [file]="document.file"></app-xlsx-viewer>
      <app-docx-viewer *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
        class="document-content document-docx" [file]="document.file"></app-docx-viewer>
      <ng-container *ngSwitchCase="'image/png'">
        <ng-container *ngTemplateOutlet="imgTmpl; context: { document: document }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'image/jpeg'">
        <ng-container *ngTemplateOutlet="imgTmpl; context: { document: document }"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'image/jpg'">
        <ng-container *ngTemplateOutlet="imgTmpl; context: { document: document }"></ng-container>
      </ng-container>
      <span *ngSwitchDefault translate>entity-document-no-preview</span>
    </ng-container>
  </ng-template>
</mat-expansion-panel>

<ng-template #imgTmpl let-document="document">
  <app-panzoom class="document-content document-img">
    <img [src]="document.file.key | storageFileUrl | async">
  </app-panzoom>
</ng-template>
