<div id="content" fxLayout="column">
  <div id="content-body" fxLayout="row">
    <app-map class="mat-elevation-z2" [class.zoomed]="mapZoomed" [options]="mapOptions" (ready)="loadMap($event)">
      <button mat-icon-button mapActions (click)="reset()" *ngIf="bounds" i18n-tooltip tooltip="Réinitialiser la vue">
        <mat-icon>move</mat-icon>
      </button>
      <button mat-icon-button mapActions (click)="toggleMapZoom()">
        <mat-icon *ngIf="mapZoomed">zoom_in_map</mat-icon>
        <mat-icon *ngIf="!mapZoomed">zoom_out_map</mat-icon>
      </button>
    </app-map>
    <app-datagrid class="mat-elevation-z2" [class.zoomed]="mapZoomed" [options]="datagridOptions">
      <ng-template appDatagridCell="location-address" let-cell="cell">
        <app-location-address-cell [cell]="cell"></app-location-address-cell>
      </ng-template>
      <ng-template appDatagridCell="organizational-unit" let-cell="cell">
        <app-organizational-unit-cell [cell]="cell"></app-organizational-unit-cell>
      </ng-template>
      <ng-template appDatagridCell="real-estate-structure" let-cell="cell">
        <app-real-estate-structure-cell [cell]="cell"></app-real-estate-structure-cell>
      </ng-template>
    </app-datagrid>
  </div>
</div>
