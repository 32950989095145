/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'momentDiff' })
export class MomentDiffPipe implements PipeTransform {
  transform(data: any | null | undefined, withData?: any, unitOfTime?: moment.unitOfTime.Diff, precise?: boolean): number | null {
    const date: moment.Moment | null = data ? moment(data) : null;
    const withDate: moment.Moment | null = withData ? moment(withData) : moment();
    return date?.isValid() ? date.diff(withDate, unitOfTime, precise) : null;
  }
}
