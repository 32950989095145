/** @format */

import { animate, style, transition, trigger } from '@angular/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatListItem } from '@angular/material/list';
import { MAT_SELECT_CONFIG, MatSelect } from '@angular/material/select';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import { get, head, split } from 'lodash-es';
import moment from 'moment';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { SubSink } from 'subsink';
import { Organization } from './_classes/organization.class';
import { UserNotificationsDialogComponent } from './_components/user-notifications-dialog/user-notifications-dialog.component';
import { highchartsOptions } from './_constants/highcharts';
import { Module } from './_constants/module';
import { AuthService } from './_services/auth.service';
import { NavigationService } from './_services/navigation.service';
import { OrganizationService } from './_services/organization.service';
import { SessionService } from './_services/session.service';
import { UserNotificationService } from './_services/user-notification.service';
import { SesioIotProvider } from './_classes/SesioIoTProvider.class';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ width: 0 }), // initial
        animate('0.2s', style({ width: '*' })), // final
      ]),
      transition(':leave', [
        style({ width: '*' }), // initial
        animate('0.2s', style({ width: 0 })), // final
      ]),
    ]),
  ],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'user-organization-select-panel' }, // TODO create module for user organization select
    },
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  SesioIotProvider = SesioIotProvider;

  active?: Module | 'help' | 'administration';

  tooltipTop: number | null = null;
  tooltipMessage: string | null = null;

  searchControl = new FormControl();

  @ViewChildren('menu')
  menus: QueryList<MatListItem>;

  @ViewChild('organizationSelectRef')
  organizationSelectRef: MatSelect;
  selectWidth: number;

  @HostBinding('class') env = head(split(window.location.hostname, '.'));

  organizationModules: Module[];
  hasAdministrationAccess = false;

  private subsink = new SubSink();

  constructor(
    public authService: AuthService,
    public organizationService: OrganizationService,
    public userNotificationService: UserNotificationService,
    public sessionService: SessionService,
    private navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
  ) {
    this.initialize();
    const deviceInfo = this.deviceService.getDeviceInfo();
    console.log({
      deviceInfo,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      isMobile: this.deviceService.isMobile(),
      isTablet: this.deviceService.isTablet(),
      isDesktopDevice: this.deviceService.isDesktop(),
    });
  }

  ngOnInit(): void {
    this.subsink.add(
      this.authService.$user.subscribe(async (user) => {
        this.hasAdministrationAccess = !!user?.isAdministrator || !!user?.customerAccounts?.length;
        this.changeDetectorRef.detectChanges();
      }),
      this.sessionService.$organization.subscribe((value) => {
        this.hasAdministrationAccess = false;
        if (this.authService.user?.isAdministrator) {
          this.hasAdministrationAccess = true;
          this.organizationModules = value?.modules || [];
        } else if (this.authService.user) {
          if (this.authService.user?.customerAccounts?.length) {
            this.hasAdministrationAccess = true;
            this.organizationModules = value?.modules || [];
          } else {
            this.hasAdministrationAccess = this.authService.user.isOrganizationAdministrator(value?._id);
            this.organizationModules = this.authService.user.getOrganizationModules(value?._id);
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  switchOrganization(organization: Organization) {
    this.sessionService.setOrganization(organization);
    switch (this.active) {
      case 'help':
        return;
      case 'administration':
        const user = this.authService.user;
        if (user?.isAdministrator) return;
        if (user?.isOrganizationAdministrator(organization._id)) return;
        break;
      default:
        if (organization.modules.includes(this.active as Module)) return;
        break;
    }
    this.navigationService.navigate(['/', head(organization.modules)]);
  }

  organizationSelectResized(entries: ResizeObserverEntry[]) {
    this.selectWidth = get(head(entries), 'contentRect.width')!;
    this.changeDetectorRef.detectChanges();
  }

  tutorial() {}

  showTooltip(message: string | null, top: number) {
    this.tooltipMessage = message;
    this.tooltipTop = top;
  }

  hideTooltip(message: string | null) {
    if (this.tooltipMessage === message) {
      this.tooltipMessage = null;
      this.tooltipTop = null;
    }
  }

  loadMenu(event: IntersectionObserverEvent) {
    if (event.intersect) {
      const element = event.element.nativeElement;
      element.onmouseenter = (event: MouseEvent) =>
        this.showTooltip(element.getAttribute('tooltip'), element.offsetTop);
      element.onmouseleave = (event: MouseEvent) => this.hideTooltip(element.getAttribute('tooltip'));
    }
  }

  openUserNotifications() {
    this.dialog.open(UserNotificationsDialogComponent, {
      height: '100vh',
      maxHeight: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      panelClass: ['dialog-no-padding', 'dialog-no-border-radius'],
      exitAnimationDuration: '300ms',
      autoFocus: false,
    });
  }

  private initialize(locale = 'fr') {
    registerLocaleData(localeFr, locale);
    moment.locale(locale);
    Highcharts.setOptions(highchartsOptions[locale]);
    Highstock.setOptions(highchartsOptions[locale]);
  }
}
