/** @format */

import { Component, Input } from '@angular/core';
import moment from 'moment';
import { EquipmentFailureService } from '../../_services/equipment-failure/equipment-failure.service';

interface IValue {
  _id: string;
  disponibilityRate: number;
}

@Component({
  selector: 'app-equipment-disponibility-info',
  templateUrl: './equipment-disponibility-info.component.html',
  styleUrls: ['./equipment-disponibility-info.component.scss'],
})
export class EquipmentDisponibilityInfoComponent {
  @Input('value')
  set setValue(value: IValue) {
    this.load(value);
  }

  id: string;
  currentYear?: number;
  last12Months?: number;
  last6Months?: number;
  last3Months?: number;
  lastMonth?: number;
  currentMonth?: number;

  constructor(private equipmentFailureService: EquipmentFailureService) {}

  private load(value: IValue) {
    this.equipmentFailureService
      .getPeriodInfo({ equipmentId: value._id, from: moment().startOf('year') })
      .then((value) => (this.currentYear = value.disponibility));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(12, 'month'),
        to: moment().startOf('day'),
      })
      .then((value) => (this.last12Months = value.disponibility));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(6, 'month'),
        to: moment().startOf('day'),
      })
      .then((value) => (this.last6Months = value.disponibility));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('day').subtract(3, 'month'),
        to: moment().startOf('day'),
      })
      .then((value) => (this.last3Months = value.disponibility));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('month').subtract(1, 'month'),
        to: moment().startOf('month'),
      })
      .then((value) => (this.lastMonth = value.disponibility));
    this.equipmentFailureService
      .getPeriodInfo({
        equipmentId: value._id,
        from: moment().startOf('month'),
      })
      .then((value) => (this.currentMonth = value.disponibility));
  }
}
