<div class="mat-typography" fxLayout="column" fxLayoutGap="16px">
  <ng-container *ngIf="failure; else loadingTmpl">

    <div fxLayout="row" fxLayoutGap="32px">

    </div>

    <app-chart kind="gantt" [options]="chartOptions" (ready)="loadChart($event)">
    </app-chart>


  </ng-container>
</div>

<ng-template #loadingTmpl>
  <app-loader id="loader" @fadeOut [diameter]="60"></app-loader>
</ng-template>
