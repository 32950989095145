/** @format */

import { Injectable, Type } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { SensorPayload } from '../../_classes/sensor/sensor-payload.class';
import { Sensor } from '../../_classes/sensor/sensor.class';
import { LorawanSensor } from '../../_classes/sensor/sensor.lorawan.class';
import { SensorKind } from '../../_constants/sensor/sensor-kind';
import { CrudServiceBuild, ICrudService } from '../crud.service';
import { map, merge } from 'lodash-es';

export type PayloadFilter = {
  kinds?: string[];
};
export interface ISensorFilter {
  search?: string;
  organizationIds?: string[];
  archived?: boolean;
  bounds?: [[number, number], [number, number]];
}

export interface IMapInfo {
  _id: string;
  reference: string;
  coordinates: [number, number];
  indicatorValue: number;
}

@Injectable({
  providedIn: 'root',
})
export class SensorService extends ExtendsSensorService<Sensor, ISensorFilter>(Sensor) {}

export interface ISensorService<TClass extends Sensor, ReqFilter extends ISensorFilter>
  extends ICrudService<TClass, ReqFilter> {
  kind?: SensorKind;

  updateConfiguration(_id: string, payload: string): Promise<boolean>;
  clearData(_id: string): Promise<boolean>;
  getMapInfo(filter?: ReqFilter): Promise<IMapInfo[]>;
  getPayloads(
    _id: string,
    limit: number,
    skip: number,
    filter?: PayloadFilter,
  ): Promise<{ data: SensorPayload[]; matchCount: number }>;
  getPayload(_id: string, payloadId: string): Promise<SensorPayload>;
}

export function ExtendsSensorService<TClass extends Sensor, ReqFilter extends ISensorFilter>(
  target: Type<TClass>,
  kind?: SensorKind,
): Type<ISensorService<TClass, ReqFilter>> {
  const path = kind ? `/sensor/${kind}` : '/sensor';
  class SensorServiceClass
    extends CrudServiceBuild<TClass, ReqFilter>(target, path)
    implements ISensorService<TClass, ReqFilter>
  {
    kind?: SensorKind = kind;

    async updateConfiguration(_id: string, payload: string): Promise<boolean> {
      const path = `${this.path}/${_id}/update-configuration`;
      return this.httpService.patch(path, { payload });
    }

    async clearData(_id: string): Promise<boolean> {
      const path = `${this.path}/${_id}/clear-data`;
      return this.httpService.patch(path);
    }

    async getMapInfo(filter?: ReqFilter): Promise<IMapInfo[]> {
      const path = `${this.path}/get-map-info`;
      return this.httpService.post(path, this.getFilterQuery(filter));
    }

    async getPayload(_id: string, payloadId: string): Promise<SensorPayload> {
      const path = `${this.path}/${_id}/payload/${payloadId}`;
      return this.httpService.get(path);
    }

    async getPayloads(
      _id: string,
      limit: number,
      skip: number,
      filter?: PayloadFilter,
    ): Promise<{ data: SensorPayload[]; matchCount: number }> {
      const path = `${this.path}/${_id}/payload`;
      const body = { limit, skip };
      if (filter?.kinds?.length) merge(body, { filter: { kinds: filter.kinds } });
      return this.httpService.post(path, body).then((res) => ({
        data: map(res.data, (d) => new ModelMapper(SensorPayload).map(d)),
        matchCount: res.matchCount,
      }));
    }

    override buildType(data: any): TClass {
      const kindType = kind || data.kind;
      switch (kindType) {
        case SensorKind.LORAWAN:
          return new ModelMapper(LorawanSensor).map(data) as any;

        default:
          return new ModelMapper(Sensor).map(data) as any;
      }
    }
  }
  return SensorServiceClass as any;
}
