/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';

@Pipe({
  name: 'disponibilityRateColor',
})
export class DisponibilityRateColorPipe implements PipeTransform {
  transform(value?: number): string {
    return isNil(value)
      ? 'var(--app-color-black)'
      : value > 0.99
      ? 'var(--app-color-success)'
      : value > 0.98
      ? 'var(--app-color-primary)'
      : value > 0.97
      ? 'var(--app-color-warning)'
      : value > 0.96
      ? 'var(--app-color-danger)'
      : 'var(--app-color-black)';
  }
}
