/** @format */

import { PropertyMap } from 'model-mapper';

export class SereniteTicketHistoryEntry {
  @PropertyMap()
  texte: string;

  @PropertyMap({ type: Date })
  datetime: Date;

  @PropertyMap()
  type: string;
}

export class SereniteTicketApplicant {
  @PropertyMap()
  id_demandeur: number;

  @PropertyMap()
  nom: string;

  @PropertyMap()
  prenom: string;

  @PropertyMap()
  telephone1: string;

  @PropertyMap()
  telephone2: string;
}

export class SereniteTicket {
  @PropertyMap()
  id_ticket: number;

  @PropertyMap()
  id_externe: string;

  @PropertyMap()
  id_appareil: number;

  @PropertyMap()
  id_nature: number;

  @PropertyMap()
  id_ticket_origine: number;

  @PropertyMap()
  etat_appareil: number;

  @PropertyMap({ type: Date })
  date_creation: Date;

  @PropertyMap({ type: Date })
  date_cloture: Date;

  @PropertyMap({ type: Date })
  updated_at: Date;

  @PropertyMap()
  id_technicien_affecte: number;

  @PropertyMap()
  id_technicien_cloture: number;

  @PropertyMap({ type: SereniteTicketApplicant })
  demandeur: SereniteTicketApplicant;

  @PropertyMap({ type: [SereniteTicketHistoryEntry] })
  historique: SereniteTicketHistoryEntry[];
}
