/** @format */

import { Component, Input } from '@angular/core';
import { LocationAddress } from '../../_classes/location.class';
import { ColumnDef, ColumnOption } from '../datagrid/column-def.class';
import { ICell, IRow } from '../datagrid/datagrid.component';

type Value = LocationAddress;

@Component({
  selector: 'app-location-address-cell',
  templateUrl: './location-address-cell.component.html',
  styleUrls: ['./location-address-cell.component.scss'],
})
export class LocationAddressCellComponent {
  static getDatagridColumn(prefix?: string): ColumnOption {
    let property = 'location.address';
    if (prefix) property = `${prefix}.${property}`;
    return {
      property,
      label: $localize`Adresse`,
      linkedProperties: [
        `${property}.number`,
        `${property}.street`,
        `${property}.postalCode`,
        `${property}.city`,
        `${property}.formatted`,
      ],
      searchProperty: `${property}.formatted`,
      searchable: true,
      sortable: true,
      cellTmpl: 'location-address',
      maxWidth: 300,
    };
  }

  static getDatagridAdditionalColumn(prefix?: string): ColumnOption {
    let path = 'location.additional';
    if (prefix) path = `${prefix}.${path}`;
    return {
      property: path,
      label: $localize`Localisation précise`,
      searchable: true,
      sortable: true,
      maxWidth: 300,
    };
  }

  value?: Value;

  @Input()
  column: ColumnDef;

  @Input()
  record: any;

  @Input()
  row: IRow;

  @Input('cell')
  public set setCell(cell: ICell) {
    this.load(cell?.rawValue);
  }

  private async load(value: any) {
    if (typeof value === 'object') this.value = value;
  }
}
