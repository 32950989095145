/** @format */

import { map, values } from 'lodash-es';

export enum EquipmentSignatureStatus {
  OK = 'ok',
  OUT_OF_ORDER = 'out-of-order',
  SUSPICION_OUT_OF_ORDER = 'suspicion-out-of-order',
  NOFLOW = 'noflow',
  UNKNOWN = 'unknown',
  ND = 'nd',
}

export const EquipmentSignatureStatusColor: { [key: string]: string } = {
  [EquipmentSignatureStatus.OK]: 'var(--app-color-ok)',
  [EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]: 'var(--app-color-suspicion-out-of-order)',
  [EquipmentSignatureStatus.OUT_OF_ORDER]: 'var(--app-color-out-of-order)',
  [EquipmentSignatureStatus.NOFLOW]: 'var(--app-color-noflow)',
  [EquipmentSignatureStatus.UNKNOWN]: 'var(--app-color-medium)',
  [EquipmentSignatureStatus.ND]: 'var(--app-color-nd)',
};

export const EquipmentSignatureStatusIcon: { [key: string]: string } = {
  [EquipmentSignatureStatus.OK]: 'check_circle',
  [EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]: 'help_outline',
  [EquipmentSignatureStatus.OUT_OF_ORDER]: 'warning',
  [EquipmentSignatureStatus.NOFLOW]: 'link_off',
  [EquipmentSignatureStatus.UNKNOWN]: 'info',
  [EquipmentSignatureStatus.ND]: 'help_outline',
};

export const EquipmentSignatureStatusName: { [key: string]: string } = {
  [EquipmentSignatureStatus.OK]: $localize`En fonctionnement`,
  [EquipmentSignatureStatus.SUSPICION_OUT_OF_ORDER]: $localize`Contrôle du fonctionnement`,
  [EquipmentSignatureStatus.OUT_OF_ORDER]: $localize`En panne`,
  [EquipmentSignatureStatus.NOFLOW]: $localize`En absence de flux`,
  [EquipmentSignatureStatus.UNKNOWN]: $localize`Inconnu`,
  [EquipmentSignatureStatus.ND]: $localize`Non défini`,
};

export const EquipmentSignatureStatusOptions = map(values(EquipmentSignatureStatus), (value) => ({
  value,
  name: EquipmentSignatureStatusName[value],
  prefixIcon: EquipmentSignatureStatusIcon[value],
  color: EquipmentSignatureStatusColor[value],
}));

export const EquipmentSignatureStatusChartOptions = map(values(EquipmentSignatureStatus), (value) => ({
  value,
  name: EquipmentSignatureStatusName[value],
  icon: EquipmentSignatureStatusIcon[value],
  color: EquipmentSignatureStatusColor[value],
}));

export const EquipmentSignatureStatusDatagridOptions = map(values(EquipmentSignatureStatus), (value) => ({
  value: value,
  name: `<span nowrap>${EquipmentSignatureStatusName[value]}</span>`,
  icon: { name: EquipmentSignatureStatusIcon[value], color: EquipmentSignatureStatusColor[value] },
}));
