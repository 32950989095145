/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { S3File } from '../../_classes/s3-file.class';
import { fadeOut } from '../../_constants/animations';

@Component({
  selector: 'app-docx-viewer',
  templateUrl: './docx-viewer.component.html',
  styleUrls: ['./docx-viewer.component.scss'],
  animations: [fadeOut],
})
export class DocxViewerComponent implements OnInit {
  loading = true;

  @Input()
  file: S3File;

  constructor(private translate: TranslateService) {}

  ngOnInit() {}
}
