/** @format */

import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { map } from 'lodash-es';
import { GalleryItem, GalleryComponent as GalleryRef, ImageItem } from 'ng-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements AfterViewInit {
  @ViewChild(GalleryRef) galleryRef: GalleryRef;

  @Input('index')
  set setIndex(index: number) {
    this.index = index;
    this.galleryRef?.set(this.index);
  }
  private index: number = 0;

  @Input('images')
  set setImages(images: string[]) {
    this.images = images;
    this.items = map(
      this.images,
      (image) =>
        new ImageItem({
          src: image,
          thumb: image,
        })
    );
  }
  images: string[];

  items: GalleryItem[];

  constructor() {}

  ngAfterViewInit(): void {
    this.galleryRef?.set(this.index);
  }
}
