<mat-tab-group mat-align-tabs="start" fill sticky-header mat-stretch-tabs="false" mat-align-tabs="start">

  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>manage_history</mat-icon>
        <span i18n>Historique</span>
      </div>
    </ng-template>
    <div class="tab-content">
      <ng-container *ngIf="!!node; else loadingTmpl">
        <ng-container *ngTemplateOutlet="historicTabTmpl"></ng-container>
      </ng-container>
    </div>
  </mat-tab>

</mat-tab-group>
<button color="medium" mat-icon-button matDialogClose id="close-btn">
  <mat-icon>close</mat-icon>
</button>

<ng-template #historicTabTmpl>
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayoutAlign="start center" fxLayoutGap="8px">
      <span class="title" color="primary" i18n>
        <span light>État du réseau : </span>
        <span [style.color]="ConnectivityStatusColor[node.connectivity.status]">
          {{ConnectivityStatusName[node.connectivity.status]}} depuis {{node.connectivity.statusDate |
          humanizeDuration}}
        </span>
      </span>
      <span class="title" color="primary" i18n><span light>sésiO nOde label : </span>{{node.label}}</span>
      <span class="title" color="primary" i18n><span light>Référence : </span>{{node.reference}}</span>
    </div>
    <app-chart-range-selector selectedZoom="today">
      <div content fxLayout="column" fxLayoutGap="16px">
        <app-multi-field-history-chart #rangeElement [categories]="componentEntityCategories"
          [entities]="componentEntities" [style.width.%]="100"></app-multi-field-history-chart>
      </div>
    </app-chart-range-selector>
  </div>
</ng-template>

<ng-template #loadingTmpl><app-loader id="loader" [diameter]="100"></app-loader> </ng-template>
