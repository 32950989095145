/** @format */

import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appDatagridExpandedDetail]',
})
export class DatagridExpandedDetailDirective {
  constructor(public template: TemplateRef<any>) {}
}
