/** @format */

import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Position, TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective {
  @Input('tooltip')
  set setTooltip(data: TemplateRef<any> | string) {
    if (typeof data === 'string') this.componentRef.instance.content = data;
    else if (data instanceof TemplateRef) this.componentRef.instance.templateRef = data;
  }

  @Input('tooltipTrigger')
  trigger: 'click' | 'hover' = 'hover';

  @Input('tooltipOnOverflowOnly')
  set setOnOverflowOnly(onOverflowOnly: boolean | '') {
    this.onOverflowOnly = onOverflowOnly !== false;
  }
  onOverflowOnly: boolean = false;

  @Input('tooltipPosition')
  set setPosition(position: Position) {
    this.componentRef.instance.setPosition(position);
  }

  private componentRef: ComponentRef<TooltipComponent>;

  @HostListener('click')
  onClick(): void {
    if (this.trigger === 'click') this.componentRef.instance.toggle();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.trigger === 'hover') {
      const element = this.elementRef.nativeElement;
      if (!this.onOverflowOnly || element.offsetWidth < element.scrollWidth) {
        this.componentRef.instance.open();
      }
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.componentRef.instance.close();
  }

  appRef = inject(ApplicationRef);
  elementRef = inject(ElementRef);
  viewContainer = inject(ViewContainerRef);

  constructor() {
    this.componentRef = this.viewContainer.createComponent(TooltipComponent);
    this.componentRef.instance.trigger = this.elementRef;
    // this.componentRef.instance.templateRef = this.templateRef;
  }
}
