<ng-container [ngSwitch]="value">
  <span *ngSwitchCase="valueEnum.BASEMENT" [attr.light]="light" [style.--default-color]="'var(--app-color-primary)'"
    i18n>Sous-sol</span>
  <div *ngSwitchCase="valueEnum.GROUND_FLOOR" fxLayoutAlign="start center" fxLayoutGap="4px" i18n>
    <mat-icon [style.--default-color]="'#985321'" size="small">star</mat-icon>
    <span>Accès principal</span>
  </div>
  <span *ngSwitchCase=" valueEnum.FLOOR" [attr.light]="light" [style.--default-color]="'var(--app-color-primary)'"
    i18n>Étage</span>
  <span *ngSwitchDefault [attr.light]="light" [style.--default-color]="'var(--app-color-medium)'" i18n>?</span>
</ng-container>
