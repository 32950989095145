import { PropertyMap } from 'model-mapper';
import { Moment } from 'moment';
import { Blueprint } from './blueprint.class';
import { MapModel } from './map-model.class';
import { RealEstateStructure } from './real-estate-structure.class';

export interface IBuildingTags {
  greenEnergyTag: number;
  energyTag: number;
  climatTag: number;
}

export class ObjectiveEvolution {
  @PropertyMap()
  energy?: number;

  @PropertyMap()
  greenEnergy?: number;

  @PropertyMap()
  climat?: number; // 50g co2 / kwh

  @PropertyMap()
  year: number;
}

export class Objective {
  @PropertyMap()
  isSubjectObjective: boolean;

  @PropertyMap()
  isSubjectClimatPlan: boolean;

  @PropertyMap()
  referenceYear?: number;

  @PropertyMap({ type: [ObjectiveEvolution] })
  evolution?: ObjectiveEvolution[];
}

export class BuildingMetadata {
  @PropertyMap({ type: MapModel })
  mapModel: MapModel;

  @PropertyMap({ type: [Blueprint] })
  blueprints: Blueprint[];

  @PropertyMap()
  greenEnergyTag: number;

  @PropertyMap()
  energyTag: number;

  @PropertyMap()
  climatTag: number;

  @PropertyMap()
  thermalRegulationCompliance: string | null;

  @PropertyMap()
  bacsCompliance: string | null;

  @PropertyMap({ type: Objective })
  objective: Objective;
}

export class Building extends RealEstateStructure {
  @PropertyMap({ type: BuildingMetadata })
  override metadata: BuildingMetadata;
}
