<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="positions" (positionChange)="positionChange($event)">
  <div @fade id="container" class="{{position?.panelClass}}" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()"
    [style.--trigger-width]="trigger.nativeElement.clientWidth"
    [style.--trigger-height]="trigger.nativeElement.clientHeight">

    <ng-container *ngTemplateOutlet="templateRef">
    </ng-container>

    <div *ngIf="content" [innerHTML]="content"></div>

  </div>
</ng-template>
