/** @format */

import { PropertyMap } from 'model-mapper';
import { SensorBrand, SensorModel } from '../../_constants/sensor/sensor-brand';

export class GatewayInfo {
  @PropertyMap()
  reference: string;

  @PropertyMap()
  label: string;

  @PropertyMap()
  rssi: number;

  @PropertyMap()
  snr: number;

  @PropertyMap()
  indicatorValue: number;

  @PropertyMap()
  spreadingFactor: number;
}

export class SensorPayloadDownlink {
  @PropertyMap()
  status: 'queued' | 'sent' | 'acknowledged';

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap()
  ackedBy: string[];
}

export class SensorPayload {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  messageId: string;

  @PropertyMap()
  ackedMessageId: string;

  @PropertyMap()
  sensorId: string;

  @PropertyMap()
  devEui: string;

  @PropertyMap()
  awsId: string;

  @PropertyMap()
  brand: SensorBrand;

  @PropertyMap()
  model: SensorModel;

  @PropertyMap({ type: [GatewayInfo] })
  gateways: GatewayInfo[];

  @PropertyMap({ type: Date })
  date: Date;

  @PropertyMap({ type: SensorPayloadDownlink })
  downlink: SensorPayloadDownlink;

  @PropertyMap()
  payload: any;

  @PropertyMap()
  decoded: any;
}
