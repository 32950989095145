/** @format */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Highcharts from 'highcharts';
import PatternFill from 'highcharts/modules/pattern-fill';
import { each, get, head, includes, isNil, keys, map, round } from 'lodash-es';
import { SubSink } from 'subsink';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { SesioNodeComponent } from '../../../_classes/sesio-node-component/sesio-node-component.class';
import {
  SesioNodeComponentKind,
  hasDebugTopic,
  hasLiveTopic,
} from '../../../_constants/sesio-node-component/sesio-node-component-kind';
import {
  SesioNodeComponentStatusColor,
  SesioNodeComponentStatusIcon,
  SesioNodeComponentStatusOptions,
} from '../../../_constants/sesio-node-component/sesio-node-component-status';
import { NodeMqttService } from '../../../_services/mqtt/node.mqtt.service';
import { IDatagridOptions } from '../../datagrid/datagrid.component';

PatternFill(Highcharts);

interface IData {
  equipment: Equipment;
  sesioNodeComponents: SesioNodeComponent[];
}

interface IMessage {
  topic: string;
  date: Date;
  data: any;
}

interface IDisplayedDatagridOptions extends IDatagridOptions {
  title: string;
  info?: string[];
}

@Component({
  selector: 'app-sesio-node-component-control',
  templateUrl: './sesio-node-component-control.dialog.html',
  styleUrls: ['./sesio-node-component-control.dialog.scss'],
})
export class SesioNodeComponentControlDialog implements OnInit, OnDestroy {
  kind?: SesioNodeComponentKind;

  SesioNodeComponentKind = SesioNodeComponentKind;
  SesioNodeComponentStatusColor = SesioNodeComponentStatusColor;
  SesioNodeComponentStatusIcon = SesioNodeComponentStatusIcon;
  SesioNodeComponentStatusOptions = SesioNodeComponentStatusOptions;

  componentMessages: { [id: string]: IMessage[] } = {};
  floorInfo: any[];

  play = true;

  private subsink = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IData,
    public dialogRef: MatDialogRef<SesioNodeComponentControlDialog>,
    private nodeMqttService: NodeMqttService,
  ) {}

  ngOnInit(): void {
    this.kind = head(this.data.sesioNodeComponents)?.kind;
    each(this.data.sesioNodeComponents, (component) => {
      this.componentMessages[component._id] = [];
    });
    this.floorInfo = this.buildFloorsInfo();
    this.live();
  }

  ngOnDestroy(): void {
    if (this.liveInterval) clearInterval(this.liveInterval);
    this.subsink.unsubscribe();
  }

  clearLive() {
    each(keys(this.componentMessages), (key) => (this.componentMessages[key] = []));
  }

  private liveInterval: any;
  private async live() {
    each(this.data.sesioNodeComponents, (component) => {
      if (includes(hasDebugTopic, component.kind)) {
        this.subsink.add(
          this.nodeMqttService
            .getDebugTopicSubscription(component)
            .subscribe((data: any) => this.pushMessage(component, 'debug', data)),
        );
        this.nodeMqttService.publishDebugAction(component);
      } else if (includes(hasLiveTopic, component.kind)) {
        this.subsink.add(
          this.nodeMqttService
            .getLiveTopicSubscription(component)
            .subscribe((data: any) => this.pushMessage(component, 'live', data)),
        );
        this.nodeMqttService.publishLiveAction(component);
      }
      this.subsink.add(
        this.nodeMqttService
          .getDataTopicSubscription(component)
          .subscribe((data: any) => this.pushMessage(component, 'data', data)),
      );
    });

    this.liveInterval = setInterval(async () => {
      if (document.visibilityState !== 'visible') return;
      each(this.data.sesioNodeComponents, (component) => {
        if (includes(hasDebugTopic, component.kind)) this.nodeMqttService.publishDebugAction(component);
        else this.nodeMqttService.publishLiveAction(component);
      });
    }, 50000);
  }

  private pushMessage(component: SesioNodeComponent, topic: string, data: any) {
    if (!this.play) return;
    if (component.reference !== data.component) return;
    this.componentMessages[component._id].splice(0, 0, { topic, date: new Date(), data });
  }

  private buildFloorsInfo(): string[] {
    return map(get(this.data, 'equipment.metadata.floors'), (floor) => {
      const floorMinDistance = isNil(floor.minDistance) ? floor.distance : floor.minDistance;
      const floorMaxDistance = isNil(floor.maxDistance) ? floor.distance : floor.maxDistance;
      return $localize`
          <div><span>${floor.name}</span></div>
          <div>
            <span color="primary">${round(get(floor, 'distance', 0) / 100, 2)} m</span>
          </div>
          <div>
            <span light>${round(floorMinDistance / 100, 2)} m</span>
            <span> <> </span>
            <span light>${round(floorMaxDistance / 100, 2)} m</span>
        </div>
        `;
    });
  }
}
