/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { split } from 'lodash';

@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
  transform(data: string, separator: string = ', '): string[] {
    return split(data, separator);
  }
}
