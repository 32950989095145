<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <span id="title" [innerHTML]="title | safe:'html'"></span>
  <button id="close" mat-icon-button mat-dialog-close size="small">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div [innerHTML]="message | safe:'html'"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="dissmiss(true)">
    <span [innerHTML]="validate | safe:'html'"></span>
  </button>
  <button mat-button (click)="dissmiss(false)" color="warn" cdkFocusInitial>
    <span [innerHTML]="cancel | safe:'html'"></span>
  </button>
</mat-dialog-actions>
