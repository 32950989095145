/** @format */

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[fxFlex]',
})
export class FlexDirective implements AfterViewInit {
  @Input('fxFlex')
  set setFlex(flex: string) {
    this.fxFlex = flex;
    this.update();
  }
  fxFlex: string;

  private viewInit = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.viewInit = true;
    this.update();
  }

  private update() {
    switch (this.fxFlex) {
      case 'none':
        this.elementRef.nativeElement.style.flex = '0 0 auto';
        break;
      case '':
        this.elementRef.nativeElement.style.flex = '1 1 0%';
        break;
      case 'nogrow':
      case 'initial':
        this.elementRef.nativeElement.style.flex = '0 1 auto';
        break;
      case 'auto':
        this.elementRef.nativeElement.style.flex = '1 1 auto';
        break;
      case 'noshrink':
        this.elementRef.nativeElement.style.flex = '1 0 auto';
        break;
      case '0':
        this.elementRef.nativeElement.style.flex = '1 1 0%';
        break;
      case 'grow':
        this.elementRef.nativeElement.style.flex = '1 1 100%';
        this.setMaxSize();
        break;
      default:
        const value = parseFloat(this.fxFlex);
        if (!isNaN(value)) {
          this.elementRef.nativeElement.style.flex = `1 1 ${value}%`;
          this.setMaxSize();
        }
    }
  }

  private setMaxSize() {
    if (!this.viewInit) return;
    const flow = this.elementRef.nativeElement.parentNode?.style.flexDirection || 'row';
    if (flow === 'row') {
      this.elementRef.nativeElement.style['max-width'] = '100%';
      this.elementRef.nativeElement.style['max-height'] = undefined;
    } else {
      this.elementRef.nativeElement.style['max-width'] = undefined;
      this.elementRef.nativeElement.style['max-height'] = '100%';
    }
  }
}
