/** @format */

import { PropertyMap } from 'model-mapper';
import { RealEstateStructureFamily } from '../_constants/real-estate-structure-family';
import { embeddedSerialize } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { Color } from './color.class';
import { EmbeddedOrganizationalUnitKind } from './organizational-unit-kind.embedded.class';

export class RealEstateStructureKind {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap()
  family: RealEstateStructureFamily;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Color })
  color: Color;

  @PropertyMap()
  parents: (string | null)[];

  @PropertyMap({ type: EmbeddedOrganizationalUnitKind, serialize: embeddedSerialize })
  organizationalUnitKind: EmbeddedOrganizationalUnitKind;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
