/** @format */

import { PropertyMap } from 'model-mapper';
import { BoilerRoomMode } from '../_constants/boiler-room-mode';
import { EnergyKind } from '../_constants/energy-kind';
import { Blueprint } from './blueprint.class';
import { RealEstateStructure } from './real-estate-structure.class';
import { S3File } from './s3-file.class';

export class BoilerRoomInstructions {
  @PropertyMap()
  setpointTemperature20: number;

  @PropertyMap()
  setpointTemperature10: number;

  @PropertyMap()
  setpointTemperature0: number;

  @PropertyMap()
  setpointTemperatureMinus10: number;

  @PropertyMap()
  setpointTemperatureStop: number;

  @PropertyMap()
  setpointTemperatureNightReduction: number;

  @PropertyMap()
  setpointTemperatureHeating: number;

  @PropertyMap()
  setpointTemperatureWater: number;
}

export class BoilerRoomMetadata {
  @PropertyMap({ type: [Blueprint] })
  blueprints: Blueprint[];

  @PropertyMap({ type: S3File })
  plan3d: S3File;

  @PropertyMap({ type: S3File })
  photogrammetry: S3File;

  @PropertyMap()
  greenEnergyTag: number;

  @PropertyMap()
  energyTag: number;

  @PropertyMap()
  climatTag: number;

  @PropertyMap()
  energyKind: EnergyKind;

  @PropertyMap()
  mode: BoilerRoomMode;

  @PropertyMap()
  power: number;

  @PropertyMap()
  nbSubstations: number;

  @PropertyMap()
  lowerCalorificValue: number;

  @PropertyMap()
  higherCalorificValue: number;

  @PropertyMap()
  waterTemperatureDeparture: number;

  @PropertyMap()
  waterTemperatureArrival: number;

  @PropertyMap()
  heatingTemperatureDeparture: number;

  @PropertyMap()
  heatingTemperatureArrival: number;

  @PropertyMap({ type: BoilerRoomInstructions })
  instructions: BoilerRoomInstructions;
}

export class BoilerRoom extends RealEstateStructure {
  @PropertyMap({ type: BoilerRoomMetadata })
  override metadata: BoilerRoomMetadata;
}
