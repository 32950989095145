/** @format */

export enum GpioDirection {
  READ = 'read',
  WRITE = 'write',
}

export const GpioDirectionName: { [kind: string]: string } = {
  [GpioDirection.READ]: $localize`Réception`,
  [GpioDirection.WRITE]: $localize`Émission`,
};
