/** @format */

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({ exports: [], imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppIconModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    iconRegistry.addSvgIcon('sesio', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/loader.svg`));
    iconRegistry.addSvgIcon('sesio-white', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/loader-white.svg`));
    iconRegistry.addSvgIcon('menu-expand', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu-expand.svg`));
    iconRegistry.addSvgIcon(
      'menu-minimize',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/menu-minimize.svg`),
    );

    iconRegistry.addSvgIcon(
      'real-estate-assets',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/real-estate-assets.svg`),
    );
    iconRegistry.addSvgIcon(
      'air-quality',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/air-quality.svg`),
    );
    iconRegistry.addSvgIcon('energy', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/energy.svg`));
    iconRegistry.addSvgIcon(
      'ventilation-and-air-conditioning',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/ventilation-and-air-conditioning.svg`),
    );
    iconRegistry.addSvgIcon('elevator', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/elevator.svg`));
    iconRegistry.addSvgIcon(
      'parking-door',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/parking-door.svg`),
    );
    iconRegistry.addSvgIcon(
      'fire-safety',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/fire-safety.svg`),
    );
    iconRegistry.addSvgIcon(
      'safety-and-access-control',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/safety-and-access-control.svg`),
    );
    iconRegistry.addSvgIcon('iot', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/modules/iot.svg`));

    iconRegistry.addSvgIcon('orga', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/orga.svg`));
    iconRegistry.addSvgIcon('orga-full', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/orga-full.svg`));
    iconRegistry.addSvgIcon('visa', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/visa.svg`));
    iconRegistry.addSvgIcon('mastercard', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/mastercard.svg`));
    iconRegistry.addSvgIcon('thermometer', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/thermometer.svg`));
    iconRegistry.addSvgIcon('cloudy', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/cloudy.svg`));
    iconRegistry.addSvgIcon('sun', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/sun.svg`));
    iconRegistry.addSvgIcon('wind', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/wind.svg`));
    iconRegistry.addSvgIcon('2d', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/2d.svg`));
    iconRegistry.addSvgIcon('3d', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/3d.svg`));
    iconRegistry.addSvgIcon(
      '1-bar-signal',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/signal-indicator/1-bar-signal.svg`),
    );
    iconRegistry.addSvgIcon(
      '2-bar-signal',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/signal-indicator/2-bar-signal.svg`),
    );
    iconRegistry.addSvgIcon(
      '3-bar-signal',
      sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/signal-indicator/3-bar-signal.svg`),
    );
  }
}
