/** @format */

import { APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AppFlexLayoutModule } from './_directives/flex-layout/flex-layout.module';

import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MESSAGE_FORMAT_CONFIG } from 'ngx-translate-messageformat-compiler';
import { AppTranslateModuleForRoot } from './app-translate.module';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperIntl } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AvatarModule } from 'ngx-avatars';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppLoaderModule } from './_components/loader/loader.module';
import { NotificationModule } from './_components/notification/notification.module';
import { UserNotificationDialogModule } from './_components/user-notifications-dialog/user-notifications-dialog.module';
import { AppTooltipModule } from './_directives/tooltip/tooltip.module';
import { AwsPipesModule } from './_pipes/aws-pipes/aws-pipes.module';
import { ExtentedPipesModule } from './_pipes/extented-pipes/extended-pipes.module';
import { AppAmplifyModule } from './app-amplify.module';
import { AppIconModule } from './app-icon.module';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
  private onLabel: string;

  constructor(private translate: TranslateService) {
    super();
    this.itemsPerPageLabel = $localize`Éléments par page :`;
    this.nextPageLabel = $localize`Page suivante`;
    this.previousPageLabel = $localize`Page précédente`;
    this.onLabel = $localize`sur`;
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.onLabel} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.onLabel} ${length}`;
  };
}

@Injectable()
export class StepperIntl extends MatStepperIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.get(['stepper-optional-label']).subscribe((labels) => {
      this.optionalLabel = labels['stepper-optional-label'];
    });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppIconModule,
    AppRoutingModule,
    AppFlexLayoutModule,
    BrowserAnimationsModule,
    AmplifyAuthenticatorModule,
    LoggerModule.forRoot({
      enableSourceMaps: true,
      level: NgxLoggerLevel.DEBUG,
    }),
    AppTranslateModuleForRoot,
    AppAmplifyModule,
    MatSidenavModule,
    NgScrollbarModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTooltipModule,
    AppTooltipModule,
    MatMenuModule,
    AvatarModule,
    AwsPipesModule,
    ExtentedPipesModule,
    AppLoaderModule,
    IntersectionObserverModule,
    NotificationModule,
    MatBadgeModule,
    UserNotificationDialogModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: ['fr', 'en'],
        biDiSupport: true,
        formatters: {
          upcase: (v: any) => v.toUpperCase(),
        },
      },
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    { provide: MatStepperIntl, useClass: StepperIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
