/** @format */

import { PropertyMap } from 'model-mapper';
import { EquipmentCabinCallName } from '../../_constants/equipment-cabin-call-name';
import { SesioNodeComponent } from './sesio-node-component.class';

export class SesioNodeComponentCabinCallGpio extends SesioNodeComponent {
  @PropertyMap()
  cabinCalls: EquipmentCabinCallName[];
}
