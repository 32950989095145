/** @format */

import { PropertyMap } from 'model-mapper';
import { ElevatorDoubtRemovalTrigger } from '../equipment/equipment.elevator-signature.class';
import { ElevatorLastDoorState, ElevatorLastMouvement } from '../equipment/equipment.elevator.class';
import { EquipmentFailure } from './equipment-failure.class';

export class EquipmentFailureElevator extends EquipmentFailure {
  @PropertyMap()
  sereniteOrganizationReference: number;

  @PropertyMap()
  sereniteEquipmentReference: number;

  @PropertyMap()
  sereniteReference: number;

  @PropertyMap({ type: ElevatorLastMouvement })
  lastMouvement: ElevatorLastMouvement;

  @PropertyMap({ type: ElevatorLastDoorState })
  lastDoorState: ElevatorLastDoorState;

  @PropertyMap({ type: [ElevatorDoubtRemovalTrigger] })
  triggeredDoubtRemovals: ElevatorDoubtRemovalTrigger[];
}
