/** @format */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IDialogData {
  confirmParams: any;
  title: string;
  message: string;
  ok: string;
  cancel: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  confirmParams: any;
  title: string;
  message: string;
  validate: string;
  cancel: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData, private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    this.confirmParams = data.confirmParams || {};
    this.title = data.title || $localize`Confirmation`;
    this.message = data.message || $localize`Confirmez-vous l'action ?`;
    this.validate = data.ok || $localize`Confirmer`;
    this.cancel = data.cancel || $localize`Annuler`;
  }

  ngOnInit(): void {}

  dissmiss(confirm = false): void {
    this.dialogRef.close(confirm);
  }
}
