/** @format */

import { PropertyMap } from 'model-mapper';

export type EquipmentSignatureIndicatorName = '__day-of-month__' | '__day-of-week__' | '__day__' | string;

export class EquipmentSignatureIndicator {
  @PropertyMap()
  organization: string;

  @PropertyMap()
  equipmentId: string;

  @PropertyMap()
  name: EquipmentSignatureIndicatorName;

  @PropertyMap()
  month: number;

  @PropertyMap()
  dayOfWeek: number;

  @PropertyMap()
  hour: number;

  @PropertyMap()
  minute: number;

  @PropertyMap()
  count: number;

  @PropertyMap()
  sum: number;

  @PropertyMap()
  min: number;

  @PropertyMap()
  max: number;

  @PropertyMap()
  avg: number;

  @PropertyMap()
  nbPeriods: number;
}
