import { PropertyMap } from 'model-mapper';
import { S3File } from './s3-file.class';

export class Blueprint {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: S3File })
  file: S3File;
}
