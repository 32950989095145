/** @format */

import { Injectable } from '@angular/core';
import { cloneDeep, concat, each, find, findIndex, includes, isEqual, merge, remove } from 'lodash-es';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, skip } from 'rxjs';
import { SearchDisplayItem, SearchDisplayOption, SearchItem, SearchOption } from '../_classes/search.class';
import { SearchView } from '../_classes/user.class';
import { EquipmentStatusName, EquipmentStatusOptions } from '../_constants/equipment-status';
import { Module } from '../_constants/module';
import { SearchKey } from '../_constants/search';
import { buildSearchString } from '../_helpers/string.helper';
import { FileService } from './file.service';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public $search: BehaviorSubject<SearchDisplayItem[]> = new BehaviorSubject([] as SearchDisplayItem[]);

  protected pathBase = '/search';
  private SEARCH_STORAGE_KEY = 'currentSearch';

  constructor(
    protected httpService: HttpService,
    protected fileService: FileService,
    protected storageService: StorageService,
    protected logger: NGXLogger,
  ) {
    this.init();
  }

  public setSearchView(view: SearchView) {
    const options: SearchDisplayOption[] = [];
    for (let item of view.items) {
      for (let value of item.values) {
        options.push(this.buildSearchOption({ key: item.key, value }));
      }
    }
    this.setSearch(options);
  }

  public removeSearchEntry(key: SearchKey) {
    const items = cloneDeep(this.$search.value);
    const entry = find(items, { key });
    if (!entry) return;
    remove(items, { key });
    this.$search.next(items);
  }

  public removeSearchItem(key: SearchKey, value: any) {
    const items = cloneDeep(this.$search.value);
    const entry = find(items, { key });
    if (!entry) return;
    const index = findIndex(entry.values, (v) => isEqual(v, value));
    if (index !== -1) {
      entry.values.splice(index, 1);
      entry.displayValues.splice(index, 1);
    }
    if (!entry.values.length) remove(items, { key });
    this.$search.next(items);
  }

  public addSearchItem(option: SearchDisplayOption) {
    const items = cloneDeep(this.$search.value) || [];
    let entry = find(items, { key: option.key });
    if (!entry) items.push((entry = { key: option.key, values: [], displayKey: option.displayKey, displayValues: [] }));
    entry.values.push(option.value);
    entry.displayValues.push(option.displayValue);
    this.$search.next(items);
  }

  public setSearch(options: SearchDisplayOption[]) {
    const items: SearchDisplayItem[] = [];
    each(options, (option) => {
      let entry = find(items, { key: option.key });
      if (!entry)
        items.push((entry = { key: option.key, values: [], displayKey: option.displayKey, displayValues: [] }));
      entry.values.push(option.value);
      entry.displayValues.push(option.displayValue);
    });
    this.$search.next(items);
  }

  public async getSearchOptions(module: Module, search: string, current: SearchItem[]): Promise<SearchDisplayOption[]> {
    const searchString = buildSearchString(search);
    let searchOptions: SearchDisplayOption[] = [];
    const path = `${this.pathBase}/options`;
    try {
      const options = await this.httpService
        .post(path, { search, current }, { module })
        .then((options) => this.buildSearchOptions(options));
      each(options, (option) => {
        if (find(searchOptions, (so) => isEqual(so.key, option.key) && isEqual(so.value, option.value))) return;
        searchOptions.push(option);
      });
      searchOptions = concat(searchOptions, this.getConstantSearchOptions(module, searchString, current));
      return searchOptions;
    } catch (err) {
      this.logger.error(err);
      return [];
    }
  }

  public buildSearchOptions(options: SearchOption[]): SearchDisplayOption[] {
    const searchOptions: SearchDisplayOption[] = [];
    each(options, (option) => searchOptions.push(this.buildSearchOption(option)));
    return searchOptions;
  }

  public buildSearchOption(option: SearchOption): SearchDisplayOption {
    switch (option.key) {
      case SearchKey.ORGANIZATIONAL_UNIT:
        merge(option, {
          displayKey: option.value.kindName,
          displayValue: `<span light>${option.value.reference}</span> ${option.value.name}`,
        });
        break;
      case SearchKey.REAL_ESTATE_STRUCTURE:
        merge(option, {
          displayKey: option.value.kindName,
          displayValue: `<span light>${option.value.reference}</span> ${option.value.name}`,
        });
        break;
      case SearchKey.EQUIPMENT_CATEGORY:
        merge(option, { displayKey: $localize`Catégorie d'équipement`, displayValue: option.value.name });
        break;
      case SearchKey.EQUIPMENT_KIND:
        merge(option, { displayKey: $localize`Type d'équipement`, displayValue: option.value.name });
        break;
      case SearchKey.EQUIPMENT_ASSIGNATION:
        merge(option, {
          displayKey: $localize`Assigné à`,
          displayValue:
            option.value?.firstname && option.value?.lastname
              ? `${option.value.firstname} ${option.value.lastname}`
              : option.value?.email,
        });
        break;
      case SearchKey.EQUIPMENT_STATUS:
        merge(option, {
          displayKey: $localize`Statut d'équipement`,
          displayValue: EquipmentStatusName[option.value],
        });
        break;
      case SearchKey.TEXT:
        merge(option, { displayKey: $localize`Recherche`, displayValue: option.value });
        break;
      default:
        this.logger.warn(`unmanaged ${option.key}`);
    }
    return option as SearchDisplayOption;
  }

  private getConstantSearchOptions(module: Module, search: string, current: SearchItem[]): SearchDisplayOption[] {
    let searchOptions: SearchDisplayOption[] = [];
    switch (module) {
      case Module.ELEVATOR:
      case Module.PARKING_DOOR:
      case Module.FIRE_SAFETY:
      case Module.RELAY_ANTENNA:
        each(EquipmentStatusOptions, (option) => {
          if (!includes(buildSearchString(option.name), search)) return;
          const c = find(current, (c) => isEqual(c.key, SearchKey.EQUIPMENT_STATUS));
          if (c && includes(c.values, option.value)) return;
          searchOptions.push({
            displayKey: $localize`Statut d'équipement`,
            key: SearchKey.EQUIPMENT_STATUS,
            displayValue: option.name,
            value: option.value,
          });
        });
        break;
    }
    return searchOptions;
  }

  private async init() {
    this.$search.next(await this.storageService.get(this.SEARCH_STORAGE_KEY));
    this.$search.pipe(skip(1)).subscribe((data) => {
      if (data) this.storageService.set(this.SEARCH_STORAGE_KEY, data);
      else this.storageService.remove(this.SEARCH_STORAGE_KEY);
    });
  }
}
