/** @format */

import { Injectable } from '@angular/core';
import { find, get, has, isNil, filter as lfilter, map as lmap, map } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { OrganizationalUnitKind } from '../_classes/organizational-unit-kind.class';
import { OrganizationalUnit } from '../_classes/organizational-unit.class';
import { IBuildingTags } from '../_classes/real-estate-structure.building.class';
import { SearchItem } from '../_classes/search.class';
import { TreeNode } from '../_classes/tree-node.class';
import { EditFormFieldOption } from '../_components/edit-dialog/edit-form/edit-form.component';
import { TagSerieValue } from '../_constants/tags';
import { CrudServiceBuild, FilterType } from './crud.service';

export interface ITreeNode extends TreeNode {
  count: number;
}

export type SerieValue = {
  _id: string;
  value: number;
};
export interface IOrganizationalUnitFilter extends FilterType {
  search?: SearchItem[];
  kinds?: (OrganizationalUnitKind | string)[];
  parentId?: string | null;
  parentPath?: string | null;
  root?: boolean;
}

export interface ITreeOrganizationalUnitFilter extends IOrganizationalUnitFilter {
  excludeEquipment?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitService extends CrudServiceBuild<OrganizationalUnit, IOrganizationalUnitFilter>(
  OrganizationalUnit,
  '/organizational-unit',
) {
  async getTreeLevel(filter?: ITreeOrganizationalUnitFilter): Promise<TreeNode[]> {
    const path = `${this.path}/tree-level`;
    return this.httpService
      .post(path, this.getTreeFilterQuery(filter))
      .then((data) => lmap(data, (d) => new ModelMapper(TreeNode).map(d)));
  }

  async getEditOptions(filter?: IOrganizationalUnitFilter): Promise<EditFormFieldOption[]> {
    return this.list(filter).then((data) => map(data, (d) => ({ name: d.name, value: d._id, prefixTop: d.kind.name })));
  }

  async countByKind(filter?: IOrganizationalUnitFilter): Promise<{ kind: OrganizationalUnitKind; count: number }[]> {
    const path = `${this.path}/count-by-kind`;
    const query = this.getFilterQuery(filter);
    return this.httpService.get(path, query);
  }

  async getTags(id?: string): Promise<IBuildingTags> {
    const path = `${this.path}/tags`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getEnergyTags(id?: string): Promise<TagSerieValue[]> {
    const path = `${this.path}/energy-tags`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getClimatTags(id?: string): Promise<TagSerieValue[]> {
    const path = `${this.path}/climat-tags`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getGreenEnergyTags(id?: string): Promise<TagSerieValue[]> {
    const path = `${this.path}/green-energy-tags`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getConformities(id?: string): Promise<SerieValue[]> {
    const path = `${this.path}/conformities`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getBACSCompliances(id?: string): Promise<SerieValue[]> {
    const path = `${this.path}/bacs-compliances`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getTertiaryDecree(id?: string): Promise<SerieValue[]> {
    const path = `${this.path}/tertiary-decree`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getTertiaryDecreeEvolution(id?: string): Promise<SerieValue[]> {
    const path = `${this.path}/tertiary-decree-evolution`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  async getObjective(id?: string): Promise<{ _id: string; energy: number; climat: number }[]> {
    const path = `${this.path}/objective`;
    const query: any = {};
    if (id) query.organizationalUnitId = id;
    return this.httpService.get(path, query);
  }

  // TODO replace by getTrees
  async getTree(filter?: IOrganizationalUnitFilter, click?: (node: ITreeNode) => void): Promise<ITreeNode> {
    const entities = await this.list(filter);
    const children = entities.filter(
      (e) => e.parent === filter?.parentPath || (isNil(e.parent) && isNil(filter?.parentPath)),
    );
    const node = {
      entity: filter?.parentPath ? find(entities, { _id: filter?.parentPath }) : null,
      children: this.getNodeChildren(entities, children, click),
      count: children.length,
      onClick: async () => (click ? click(node) : null),
    } as any;
    return node;
  }

  async getTrees(filter?: IOrganizationalUnitFilter, click?: (node: ITreeNode) => void): Promise<ITreeNode[]> {
    const entities = await this.list(filter);
    const roots = entities.filter((e) => isNil(e.parent));
    return this.getNodeChildren(entities, roots, click);
  }

  private getNodeChildren(
    entities: OrganizationalUnit[],
    childrenEntities: OrganizationalUnit[],
    click?: (node: ITreeNode) => void,
  ): ITreeNode[] {
    return lmap(childrenEntities, (entity) => {
      const children = entities.filter((e) => e.parent === entity._id);
      const childrenNode = this.getNodeChildren(entities, children, click);
      const node: ITreeNode = {
        type: OrganizationalUnit.name,
        entity,
        count: children.length,
        color: entity.color,
        children: childrenNode.length > 20 ? [] : childrenNode,
        onClick: async () => (click ? click(node) : (node.hideChildren = !node.hideChildren)),
      };
      return node;
    });
  }

  protected buildOrganizationalUnits(data: any[]): OrganizationalUnit[] {
    return lmap(data, (d) => this.buildOrganizationalUnit(d));
  }

  protected buildOrganizationalUnit(data: any): OrganizationalUnit {
    return new ModelMapper(OrganizationalUnit).map(data) as any;
  }

  override async processData(data: any, action: 'create' | 'update'): Promise<any> {
    if (has(data, 'kind')) {
      const kind = get(data, 'kind');
      data.family = get(kind, 'family');
      if (!kind.parent) data.parent = null;
    }
    return super.processData(data, action);
  }

  override getFilterQuery(
    filter?: IOrganizationalUnitFilter,
    fields?: string | string[],
    sorts?: string,
  ): any {
    const query: any = super.getFilterQuery(filter, fields, sorts);
    if (filter?.kinds?.length) {
      query.kindIds = map(
        lfilter(filter.kinds, (kind) => !!kind),
        (kind) => (typeof kind === 'string' ? kind : kind._id),
      );
    }
    if (filter?.parentId !== undefined) query.parentId = filter.parentId;
    if (filter?.parentPath) query.parentPath = filter.parentPath;
    if (filter?.root === true) query.root = true;
    return query;
  }

  private getTreeFilterQuery(filter?: ITreeOrganizationalUnitFilter): any {
    const query = this.getFilterQuery(filter);
    if (filter?.excludeEquipment === true) query.excludeEquipment = true;
    return query;
  }
}
