<div id="container" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span class="label" light i18n>Taux de disponibilité sur les 12 derniers mois</span>
    <app-disponibility-rate-label [value]="last12Months" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span class="label" light i18n>Taux de disponibilité sur l'année en cours</span>
    <app-disponibility-rate-label [value]="currentYear" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span class="label" light i18n>Taux de disponibilité sur les 6 derniers mois</span>
    <app-disponibility-rate-label [value]="last6Months" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"> <span class="label" light i18n>Taux de
      disponibilité sur les 3 derniers mois</span>
    <app-disponibility-rate-label [value]="last3Months" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span class="label" light i18n>Taux de disponibilité sur le mois dernier</span>
    <app-disponibility-rate-label [value]="lastMonth" />
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
    <span class="label" light i18n>Taux de disponibilité sur le mois en cours</span>
    <app-disponibility-rate-label [value]="currentMonth" />
  </div>
</div>
