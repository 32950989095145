/** @format */

import { PropertyMap } from 'model-mapper';
import { Embedded } from '../../_decorators/embedded-class.decorator';

@Embedded()
export class EmbeddedGateway {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  reference: string; // gateway eui

  @PropertyMap()
  label: string; // gateway name

  @PropertyMap()
  awsId: string; // WirelessDeviceId
}
