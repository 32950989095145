<div id="container" #container [style.cursor]="cursor" (obsIntersection)="load($event)">
  <div id="content" #content>
    <div id="pan" #pan (obsResize)="rezise()">
      <ng-content></ng-content>
    </div>
  </div>
  <button id="reset" mat-mini-fab size="small" (click)="rezise()">
    <mat-icon>crop_free</mat-icon>
  </button>
</div>
