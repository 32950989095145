/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { isNil, merge } from 'lodash-es';
import { fade } from '../../_constants/animations';
import humanizeDuration, { HumanizerOptions } from 'humanize-duration';

@Component({
  selector: 'app-humanize-duration',
  templateUrl: './humanize-duration.component.html',
  styleUrls: ['./humanize-duration.component.scss'],
  animations: [fade],
})
export class HumanizeDurationComponent implements OnInit {
  ready = false;
  loading = true;

  @Input()
  color: string;

  @Input()
  options: HumanizerOptions;

  @Input('value')
  set setValue(value: number | null | undefined) {
    this.value = value;
    this.build();
  }
  value: number | null | undefined;
  duration: string | null;

  constructor() {}

  ngOnInit() {
    this.ready = true;
    this.build();
  }

  private build() {
    this.duration = !isNil(this.value)
      ? humanizeDuration(this.value, merge({ language: 'fr', maxDecimalPoints: 0 }, this.options))
      : null;
    this.loading = !this.ready || isNil(this.duration);
  }
}
