/** @format */

import { Component, Input } from '@angular/core';
import {
  EquipmentStatus,
  EquipmentStatusColorName,
  EquipmentStatusIcon,
  EquipmentStatusName,
} from '../../_constants/equipment-status';

@Component({
  selector: 'app-equipment-status',
  templateUrl: './equipment-status.component.html',
  styleUrls: ['./equipment-status.component.scss'],
})
export class EquipmentStatusComponent {
  EquipmentStatusColor = EquipmentStatusColorName;
  EquipmentStatusName = EquipmentStatusName;
  EquipmentStatusIcon = EquipmentStatusIcon;

  @Input()
  status: EquipmentStatus;

  @Input()
  reference: string;

  @Input('icon-only')
  set setIconOnly(iconOnly: any) {
    iconOnly = iconOnly !== false;
  }
  iconOnly: boolean = false;
}
