/** @format */

import { map, values } from 'lodash-es';

export enum SensorKind {
  LORAWAN = 'lorawan',
}

export const SensorKindName: { [kind: string]: string } = {
  [SensorKind.LORAWAN]: $localize`LoRaWAN`,
};

export const SensorKindOptions: { value: any; name: string }[] = map(values(SensorKind), (value) => ({
  value,
  name: SensorKindName[value],
}));
