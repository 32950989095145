<div id="container" fxLayout="row" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="16px">
    <span class="label"></span>
    <span class="entry" i18n>12 derniers mois</span>
    <span class="entry" i18n>Année en cours</span>
    <span class="entry" i18n>6 derniers mois</span>
    <span class="entry" i18n>3 derniers mois</span>
    <span class="entry" i18n>Mois dernier</span>
    <span class="entry" i18n>Mois en cours</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <span class="label" i18n>Nombre de pannes</span>
    <app-failure-count-label class="info" [value]="last12Months?.count" />
    <app-failure-count-label class="info" [value]="currentYear?.count" />
    <app-failure-count-label class="info" [value]="last6Months?.count" />
    <app-failure-count-label class="info" [value]="last3Months?.count" />
    <app-failure-count-label class="info" [value]="lastMonth?.count" />
    <app-failure-count-label class="info" [value]="currentMonth?.count" />
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <span class="label" i18n>Durée des pannes</span>
    <app-humanize-duration class="info" [value]="last12Months?.duration" />
    <app-humanize-duration class="info" [value]="currentYear?.duration" />
    <app-humanize-duration class="info" [value]="last6Months?.duration" />
    <app-humanize-duration class="info" [value]="last3Months?.duration" />
    <app-humanize-duration class="info" [value]="lastMonth?.duration" />
    <app-humanize-duration class="info" [value]="currentMonth?.duration" />
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <span class="label" i18n>Délai moyen de remise en service</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <span class="label" i18n>Délai moyen d'intervention</span>
  </div>
</div>

<ng-template #durationTmpl let-duration="duration">
  <div class="duration-container">
    <app-loader class="duration-spinner" *ngIf="!duration && duration !== 0" @fade [diameter]="16"></app-loader>
    <span light>{{duration | humanizeDuration: { largest: 2 } }}</span>
  </div>
</ng-template>
