/** @format */

import { Injectable } from '@angular/core';
import { each, map, set } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import { SubSink } from 'subsink';
import {
  Elevator,
  ElevatorFloor,
  ElevatorFloorInitialization,
  ElevatorLastMouvement,
} from '../../_classes/equipment/equipment.elevator.class';
import { EquipmentFamily } from '../../_constants/equipment-family';
import { SessionData } from '../../app.session.data';
import { PartialType } from '../crud.service';
import { GraphqlService } from '../graphql.service';
import { ExtendsEquipmentService } from './equipment.service';

export interface IInitializationStopDto {
  _id: string;
  minDistance: number;
  maxDistance: number;
  floorId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentElevatorService extends ExtendsEquipmentService(Elevator, EquipmentFamily.ELEVATOR) {
  familyPath = '/elevator';

  private subSink = new SubSink();

  constructor() {
    super();
    SessionData.$user.subscribe((user) => {
      if (!user) this.subSink.unsubscribe();
    });
  }

  private entityPropertyMap: {
    [_id: string]: (PartialType<Elevator> & { _id: string })[];
  } = {};
  private addEntityPropertyMapEntry(data: PartialType<Elevator>) {
    let entry = this.entityPropertyMap[data._id];
    if (!entry) {
      entry = this.entityPropertyMap[data._id] = [];
      this.subSink.add(
        GraphqlService.onPropertyUpsert(data._id, 'status').subscribe((data) =>
          each(entry, (e) => set(e, 'status', data.value)),
        ),
        GraphqlService.onPropertyUpsert(
          data._id,
          'metadata.lastMouvement',
          new ModelMapper(ElevatorLastMouvement),
        ).subscribe((data) => each(entry, (e) => set(e, 'metadata.lastMouvement', data.value))),
        GraphqlService.onPropertyUpsert(data._id, 'metadata.lastDoorStates').subscribe((data) =>
          each(entry, (e) => set(e, 'metadata.lastDoorStates', data.value)),
      ),
      GraphqlService.onPropertyUpsert(data._id, 'metadata.movementCount').subscribe((data) =>
        each(entry, (e) => set(e, 'metadata.movementCount', data.value)),
      ),
        GraphqlService.onPropertyUpsert(data._id, 'metadata.distanceCount').subscribe((data) =>
          each(entry, (e) => set(e, 'metadata.distanceCount', data.value)),
        ),
        GraphqlService.onPropertyUpsert(data._id, 'metadata.signature.status').subscribe((data) =>
          each(entry, (e) => set(e, 'metadata.signature.status', data.value)),
        ),
      );
    }
    entry.push(data);
  }

  override get(id: string): Promise<Elevator>;
  override get<T extends Elevator>(id: string, type: new () => T): Promise<T>;
  override get(id: string, fields: string): Promise<PartialType<Elevator>>;
  override get<T extends Elevator>(id: string, data?: string | (new () => T)): Promise<PartialType<Elevator> | T> {
    return super.get(id, data).then((res) => {
      this.addEntityPropertyMapEntry(res);
      return res;
    });
  }

  public async getFloors(id: string): Promise<ElevatorFloor[]> {
    const path = `${this.path}${this.familyPath}/${id}/floors`;
    return this.httpService.get(path).then((data) => map(data, (d) => new ModelMapper(ElevatorFloor).map(d)));
  }

  public async getFloorInitialization(id: string): Promise<ElevatorFloorInitialization> {
    const path = `${this.path}${this.familyPath}/${id}/floor-initialization`;
    return this.httpService.get(path).then((data) => new ModelMapper(ElevatorFloorInitialization).map(data));
  }

  public async setFloorInitializationStops(id: string, stops: IInitializationStopDto[]): Promise<boolean> {
    const path = `${this.path}${this.familyPath}/${id}/floor-initialization`;
    return this.httpService.post(path, { stops });
  }

  public async deleteFloorInitializationStop(id: string, stopId: string): Promise<boolean> {
    const path = `${this.path}${this.familyPath}/${id}/floor-initialization/${stopId}`;
    return this.httpService.delete(path);
  }

  async resetFloorsInitialization(id: string): Promise<boolean> {
    const path = `${this.path}/elevator/${id}/reset-floors-intialization`;
    return this.httpService.patch(path);
  }

  public async cancelFloorsInitialization(id: string): Promise<boolean> {
    const path = `${this.path}${this.familyPath}/${id}/cancel-floors-intialization`;
    return this.httpService.patch(path, {});
  }
}
