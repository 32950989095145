/** @format */

import { PropertyMap } from 'model-mapper';
import { ConsumptionKind } from '../_constants/consumption-kind';

export class EquipmentConsumptionKind {
  @PropertyMap()
  kind: ConsumptionKind;

  @PropertyMap()
  propellant: string;
}
