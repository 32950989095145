import { Pipe, PipeTransform } from '@angular/core';
import { Storage } from 'aws-amplify';
import { map } from 'lodash-es';

@Pipe({
  name: 'galleryStorageFileUrl',
})
export class GalleryStorageFileUrlPipe implements PipeTransform {
  async transform(path: string | string[]): Promise<{ src: string } | { src: string }[] | null> {
    if (Array.isArray(path)) {
      return Promise.all(map(path, (p) => Storage.get(p))).then((data) => map(data, (src) => ({ src })));
    } else if (typeof path === 'string') {
      return { src: await Storage.get(path) };
    }
    return null;
  }
}
