/** @format */

import { Injectable, inject } from '@angular/core';
import { Moment } from 'moment';
import { Observable, from } from 'rxjs';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { HttpService } from './http.service';

export interface IFilter {
  equipmentId?: string;
  sesioNodeComponentId?: string;
  from?: Date | Moment;
  to?: Date | Moment;
}

@Injectable({
  providedIn: 'root',
})
export class CabinCallDataService {
  path = '/cabin-call-data';
  httpService: HttpService = inject(HttpService);

  datatable(query: IDatatableOptions, filter?: IFilter): Observable<any> {
    const path = `${this.path}/datatable`;
    return from(this.httpService.post(path, { query, filter: this.getFilterQuery(filter) }));
  }

  getFilterQuery(filter?: IFilter): any {
    const query: any = {};
    if (filter?.equipmentId) query.equipmentId = filter.equipmentId;
    if (filter?.sesioNodeComponentId) query.sesioNodeComponentId = filter.sesioNodeComponentId;
    if (filter?.from) query.from = filter.from.toISOString();
    if (filter?.to) query.to = filter.to.toISOString();
    return query;
  }
}
