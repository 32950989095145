/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { isEqual, merge, reduce, values } from 'lodash-es';
import { SubSink } from 'subsink';
import {
  getSensorIndicator,
  SensorIndicator,
  SensorIndicatorColorName,
} from '../../../_constants/sensor/sensor-indicator';
import { getColorValue } from '../../../_helpers/style.helper';
import { SearchService } from '../../../_services/search.service';
import { IMapInfo, ISensorFilter, SensorService } from '../../../_services/sensor/sensor.service';
import { SessionService } from '../../../_services/session.service';
import { IMarker, MapComponent } from '../map.component/map.component';

@Component({
  selector: 'app-sensor-indicator-map',
  templateUrl: './sensor-indicator-map.component.html',
  styleUrls: ['./sensor-indicator-map.component.scss'],
})
export class SensorIndicatorMapComponent implements OnInit, OnDestroy {
  mapOptions = {
    maxZoom: 19,
    cluster: true,
    enableNavControl: true,
    clusterProperties: {
      ...reduce(
        values(SensorIndicator),
        (pv, key) => {
          pv[key] = {
            color: `var(--app-color-${SensorIndicatorColorName[key]})`,
            merge: ['+', ['case', ['==', ['get', 'indicator'], key], 1, 0]],
          };
          return pv;
        },
        {} as any,
      ),
    },
  };

  bounds: mapboxgl.LngLatBounds | null = null;
  private mapRef: MapComponent;
  private subsink = new SubSink();

  constructor(
    private sessionService: SessionService,
    private searchService: SearchService,
    private sensorService: SensorService,
  ) {}

  ngOnInit(): void {
    this.subsink.add(
      this.sessionService.$organization.subscribe(() => this.load()),
      this.searchService.$search.subscribe((value) => this.load()),
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  private boundsTm: any;
  async loadMap(mapRef: MapComponent) {
    this.mapRef = mapRef;
    this.subsink.add(
      this.mapRef.movedEmitter.subscribe((event): any => {
        const bounds = this.mapRef.getBounds();
        if (!this.bounds || !isEqual(this.bounds.toArray(), bounds.toArray())) {
          this.bounds = bounds;
          if (this.boundsTm) clearTimeout(this.boundsTm);
          this.boundsTm = setTimeout(() => {
            this.boundsTm = null;
          }, 300);
        }
      }),
    );
    this.loadMapData();
  }

  reset() {
    this.bounds = null;
    this.load();
  }

  private load() {
    if (!this.sessionService.$organization.value) return;
    this.loadMapData();
  }

  private async loadMapData() {
    if (!this.mapRef) return;
    this.mapRef.isLoading = true;
    const data = await this.sensorService.getMapInfo(this.getFilter(true));
    const markers: IMarker[] = [];
    const coordinates: [number, number][] = [];
    for (let d of data) {
      if (d.coordinates?.length < 2) continue;
      markers.push(this.buildMarker(d));
      coordinates.push(d.coordinates);
    }
    this.mapRef.setMarkers(markers);
    this.mapRef.fitBounds(coordinates, { padding: 64 });
    this.mapRef.isLoading = false;
  }

  private buildMarker(d: IMapInfo): IMarker {
    const indicator = getSensorIndicator(d.indicatorValue);
    return {
      type: 'icon',
      icon: `assets/icons/sensor-indicator/${indicator}.png`,
      position: d.coordinates,
      color: getColorValue(SensorIndicatorColorName[indicator]),
      properties: merge({ indicator }, d),
    };
  }

  private getFilter(omitBounds = false): ISensorFilter {
    const filter: ISensorFilter = {};
    if (!omitBounds && this.bounds) filter.bounds = this.bounds.toArray() as [[number, number], [number, number]];
    return filter;
  }
}
