<mat-tab-group mat-align-tabs="start" fill sticky-header mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>insert_chart</mat-icon>
        <span i18n>Signature</span>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="tab-content">
        <ng-container *ngTemplateOutlet="chartTabTmpl"></ng-container>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>table_chart</mat-icon>
        <span i18n>Données</span>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="tab-content-datagrid">
        <ng-container *ngTemplateOutlet="datagridTabTmpl"></ng-container>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon>manage_history</mat-icon>
        <span i18n>Historique</span>
      </div>
    </ng-template>
    <ng-template matTabContent>
      <div class="tab-content">
        <ng-container *ngTemplateOutlet="historicTabTmpl"></ng-container>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<button color="medium" mat-icon-button matDialogClose id="close-btn">
  <mat-icon>close</mat-icon>
</button>

<ng-template #chartTabTmpl>
  <div *ngIf="data.elevator" fill [style.height.%]="100">
    <app-elevator-indicators [elevator]="data.elevator"></app-elevator-indicators>
  </div>
</ng-template>

<ng-template #datagridTabTmpl>
  <table *ngIf="paginator" mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="search">
      <th mat-header-cell *matHeaderCellDef [attr.colspan]="displayedColumns.length">
        <mat-form-field no-subscript no-line-ripple [style.width.%]="100">
          <input matInput (keyup)="applyFilter($event)" i18n-placeholder placeholder="Filtrer" #input>
        </mat-form-field>
      </th>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span i18n>Type de l'indicateur</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.name}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Mois</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.month !== -1">{{MONTHS[element.month]}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="dayOfWeek">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Jours de la semaine</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.dayOfWeek !== -1">{{DAYS[element.dayOfWeek]}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Période</span>
      </th>
      <td mat-cell *matCellDef="let element; index as index">
        <div fxLayoutGap="4px" [style.white-space]="'nowrap'">
          <span>{{element.hour | number:'2.0-0'}}:{{element.minute | number:'2.0-0'}}</span>
          <span>&lt; &gt;</span>
          <span *ngIf="element.minute < 45">
            {{element.hour | number:'2.0-0'}}:{{(element.minute + 15) | number:'2.0-0'}}
          </span>
          <ng-container *ngIf="element.minute === 45">
            <span *ngIf="element.hour < 23">{{(element.hour + 1) | number:'2.0-0'}}:00</span>
            <span *ngIf="element.hour === 23">00:00</span>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="nbPeriods">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Total des créneaux supervisés</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.nbPeriods}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="mouvements">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Nombre de mouvements moyen par créneau supervisé</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.mouvements | number: '0.0-2'}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Nombre de créneaux supervisés ayant des mouvements</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.count}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="sum">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Total des mouvements sur ces créneaux</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.sum}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="min">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Nombre de mouvements minimum sur ces créneaux</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.min}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="max">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Nombre de mouvements maximum sur ces créneaux</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.max}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="avg">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>Nombre de mouvements moyen sur ces créneaux</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{element.avg | number: '0.0-2'}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['search']; sticky: true"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons i18n-aria-label
    aria-label="Sélectionnez la page des indicateurs" intersectionObserver
    (intersection)="loadPaginator($event, paginator)">
  </mat-paginator>
  <app-loader *ngIf="!signatureIndicators" @fade class="block-loader" diameter="100px"></app-loader>
</ng-template>

<ng-template #historicTabTmpl>
  <app-chart-range-selector selectedZoom="today">
    <div content fxLayout="column" fxLayoutGap="16px">

      <div fxLayout="column" fxLayoutAlign="start start" [style.min-width.%]="100">
        <span class="title" color="primary" i18n>Historique des statuts de signature</span>
        <!-- <app-field-history-chart #rangeElement [categories]="EquipmentSignatureStatusOptions"
          [entityId]="data.elevator._id" field="metadata.signature.status"
          [style.width.%]="100"></app-field-history-chart> -->
        <app-multi-field-history-chart #rangeElement [categories]="componentEntityCategories"
          [entities]="componentEntities" [style.width.%]="100"></app-multi-field-history-chart>
      </div>

    </div>
  </app-chart-range-selector>
</ng-template>
