import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
  index?:number
  images?: string[];
}

@Component({
  templateUrl: './gallery-dialog.dialog.html',
  styleUrls: ['./gallery-dialog.dialog.scss'],
})
export class GalleryDialog implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IData, public dialogRef: MatDialogRef<GalleryDialog>) {}

  ngOnInit(): void {}
}
