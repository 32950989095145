import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
  transform(data: any): any {
    const date = data ? moment(data) : null;
    return date?.isValid() ? date : null;
  }
}
