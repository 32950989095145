/** @format */

import { Injectable } from '@angular/core';
import { EquipmentFailureElevator } from '../../_classes/equipment-failure/equipment-failure.elevator.class';
import { EquipmentFamily } from '../../_constants/equipment-family';
import { ExtendsEquipmentFailureService } from './equipment-failure.service';
import { SereniteTicket } from '../../_classes/serenite/serenite-ticket.class';
import { ModelMapper } from 'model-mapper';

@Injectable({
  providedIn: 'root',
})
export class EquipmentFailureElevatorService extends ExtendsEquipmentFailureService(
  EquipmentFailureElevator,
  EquipmentFamily.ELEVATOR,
) {
  async getSereniteTicket(_id: string): Promise<SereniteTicket | null> {
    const path = `${this.path}/${_id}/serenite-ticket`;
    return this.httpService.get(path).then((data) => (data ? new ModelMapper(SereniteTicket).map(data) : null));
  }
}
