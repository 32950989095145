<app-datagrid #datagrid [options]="options">
  <ng-template appDatagridCell="ignored" let-cell="cell">
    <div fxLayoutAlign="center center">
      <mat-icon *ngIf="cell.rawValue" i18n-matTooltip
        matTooltip="PANNE IGNORÉE : cette panne n'est pas comptabilisée dans vos statistiques de pannes et n'impacte donc pas votre taux de disponibilité">
        notifications_paused
      </mat-icon>
    </div>
  </ng-template>
</app-datagrid>
