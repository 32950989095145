/** @format */

import { PropertyMap } from 'model-mapper';
import { EmbeddedGateway } from '../gateway/gateway.embedded.class';
import { Sensor } from './sensor.class';

export class LorawanConfiguration {
  @PropertyMap()
  devEui: string;

  @PropertyMap()
  appEui: string;

  @PropertyMap()
  appKey: string;

  @PropertyMap()
  deviceProfileId: string;

  @PropertyMap()
  serviceProfileId: string;

  @PropertyMap()
  macVersion: string;

  @PropertyMap()
  regParamsRevision: string;
}

export class GatewayInfoMetricState {
  @PropertyMap()
  min: number;

  @PropertyMap()
  max: number;

  @PropertyMap()
  avg: number;

  @PropertyMap()
  last: number;
}

export class GatewayInfo {
  @PropertyMap({ type: EmbeddedGateway })
  gateway: EmbeddedGateway;

  @PropertyMap()
  occurence: number;

  @PropertyMap({ type: Date })
  lastDate: Date;

  @PropertyMap({ type: GatewayInfoMetricState })
  rssi: GatewayInfoMetricState;

  @PropertyMap({ type: GatewayInfoMetricState })
  snr: GatewayInfoMetricState;

  @PropertyMap({ type: GatewayInfoMetricState })
  indicatorValue: GatewayInfoMetricState;
}

export class LorawanSensor extends Sensor {
  @PropertyMap({ type: LorawanConfiguration })
  configuration: LorawanConfiguration;

  @PropertyMap({ type: [GatewayInfo] })
  gateways: GatewayInfo[];

  @PropertyMap()
  adr: boolean;

  @PropertyMap()
  bandwidth: number;

  @PropertyMap()
  dataRate: number;

  @PropertyMap()
  spreadingFactor: number;

  @PropertyMap()
  rssi: number;

  @PropertyMap()
  snr: number;

  @PropertyMap({ type: Date })
  joinRequestDate: Date;

  @PropertyMap({ type: Date })
  joinAcceptedDate: Date;
}
