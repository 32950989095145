/** @format */

import { PropertyMap } from 'model-mapper';
import {
  CloseFailureOriginTransformer,
  FieldUpdateOrigin,
  OpenFailureOriginTransformer,
} from '../field-update-origin.class';

export class NotificationMessage {
  @PropertyMap()
  topic: string;

  @PropertyMap()
  messageId: string;

  @PropertyMap({ type: Date })
  messageDate: Date;

  @PropertyMap()
  messageContent: any;
}

export class ExternalNotificationMessage {
  @PropertyMap({ type: NotificationMessage })
  message: NotificationMessage;

  @PropertyMap({ type: NotificationMessage })
  acknowledgment: NotificationMessage;
}

export class InternalNotificationMessage {
  @PropertyMap({ type: NotificationMessage })
  message: NotificationMessage;

  @PropertyMap({ type: NotificationMessage })
  acknowledgment?: NotificationMessage;
}

export class EquipmentFailureNotification {
  @PropertyMap({ type: InternalNotificationMessage })
  internalOpen?: InternalNotificationMessage;

  @PropertyMap({ type: InternalNotificationMessage })
  internalClose?: InternalNotificationMessage;

  @PropertyMap({ type: ExternalNotificationMessage })
  externalOpen?: ExternalNotificationMessage;

  @PropertyMap({ type: ExternalNotificationMessage })
  externalClose?: ExternalNotificationMessage;
}

export class EquipmentFailure {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization?: string;

  @PropertyMap({ type: Date })
  start: Date;

  @PropertyMap({ type: Date })
  end: Date;

  @PropertyMap()
  isIgnored: boolean;

  @PropertyMap({ type: FieldUpdateOrigin })
  openOrigin: FieldUpdateOrigin;

  @PropertyMap({ map: OpenFailureOriginTransformer })
  openOriginLabel: string;

  @PropertyMap({ type: FieldUpdateOrigin })
  closeOrigin: FieldUpdateOrigin;

  @PropertyMap({ map: CloseFailureOriginTransformer })
  closeOriginLabel: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  externalReferences?: string;

  @PropertyMap({ type: EquipmentFailureNotification })
  notification?: EquipmentFailureNotification;
}
