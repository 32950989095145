/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { first } from 'lodash-es';
import { prettyPrintJson, FormatOptions } from 'pretty-print-json';

@Pipe({ name: 'first' })
export class FirstPipe implements PipeTransform {
  transform<T>(data: T[] | null | undefined): T | undefined {
    return first(data);
  }
}
