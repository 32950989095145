/** @format */

import { map, values } from 'lodash-es';

export enum EquipmentCabinCallName {
  CABIN_CALL_1 = 'cabin-call-1',
  CABIN_CALL_2 = 'cabin-call-2',
}

export const EquipmentCabinCallNameName: { [key: string]: string } = {
  [EquipmentCabinCallName.CABIN_CALL_1]: $localize`Appel cabine 1`,
  [EquipmentCabinCallName.CABIN_CALL_2]: $localize`Appel cabine 2`,
};

export const EquipmentCabinCallNameOptions = map(values(EquipmentCabinCallName), (value) => ({
  value,
  name: EquipmentCabinCallNameName[value],
}));

export const EquipmentCabinCallNameDatagridOptions = EquipmentCabinCallNameOptions;
