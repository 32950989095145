<div class="block" fxLayout="column" fxLayoutGap="8px" intersectionObserver (intersection)="intersection($event)">
  <div fxLayoutAlign="space-between end" fxFlex="1">
    <span class="title" [innerHTML]="label | translate"></span>
    <span *ngIf="!hideShowAllButton" color="secondary" underline cursor-pointer (click)="openPictureLibrary()"
      translate>open-picture-library</span>
  </div>
  <mat-grid-list *ngIf="intersect && pictures?.length" @fade [cols]="cols" [gutterSize]="gutterSize">
    <mat-grid-tile *ngFor="let tile of tiles; index as index" [colspan]="tile.colspan" [rowspan]="tile.rowspan">
      <div class="picture" [style.background-image]="'url(' + (pictures[index].key | storageFileUrl | async) + ')'"
        cursor-pointer (click)="openPictureLibrary(index)">
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
