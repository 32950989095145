/** @format */

import { AWSIoTProvider, PubSub } from '@aws-amplify/pubsub';
import { Auth, Hub } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppLogger } from '../_services/logger.service';
import { SessionData } from '../app.session.data';

export type SesioIoTProviderState = null | 'Disconnected' | 'Connecting' | 'Connected' | 'ConnectionDisrupted';

class SesioIotProviderClass extends AWSIoTProvider {
  public state = new BehaviorSubject<SesioIoTProviderState>(null);

  private retryTm: any;
  private logger = new AppLogger('SesioIotProvider');

  constructor() {
    super({
      aws_pubsub_region: environment.region,
      aws_pubsub_endpoint: `wss://a2cxyg0a7pd2x5-ats.iot.${environment.region}.amazonaws.com/mqtt`,
    });
    this.listenPubSub();
    this.listenAuth();
  }

  private listenPubSub() {
    Hub.listen('pubsub', async ({ payload }) => {
      this.state.next(payload.data.connectionState);
      if (payload.data.connectionState === 'ConnectionDisrupted') {
        if (!SessionData.user) return this.logger.info('Connection state is Disconnected'!);
        this.logger.warn(payload.message!, payload);
        if (!this.retryTm) this.retryTm = setTimeout(() => this.restartConnection(), 3000);
      } else {
        this.logger.info(payload.message!);
        if (this.retryTm) {
          clearTimeout(this.retryTm);
          this.retryTm = null;
        }
      }
    });
  }

  private listenAuth() {
    Hub.listen('auth', async ({ payload: { event, data, message } }) => {
      if (event === 'signIn') {
        this.connectClient();
      } else if (event === 'signOut') {
        this.disconnectClient();
      }
    });
    Auth.currentSession()
      .catch((err) => {})
      .then(() => this.connectClient());
  }

  public async restartConnection() {
    await this.disconnectClient();
    await this.connectClient();
  }

  public async connectClient() {
    if (this.state.value === 'Connected') {
      return this.logger.debug('already connected');
    }
    try {
      const options = { url: await this.endpoint };
      await this.connect(this.clientId, options);
    } catch (err: any) {
      this.logger.warn(`error connectiong client ${this.clientId}`, err);
    }
  }

  public async disconnectClient() {
    if (this.state.value === 'Disconnected') {
      return this.logger.debug('already disconnected');
    }
    if (this.retryTm) {
      clearTimeout(this.retryTm);
      this.retryTm = null;
    }
    try {
      await this.disconnect(this.clientId);
      if ((this.state.value as any) !== 'Disconnected') {
        this.state.next('Disconnected');
      }
    } catch (err: any) {
      this.logger.warn(`error disconnecting client ${this.clientId}`, err);
    }
  }
}

export const SesioIotProvider = new SesioIotProviderClass();
PubSub.addPluggable(SesioIotProvider);
