/** @format */

import { PropertyMap } from 'model-mapper';
import { Color } from './color.class';

export class TreeNode {
  @PropertyMap()
  type: string;

  @PropertyMap()
  entity?: any;

  @PropertyMap({ type: Color })
  color?: Color;

  @PropertyMap()
  hasChildren?: boolean;

  @PropertyMap()
  children?: TreeNode[];

  hideChildren?: boolean;

  onClick?: () => Promise<any>;
}
