/** @format */

import { Directive, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';

@Directive({
  selector: '[confirm], [confirmed]',
})
export class ConfirmDialogDirective {
  @Input('confirmTitle') title?: string;
  @Input('confirmMessage') message!: string;
  @Input('confirmValidate') validate?: string;
  @Input('confirmCancel') cancel?: string;
  @Output() confirmed = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  private confirnDialogService = inject(ConfirmDialogService);

  @HostListener('click', ['$event']) async onClick(e: MouseEvent): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    const data = await this.confirnDialogService.confirm({
      title: this.title,
      message: this.message,
      ok: this.validate,
      cancel: this.cancel,
    });
    if (data === true) this.confirmed.emit();
    else if (data === false) this.canceled.emit();
  }
}
