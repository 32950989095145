/** @format */

import { map, values } from 'lodash-es';

export enum RealEstateStructureStatus {
  OK = 'ok',
  ERROR = 'error',
  ANOMALY = 'anomaly',
}

export const RealEstateStructureStatusColor: { [key: string]: string } = {
  [RealEstateStructureStatus.OK]: 'var(--app-color-ok)',
  [RealEstateStructureStatus.ANOMALY]: 'var(--app-color-anomaly)',
  [RealEstateStructureStatus.ERROR]: 'var(--app-color-ko)',
};

export const RealEstateStructureStatusIcon: { [key: string]: string } = {
  [RealEstateStructureStatus.OK]: 'check_circle',
  [RealEstateStructureStatus.ANOMALY]: 'error',
  [RealEstateStructureStatus.ERROR]: 'warning',
};

export const RealEstateStructureStatusName: { [key: string]: string } = {
  [RealEstateStructureStatus.OK]: $localize`Ok`,
  [RealEstateStructureStatus.ANOMALY]: $localize`Anomalie`,
  [RealEstateStructureStatus.ERROR]: $localize`Erreur`,
};

export const RealEstateStructureStatusOptions = map(values(RealEstateStructureStatus), (value) => ({
  value,
  name: RealEstateStructureStatusName[value],
  prefixIcon: RealEstateStructureStatusIcon[value],
  color: RealEstateStructureStatusColor[value],
}));

export const RealEstateStructureStatusDatagridOptions = map(values(RealEstateStructureStatus), (value) => ({
  value: value,
  name: RealEstateStructureStatusName[value],
  icon: { name: RealEstateStructureStatusIcon[value], color: RealEstateStructureStatusColor[value] },
}));
