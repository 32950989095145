/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

type Data = {
  failureDuration: number;
  lastOpenedFailureId?: string | null;
  lastOpenedFailureStartDate?: Date | null;
};

@Pipe({
  name: 'equipmentDisponibilityRate',
})
export class EquipmentDisponibilityRatePipe implements PipeTransform {
  transform(disponibilityRate: number, data?: Data): number {
    if (data?.lastOpenedFailureId) {
      const failureDuration = data.failureDuration + moment().diff(data.lastOpenedFailureStartDate);
      const period = moment().diff(moment().subtract(12, 'months').startOf('day'));
      return 1 - failureDuration / period;
    }
    return disponibilityRate;
  }
}
