/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { each, find, get, map, max, min, pick } from 'lodash-es';
import moment from 'moment';
import { Extremes } from '../../../_classes/extremes.class';
import { FieldHistory } from '../../../_classes/field-history.class';
import { HumanizeFieldUpdateOrigin } from '../../../_classes/field-update-origin.class';
import { highchartsOptions } from '../../../_constants/highcharts';
import { FieldHistoryService } from '../../../_services/field-history.service';
import { SessionService } from '../../../_services/session.service';
import { ChartComponent } from '../chart/chart.component';
import { SessionData } from '../../../app.session.data';

@Component({
  selector: 'app-multi-field-history-chart',
  templateUrl: './multi-field-history-chart.component.html',
  styleUrls: ['./multi-field-history-chart.component.scss'],
})
export class MultiFieldHistoryChartComponent implements OnInit, OnDestroy {
  extremes: Extremes;

  @Input('yAxisMinWidth')
  set setYAxisMinWidth(yAxisMinWidth: number | string) {
    this.yAxisMinWidth = typeof yAxisMinWidth === 'number' ? yAxisMinWidth : parseFloat(yAxisMinWidth);
  }
  yAxisMinWidth: number;

  @Input()
  categories: { value: string; name: string; color: string }[];

  @Input()
  entities: { id: string; label: string; field: string }[];

  @Output()
  over = new EventEmitter<FieldHistory | null>();

  chartOptions: any;
  private chart: ChartComponent | undefined;

  constructor(
    private fieldHistoryService: FieldHistoryService,
    private sessionService: SessionService,
  ) {}

  ngOnInit(): void {
    this.loadOptions();
  }

  ngOnDestroy(): void {}

  public setExtremes(extremes: Extremes): void {
    this.extremes = extremes;
    this.loadData();
  }

  loadChart(chart: ChartComponent) {
    this.chart = chart;
    this.loadData();
  }

  private async loadData() {
    if (!this.chart || !this.extremes) return;
    this.chart.setLoading(true);
    const histories = await Promise.all(
      map(this.entities, (e) => this.fieldHistoryService.get(e.id, e.field, this.extremes)),
    );
    const formatted: any[] = [];
    each(histories, (h, y) => {
      for (let d of h) {
        const category = find(this.categories, { value: d.value });
        formatted.push([
          y,
          max([d.start.valueOf(), this.extremes?.start?.valueOf()]),
          min([d.end?.valueOf() || moment().valueOf(), this.extremes?.end?.valueOf()]),
          d.start.valueOf(),
          d.end?.valueOf() || moment().valueOf(),
          get(category, 'name'),
          get(category, 'color'),
          d.origin,
          d.isIgnored ? 0.5 : 1,
          d,
        ]);
      }
    });
    this.chart.setSerieData(formatted);
    if (this.extremes) this.chart.setExtremes(this.extremes, true);
    this.chart.setLoading(false);
  }

  private loadOptions() {
    this.chartOptions = {
      chart: { zoomType: 'x' },
      credits: { enabled: false },
      exporting: { enabled: false },
      title: { text: '' },
      xAxis: [
        {
          dateTimeLabelFormats: pick(
            highchartsOptions[this.sessionService.$locale.value]?.dateTimeLabelFormats,
            'week',
          ),
        },
        { dateTimeLabelFormats: highchartsOptions[this.sessionService.$locale.value]?.dateTimeLabelFormats },
      ],
      yAxis: [
        {
          type: 'category',
          categories: map(
            this.entities,
            (entity) =>
              `<span style="display: inline-block; min-width: ${this.yAxisMinWidth}px; text-align: end;">${entity.label}</span>`,
          ),
          labels: {
            align: 'right',
            useHTML: true,
            style: { padding: '0 8px 0 0' },
          },
        },
      ],
      tooltip: { useHTML: true, formatter: this.getFormatter() },
      navigator: {
        enabled: true,
        liveRedraw: true,
        series: {
          type: 'gantt',
          pointPlacement: 0.5,
          pointPadding: 0.25,
        },
        yAxis: {
          min: 0,
          max: 3,
          reversed: true,
          categories: [],
          label: { enabled: false },
        },
      },
      scrollbar: {
        enabled: true,
      },
      rangeSelector: {
        enabled: false,
        selected: 0,
      },
      series: [
        {
          name: 'field-history',
          keys: ['y', 'start', 'end', 'dateStart', 'dateEnd', 'name', 'color', 'origin', 'opacity', 'entry'],
          point: {
            events: {
              mouseOver: (event: any) => this.over.next(event.target.entry),
              mouseOut: () => this.over.next(null),
            },
          },
        },
      ],
      plotOptions: { series: { turboThreshold: 10000 } },
    };
  }

  private getFormatter() {
    return function (this: any): any {
      return `
  <div style="margin-bottom: 4px; width: 100%; display: flex; place-content: center space-between; align-items: center;">
    <span style="font-size: 1.2em; color:${this.point.color}; opacity: ${this.point.opacity}">
    <span style="font-style: italic;">${this.point.opacity !== 1 ? $localize`[ignoré] ` : ''}</span>
      ${this.point.name}
    </span>
    <span style="color:${this.point.color}; padding-left: 8px;">
      ${moment.duration(moment(this.point.dateEnd).diff(this.point.dateStart)).humanize()}
    </span>
  </div>
  <div>
    <span>
      ${moment(this.point.dateStart).format('L LT')}
    </span>
    <span> - </span>
    <span>
      ${moment(this.point.dateEnd).format('L LT')}
    </span>
  </div>
  <div>
    <span>Mis à jour par : </span>
      <span style="font-style: italic;">${HumanizeFieldUpdateOrigin(this.point.origin)}</span>
      <span style="font-style: italic;">${SessionData.user?.isAdministrator ? `( ${this.point.origin.info} )` : ''}</span>
    </div>
  `;
    };
  }
}
