/** @format */

import { PropertyMap } from 'model-mapper';
import { RealEstateStructureFamily } from '../_constants/real-estate-structure-family';
import { RealEstateStructureStatus } from '../_constants/real-estate-structure-status';
import { embeddedSerialize, s3PathPropertyMap } from '../_helpers/class.helper';
import { Archived } from './archived.class';
import { Color } from './color.class';
import { EntityDocument } from './entity-document.class';
import { ExternalReferences } from './external-references.class';
import { Location } from './location.class';
import { EmbeddedOrganizationalUnit } from './organizational-unit.embedded.class';
import { EmbeddedRealEstateStructureKind } from './real-estate-structure-kind.embedded.class';
import { S3File } from './s3-file.class';
import { Space } from './space.class';

export interface IRealEstateStructureKind {
  _id: string;
  name: string;
  color: Color;
  parents: (string | null)[];
}

export const RealEstateStructureKinds: IRealEstateStructureKind[] = [
  { _id: '0', name: 'Bâtiment', color: { hex: '#0081a7', contrast: '#ffffff' }, parents: [null] },
  { _id: '1', name: 'Parking', color: { hex: '#00afb9', contrast: '#ffffff' }, parents: [null, '0'] },
  { _id: '2', name: 'Ascenseur', color: { hex: '#fdfcdc', contrast: '#000000' }, parents: ['0', '1'] },
  { _id: '3', name: 'Porte de parking', color: { hex: '#70a288', contrast: '#ffffff' }, parents: ['1'] },
];

export class RealEstateStructure {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap()
  family: RealEstateStructureFamily;

  @PropertyMap({ type: EmbeddedRealEstateStructureKind, serialize: embeddedSerialize })
  kind: EmbeddedRealEstateStructureKind;

  @PropertyMap({ type: EmbeddedOrganizationalUnit, serialize: embeddedSerialize })
  organizationalUnit: EmbeddedOrganizationalUnit;

  @PropertyMap()
  reference: string;

  @PropertyMap({ type: ExternalReferences })
  externalReferences: ExternalReferences;

  @PropertyMap()
  status: RealEstateStructureStatus;

  @PropertyMap()
  name: string;

  @PropertyMap(s3PathPropertyMap)
  s3Path: string;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap({ type: Space })
  space: Space;

  @PropertyMap({ type: [S3File] })
  pictures: S3File[];

  @PropertyMap({ type: [EntityDocument] })
  documents: EntityDocument[];

  @PropertyMap()
  metadata: any;

  @PropertyMap()
  parent: string;

  @PropertyMap()
  path: string;

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
