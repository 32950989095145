/** @format */

export function getVariableValue(variable: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

export function getColorValue(color: string): string {
  return getVariableValue(color.replace(/var\(([^)]+)\)/g, '$1'));
}

