/** @format */

import { inject, Injectable } from '@angular/core';
import { SesioNodeComponentCabinCallGpio } from '../../_classes/sesio-node-component/sesio-node-component.cabin-call-gpio.class';
import { NotificationService } from '../../_components/notification/notification.service';
import { EquipmentCabinCallName } from '../../_constants/equipment-cabin-call-name';
import { SesioNodeComponentKind } from '../../_constants/sesio-node-component/sesio-node-component-kind';
import { ExtendsSesioNodeComponentService } from './sesio-node-component.service';

@Injectable({
  providedIn: 'root',
})
export class SesioNodeComponentCabinCallGpioService extends ExtendsSesioNodeComponentService(
  SesioNodeComponentCabinCallGpio,
  SesioNodeComponentKind.CABIN_CALL_GPIO,
) {
  private notificationService = inject(NotificationService);

  async trigger(id: string, group: EquipmentCabinCallName): Promise<boolean> {
    try {
      const path = `${this.path}/${id}/trigger`;
      const res = await this.httpService.post(path, { group });
      this.notificationService.info($localize`Demande de levée de doute envoyée`, { duration: 3000 });
      return res;
    } catch (err) {
      return false;
    }
  }
}
